import React from "react";
import Button from "@mui/material/Button";
import { makeStyles } from "@mui/styles";
import HomeIcon from "@mui/icons-material/Home";
import { useLocation } from "react-router-dom";
import {
  homeButtonLabel,
  homeButtonRedirectionURL,
} from "../../Constant/CustomButton";

const useStyles = makeStyles((theme) => ({
  circularButton: {
    borderRadius: "50px", // Makes the button circular
    width: "70px", // Set the width
    height: "32px", // Set the height
    minWidth: "auto", // Set the minimum width to auto to allow the button to be circular
    padding: 0, // Remove padding to keep it circular
  },
  whiteButton: {
    backgroundColor: "white", // Set background color to white
  },
}));

const CustomButton = ({ onClick }) => {
  const classes = useStyles();
  const location = useLocation();
  return location.pathname === homeButtonRedirectionURL ? (
    <Button
      variant="contained"
      style={{
        borderRadius: "50px",
        width: "99px",
        height: "34px",
        minWidth: "auto",
        padding: 0,
        backgroundColor: "white",
        color: "black",
        textTransform: "none",
        fontFamily: "Unilever Shilling",
        position: "relative",
        left: "30%",
        fontSize: "16px",
        boxShadow: "0px 4px 4px 0px #0000001C",
        fontWeight: 600,
        lineHeight: "18px",
      }}
      startIcon={<HomeIcon />}
      onClick={onClick}
    >
      {homeButtonLabel}
    </Button>
  ) : (
    <Button
      style={{
        width: "99px",
        height: "34px",
        minWidth: "auto",
        padding: 0,
        backgroundColor: "transparent",
        color: "black",
        textTransform: "none",
        fontFamily: "Unilever Shilling",
        position: "relative",
        left: "30%",
        fontSize: "16px",
        fontWeight: 600,
        lineHeight: "18px",
      }}
      startIcon={<HomeIcon />}
      onClick={onClick}
    >
      {homeButtonLabel}
    </Button>
  );
};

export default CustomButton;
