import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import "./Slide3.css";
import { useDispatch, useSelector } from "react-redux";
import Heading from "../Heading";
import { getProductImageKey } from "../../../Constant/ProductImages";
import { getImageUrl } from "../../../utilities/apiUtils/apiUtils";
import { setBlobProductImageURL } from "../../../slicers/createBriefSlicer";
import { aboutText } from "../../../Constant/EditBrief";

function Slide3({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  illustrationTopLeft,
  illustrationBottomRight,
  placement,
  labels,
}) {
  const {
    productSelectedVal,
    productOthersSelectedVal,
    productImageURL,
    marketSelectedVal,
    blobProductImageURL,
  } = useSelector((state) => state.createBrief);
  const { aboutProduct } = useSelector((state) => state.editYourBrief);
  const dispatch = useDispatch();

  return (
    <Box display="flex" height="100%" className={`${placement}`}>
      <Box className="slide3Left" height="100%" position="relative">
        <Box>{illustrationTopLeft && <img src={illustrationTopLeft} />}</Box>
        <Box className="contentMain">
          <Heading
            text={`${labels.ALL_ABOUT} ${
              productSelectedVal === "Others"
                ? productOthersSelectedVal
                : productSelectedVal
            }`}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
          <Heading
            text={aboutProduct}
            textColor={fontColor}
            textSize={subHeadingFontSize}
            textWeight={headingFontWeight}
          />
        </Box>
        <Box className="illustration2Slide3">
          {illustrationBottomRight && <img src={illustrationBottomRight} />}
        </Box>
      </Box>
      <Box className="slide3Right">
      <Box margin={10}>
      <img
          src={productImageURL || blobProductImageURL}
          alt="Product is loading"
       
          className="productImage"
        />
      </Box>
      </Box>
    </Box>
  );
}

export default Slide3;
