import React from 'react';
import { Box } from '@mui/system';
import editIcon from '../../assets/images/Pen-Line.png';

function TitleEditIcon({ title = "Hello",isEditRequired=true, onEdit }) {
    return (
        <Box display="flex" justifyContent="space-between" >
            <Box style={{
                color: "var(--Text-Normal, #111)",
                fontSize: 16,
                fontStyle: "normal",
                fontWeight: 600,
                color: "#000",
            }} >
                {title}
            </Box>
           {isEditRequired&& <img src={editIcon} alt="Edit" onClick={onEdit} style={{ cursor: 'pointer' }} />}
        </Box>
    )
}

export default TitleEditIcon;
