import React, { useEffect } from "react";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { setUserName, setUserEmail } from "../../slicers/createBriefSlicer";
import nutritionsAITopDishEngineLogo from "../../assets/images/Nutritions-AI-Top-Dish-Engine-Logo.png";
import mobileImage from "../../assets/images/LandingPage/mobileImage.png";
import youtubeIcon from "../../assets/images/LandingPage/youtubeIcon.png";
import tiktokIcon from "../../assets/images/LandingPage/tiktokIcon.png";
import instagramIcon from "../../assets/images/LandingPage/instagramIcon.png";
import hellmannsBottleLogo from "../../assets/images/LandingPage/hellmannsBottleLogo.png";
import knorrLogo from "../../assets/images/LandingPage/knorrLogo.png";
import hellmannsJarLogo from "../../assets/images/LandingPage/hellmannsJarLogo.png";
import lowerBackground from "../../assets/images/LandingPage/lowerBackground.png";

export default function Landing() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // const { loginHint } = useAccount(accounts[0] || {});
  // const { acquireTokenSilent } = useMsalAuthentication();

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();

    if (!isAuthenticated) {
      instance
        .ssoSilent({
          scopes: ["user.read"],
          loginHint: "",
        })
        .then((response) => {
          instance.setActiveAccount(response.account);
          console.log("response", response);
          // dispatch(setUserName(currentAccount.name));
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginPopup({
              scopes: ["user.read"],
            });
          }
        });
    }
  }, [instance]);

  const signIn = async () => {
    try {
      await instance.loginPopup();
      const currentAccount = instance.getActiveAccount();

      if (currentAccount) {
        dispatch(setUserName(currentAccount.name));
        dispatch(setUserEmail(currentAccount.username));
      }

      navigate("/create-brief");
    } catch (error) {
      console.error(error);
    }
  };

  const signOut = async () => {
    instance.logoutRedirect();
  };

  return (
    <Grid
      container
      spacing={0.5}
      sx={{ backgroundColor: "#47C8FF", height: "100vh" }}
    >
      <Grid item xs={12} sx={{ height: "5%" }}></Grid>
      <Grid item xs={12} sx={{ zIndex: 1 }}>
        <Grid container spacing={0.5} sx={{ height: "100%" }}>
          <Grid item xs={2} sx={{}}></Grid>
          <Grid item xs={10} sx={{ display: "flex", justifyContent: "left" }}>
            <img
              src={knorrLogo}
              style={{
                width: "11%",
              }}
            />
            <img
              src={hellmannsJarLogo}
              style={{
                width: "11%",
              }}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} sx={{ zIndex: 1 }}>
        <Grid container spacing={0.5} sx={{ height: "100%", display: "flex" }}>
          <Grid item xs={2} sx={{}}>
            <Grid
              container
              direction="column"
              spacing={0.5}
              sx={{ height: "100%" }}
            >
              <Grid item xs={6} sx={{}}>
                <img
                  src={hellmannsBottleLogo}
                  style={{
                    width: "70%",
                    position: "relative",
                    float: "right",
                  }}
                />
              </Grid>
              <Grid
                item
                xs={6}
                sx={{
                  alignItems: "flex-start",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={tiktokIcon}
                  style={{
                    width: "42%",
                    position: "relative",
                  }}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid
            item
            xs={3}
            sx={{
              zIndex: 1,
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <img
              src={mobileImage}
              // height="60%"
              style={{
                // height: "auto", // Adjust to "auto" or remove to maintain aspect ratio
                // maxHeight: "85%", // Set maximum height to 45% of the container
                // width: "auto", // Adjust to "auto" or remove to maintain aspect ratio
                // maxWidth: "100%", // Set maximum width to 100% to fit within the container
                width: "100%",
                position: "relative",
              }}
            />
          </Grid>
          <Grid
            item
            xs={7}
            sx={{
              zIndex: 1,
              height: "100%",
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Grid
              container
              spacing={0.5}
              sx={{
                width: "80%",
                height: "100%",
                // height: "auto", // Adjust to "auto" or remove to maintain aspect ratio
                // maxHeight: "85%", // Set maximum height to 45% of the container
                // width: "auto", // Adjust to "auto" or remove to maintain aspect ratio
                // maxWidth: "80%", // Set maximum width to 100% to fit within the container
                borderRadius: "50px",
                background: "#FFFFFF66",
                boxShadow: "0px 4px 4px 0px #00000040",
              }}
            >
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  height: "38%",
                }}
              >
                <img
                  src={nutritionsAITopDishEngineLogo}
                  style={{
                    width: "33%",
                    position: "relative",
                    top: "-59%",
                  }}
                />
              </Grid>
              <Grid item xs={12} sx={{ height: "11%" }}>
                <Typography
                  variant="h1"
                  style={{
                    fontFamily: "Unilever Shilling",
                    fontSize: "34px",
                    fontWeight: 600,
                    lineHeight: "55.76px",
                    letterSpacing: "0.02em",
                    textAlign: "center",
                    color: "#001F82",
                  }}
                >
                  Welcome to the GAI
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  variant="h1"
                  style={{
                    fontFamily: "Unilever Shilling",
                    fontSize: "34px",
                    fontWeight: 600,
                    lineHeight: "55.76px",
                    letterSpacing: "0.02em",
                    textAlign: "center",
                    color: "#001F82",
                  }}
                >
                  Influencer Briefing Tool
                </Typography>
              </Grid>
              <Grid
                item
                xs={12}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Button
                  sx={{
                    width: "227px",
                    height: "50px",
                    padding: "8px 10px 8px 10px",
                    gap: "0px",
                    borderRadius: "24px",
                    background: "#005EEF",
                    fontFamily: "Unilever Shilling",
                    fontSize: "18px",
                    fontWeight: 600,
                    lineHeight: "20px",
                    textAlign: "center",
                    color: "#FCFCFC",
                  }}
                  onClick={() => signIn()}
                >
                  Login
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <Grid item xs={2}></Grid>
      <Grid item xs={2} sx={{ position: "relative", top: "-4%" }}>
        <img
          src={youtubeIcon}
          style={{
            width: "60%",
            position: "relative",
            zIndex: 1,
          }}
        />
      </Grid>
      <Grid item xs={2} sx={{ position: "relative", top: "-2%" }}>
        <img
          src={instagramIcon}
          style={{
            width: "53%",
            position: "relative",
            zIndex: 1,
          }}
        />
      </Grid>

      <Grid item xs={12}>
        <img
          src={lowerBackground}
          style={{
            width: "100%",
            position: "absolute",
            left: "0%",
            bottom: "0%",
            zIndex: 0,
          }}
        />
      </Grid>
    </Grid>
  );
}
