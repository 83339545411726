import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import CommonLayout from "../../pages/CommonLayout/CommonLayout";
import CustomInputTextarea from "../CustomInputTextarea/CustomInputTextarea";
import { thingsToKeepInMindTooltip } from "../../Constant/EditBreifTooltip";
import { useSelector, useDispatch } from "react-redux";
import {
  setContentMandatories,
  setContentMandatoriesEditData,
  setDeliverables,
  setEditYourBrief,
  setHashtags,
  setLinkToUse,
  setPostingTimeLine,
  setSocialMediaHashtag,
} from "../../slicers/editYourBriefSlicer";
import apiService from "../../utilities/service/apiService";
import { updateBriefData } from "../../utilities/apiUtils/apiUtils";
import {
  contentMandatoriesText,
  deliverableText,
  linkToUseText,
  postingTimelineText,
  socialMediaHandlesText,
} from "../../Constant/EditBrief";
import { useTextLabels } from "../../Constant/useTextLabels";

function ThingsToKeepInMind({ handleNext }) {
  const dispatch = useDispatch();
  const labels = useTextLabels();
  const {
    contentMandatories,
    deliverables,
    postingTimeline,
    socialMediaHashtag,
    hashtags,
    linkToUse,
    contentMandatoriesEditData,
  } = useSelector((state) => state.editYourBrief);
  const { briefId, briefData } = useSelector((state) => state.createBrief);

  const [inputData, setInputData] = useState({
    CONTENT_MANDATORIES: {
      isEdit: false,
      data: contentMandatories,
      originalData: contentMandatories,
    },
    DELIVERABLES: {
      isEdit: false,
      data: deliverables,
      originalData: deliverables,
    },
    SOCIAL_MEDIA_HANDLES_HASHTAGS: {
      isEdit: false,
      data: {
        socialMediaHandles: socialMediaHashtag,
        hashtags: hashtags,
      },
      originalData: {
        socialMediaHandles: socialMediaHashtag,
        hashtags: hashtags,
      },
    },
    LINK_TO_USE: {
      isEdit: false,
      data: linkToUse,
      originalData: linkToUse,
    },
    POSTING_TIMELINE: {
      isEdit: false,
      data: postingTimeline,
      originalData: postingTimeline,
    },
  });

  const handleInputChange = (e, section, doArray, doNot) => {
    setInputData((prevInputData) => ({
      ...prevInputData,
      [section]: {
        ...prevInputData[section],
        data: e.target.value,
      },
    }));
  };
  const cancelSaveBtn = {
    display: "flex",
    width: "64px",
    height: "24px",
    padding: "16px 8px",
    justifyContent: "center",
    alignItems: "center",
    gap: "8px",
    flexShrink: 0,
    color: "#111",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 400,
    cursor: "pointer",
    lineHeight: "20px",
  };
  const handleEdit = (section) => {
    setInputData((prevInputData) => ({
      ...prevInputData,
      [section]: {
        ...prevInputData[section],
        isEdit: true,
      },
    }));
  };

  const onCancelClick = (section) => {
    setInputData((prevInputData) => ({
      ...prevInputData,
      [section]: {
        ...prevInputData[section],
        isEdit: false,
        data: prevInputData[section].originalData,
      },
    }));
  };

  function extractPostingTimeline(userObject) {
    const postingTimelineKeys = [
      "contractual_briefing",
      "shooting_production",
      "send_content_to_knorr_for_review",
      "edit_2_2",
      "final_approval",
      "content_go_alive",
    ];

    const keyMappings = 
    {
      contractual_briefing: labels.CONTRACTUAL_BRIEFING,
      shooting_production: labels.SHOOTING_PRODUCTION,
      send_content_to_knorr_for_review: labels.SEND_CONTENT_TO_BRAND_TEAM_FOR_REVIEW,
      edit_2_2: labels.EDIT_2_2,
      final_approval: labels.FINAL_APPROVAL,
      content_go_alive: labels.CONTENT_GO_LIVE,
    };

    const postingTimeline = {};
    postingTimelineKeys.forEach((key) => {
      const formattedKey = keyMappings[key];
      postingTimeline[formattedKey] = userObject[`POSTING_TIMELINE_${key}`];
    });

    return postingTimeline;
  }

  const handleDataSave = async (section) => {
    try {
      let bodyParam;
      if (section === "SOCIAL_MEDIA_HANDLES_HASHTAGS") {
        const data = JSON.stringify({
          social_media_handles: socialMediaHandles,
          hashtags: socialHashtags,
        });
        bodyParam = updateBriefData(
          "SOCIAL_MEDIA_HANDLES_HASHTAGS",
          data,
          briefData,
          dispatch
        );
      } else if (section === "POSTING_TIMELINE") {
        const param = {};
        // Manually create keys for POSTING_TIMELINE
        param["POSTING_TIMELINE_contractual_briefing"] = convertDateFormatToISO(
          dates[0].value
        );
        param["POSTING_TIMELINE_shooting_production"] = convertDateFormatToISO(
          dates[1].value
        );
        param["POSTING_TIMELINE_send_content_to_knorr_for_review"] =
          convertDateFormatToISO(dates[2].value);
        param["POSTING_TIMELINE_edit_2_2"] = convertDateFormatToISO(
          dates[3].value
        );
        param["POSTING_TIMELINE_final_approval"] = convertDateFormatToISO(
          dates[4].value
        );
        param["POSTING_TIMELINE_content_go_alive"] = convertDateFormatToISO(
          dates[5].value
        );

        const { BRIEF_ID, ...restOfBriefData } = briefData;
        const updatedBriefData = {
          ...restOfBriefData,
          ...param,
        };
        bodyParam = updatedBriefData;
      } else if (section === "CONTENT_MANDATORIES") {
        const updatedContentMandatories = {
          do: editedDo,
          donts: editedDoNot,
        };
        const stringData = JSON.stringify(updatedContentMandatories);
        bodyParam = updateBriefData(
          section,
          JSON.stringify(stringData),
          briefData,
          dispatch
        );
        console.log(bodyParam, "body param");
      } else {
        bodyParam = updateBriefData(
          section,
          inputData[section].data,
          briefData,
          dispatch
        );
      }
      const response = await apiService({
        method: "PUT",
        url: `${window.location.origin}/briefs/${briefId}`,
        body: bodyParam,
        headers: {
          "Content-Type": "application/json",
        },
      });
      switch (section) {
        case "CONTENT_MANDATORIES":
          const contentMandatoriesData = JSON.parse(
            response.CONTENT_MANDATORIES
          );
          console.log(contentMandatoriesData, "88");
          dispatch(setContentMandatoriesEditData(contentMandatoriesData));
          break;
        case "DELIVERABLES":
          dispatch(setDeliverables(response.DELIVERABLES));
          break;
        case "SOCIAL_MEDIA_HANDLES_HASHTAGS":
          const { SOCIAL_MEDIA_HANDLES_HASHTAGS } = response;

          const cleanedString = SOCIAL_MEDIA_HANDLES_HASHTAGS.replace(
            /\\/g,
            ""
          );
          const parsedData = JSON.parse(cleanedString);
          const socialMediaHandles = parsedData.social_media_handles;
          const hashtags = parsedData.hashtags;
          dispatch(setSocialMediaHashtag(socialMediaHandles));
          dispatch(setHashtags(hashtags));
          break;
        case "LINK_TO_USE":
          dispatch(setLinkToUse(response.LINK_TO_USE));
          break;
        case "POSTING_TIMELINE":
          const timeline = extractPostingTimeline(response);
          console.log(timeline, "888");
          dispatch(setPostingTimeLine(timeline));
          break;
        default:
          throw new Error("Invalid section provided");
      }

      setInputData((prevInputData) => ({
        ...prevInputData,
        [section]: {
          ...prevInputData[section],
          isEdit: false,
        },
      }));
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const [editedDo, setEditedDo] = useState(contentMandatories[0].do);
  const [editedDoNot, setEditedDoNot] = useState(contentMandatories[0].donts);

  const handleDoChange = (index, newValue) => {
    const newDoArray = [...editedDo];
    newDoArray[index] = newValue;
    setEditedDo(newDoArray);
  };

  const handleDoNotChange = (index, newValue) => {
    const newDoNotArray = [...editedDoNot];
    newDoNotArray[index] = newValue;
    setEditedDoNot(newDoNotArray);
  };

  const [dates, setDates] = useState(
    Object.entries(postingTimeline).map(([key, value]) => ({ key, value }))
  );

  const handleDateChange = (key, newValue) => {
    setDates((prevDates) =>
      prevDates.map((date) => {
        if (date.key === key) {
          return { ...date, value: newValue };
        }
        return date;
      })
    );
  };
  function convertDateFormatToISO(dateString) {
    if (/^\d{4}-\d{2}-\d{2}$/.test(dateString)) {
      return dateString;
    } else {
      const [month, day, year] = dateString.split("/");
      return `${year}-${month}-${day}`;
    }
  }

  // const [socialMediaHashtags, setSocialMediaHashtag] = useState("");
  const [socialMediaHandles, setSocialMediaHandles] =
    useState(socialMediaHashtag);
  const [socialHashtags, setSocialHashtags] = useState(hashtags);
  const contentHeaders = [
    {
      title: labels.CONTENT_MANDATORIES,
      onEdit: () => handleEdit("CONTENT_MANDATORIES"),
      content: inputData.CONTENT_MANDATORIES.isEdit ? (
        <div style={{ margin: "10px" }}>
          <h3>{labels.DO_S}</h3>
          <ul>
            {editedDo.map((item, index) => (
              <li key={index}>
                <input
                  type="text"
                  value={item}
                  onChange={(e) => handleDoChange(index, e.target.value)}
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    padding: "8px 12px",
                    borderRadius: "8px",
                    color: "black",
                    background: "#fff",
                    border: "none",
                    resize: "none",
                  }}
                />
              </li>
            ))}
          </ul>

          <h3>{labels.DON_TS}</h3>
          <ul>
            {editedDoNot.map((item, index) => (
              <li key={index}>
                <input
                  type="text"
                  value={item}
                  onChange={(e) => handleDoNotChange(index, e.target.value)}
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    padding: "8px 12px",
                    borderRadius: "8px",
                    color: "black",
                    background: "#fff",
                    border: "none",
                    resize: "none",
                  }}
                />
              </li>
            ))}
          </ul>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "20px",
            }}
          >
            <div className="cancelSaveBtn">
              <div
                style={{
                  display: "flex",
                  width: "64px",
                  height: "24px",
                  padding: "16px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  color: "#111",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  cursor: "pointer",
                  lineHeight: "20px",
                }}
                onClick={() => onCancelClick("CONTENT_MANDATORIES")}
              >
                Cancel
              </div>
              <div
                style={{
                  display: "flex",
                  width: "113px",
                  height: "20px",
                  padding: "4px 4px",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  borderRadius: "4px",
                  background: "#008651",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                  color: "#fff",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  cursor: "pointer",
                  lineHeight: "20px",
                }}
                onClick={() => handleDataSave("CONTENT_MANDATORIES")}
              >
                Save Changes
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Array.isArray(editedDo) && editedDo.length > 0 && (
            <div>
              <h3>{labels.DO_S}</h3>
              {editedDo.map((mandatories, index) => (
                <div key={index}>
                  <div>
                    <ul>
                      <li key={index}>{mandatories}</li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}

          {Array.isArray(editedDoNot) && editedDoNot.length > 0 && (
            <div>
              <h3>{labels.DON_TS}</h3>
              {editedDoNot.map((mandatories, index) => (
                <div key={index}>
                  <div>
                    <ul>
                      <li key={index}>{mandatories}</li>
                    </ul>
                  </div>
                </div>
              ))}
            </div>
          )}
        </>
      ),
    },
    {
      title: labels.DELIVERABLES,
      onEdit: () => handleEdit("DELIVERABLES"),
      content: inputData.DELIVERABLES.isEdit ? (
        <CustomInputTextarea
          isCancelSaveRequired={true}
          handleCancelClick={() => onCancelClick("DELIVERABLES")}
          handleSaveChangesClick={() => handleDataSave("DELIVERABLES")}
          data={inputData.DELIVERABLES.data}
          handleChange={(e) => handleInputChange(e, "DELIVERABLES")}
        />
      ) : (
        <div>
          <p>{inputData.DELIVERABLES.data}</p>
        </div>
      ),
    },
    {
      title: labels.SOCIAL_MEDIA_HANDLES_AND_HASHTAGS,
      onEdit: () => handleEdit("SOCIAL_MEDIA_HANDLES_HASHTAGS"),
      content: inputData.SOCIAL_MEDIA_HANDLES_HASHTAGS.isEdit ? (
        <>
          <CustomInputTextarea
            isCancelSaveRequired={false}
            data={
              socialMediaHashtag
                ? socialMediaHashtag
                    .map((handle) =>
                      Object.entries(handle).map(
                        ([platform, username]) => `${platform}:${username}`
                      )
                    )
                    .join(", ")
                : ""
            }
            handleChange={(e) => {
              // Assuming the input contains handles separated by a comma
              const value = e.target.value;
              const handles = value.split(",").map((item) => item.trim()); // Splitting handles
              const updatedHandles = handles.map((handle) => {
                const [platform, username] = handle
                  .split(":")
                  .map((item) => item.trim());
                return { [platform]: username };
              });

              setSocialMediaHandles(updatedHandles);
            }}
          />
          <CustomInputTextarea
            isCancelSaveRequired={true}
            handleCancelClick={() =>
              onCancelClick("SOCIAL_MEDIA_HANDLES_HASHTAGS")
            }
            handleSaveChangesClick={() =>
              handleDataSave("SOCIAL_MEDIA_HANDLES_HASHTAGS")
            }
            data={hashtags}
            handleChange={(e) => setSocialHashtags(e.target.value)}
          />
        </>
      ) : (
        <div>
          <div
            style={{ display: "flex", flexDirection: "row", columnGap: "10px" }}
          >
            {socialMediaHashtag.map((handle, index) => (
              <p key={index} style={{ margin: "0" }}>
                {Object.entries(handle).map(([platform, username]) => (
                  <span key={platform}>{`${platform}: ${username}`} </span>
                ))}
              </p>
            ))}
          </div>

          <div>{hashtags && hashtags}</div>
        </div>
      ),
    },
    {
      title: labels.LINKS_TO_USE_WITHIN_CAPTIONS,
      onEdit: () => handleEdit("LINK_TO_USE"),
      content: inputData.LINK_TO_USE.isEdit ? (
        <CustomInputTextarea
          isCancelSaveRequired={true}
          handleCancelClick={() => onCancelClick("LINK_TO_USE")}
          handleSaveChangesClick={() => handleDataSave("LINK_TO_USE")}
          data={inputData.LINK_TO_USE.data}
          handleChange={(e) => handleInputChange(e, "LINK_TO_USE")}
        />
      ) : (
        <div>
          <p>{inputData.LINK_TO_USE.data}</p>
        </div>
      ),
    },
    {
      title: labels.POSTING_TIMELINES,
      onEdit: () => handleEdit("POSTING_TIMELINE"),
      content: inputData.POSTING_TIMELINE.isEdit ? (
        <div style={{ display: "flex", flexDirection: "column", gap: "10px" }}>
          {Array.isArray(dates) &&
            dates.map(({ key, value }, index) => (
              <div key={index} style={{ display: "flex", columnGap: "50px" }}>
                <div style={{ width: "250px" }}>{key}</div>{" "}
                <div style={{ flexGrow: 1 }}>
                  <input
                    type="date"
                    value={convertDateFormatToISO(value)}
                    onChange={(e) => handleDateChange(key, e.target.value)}
                  />
                </div>
              </div>
            ))}
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "20px",
            }}
          >
            <div className="cancelSaveBtn">
              <div
                style={cancelSaveBtn}
                onClick={() => onCancelClick("POSTING_TIMELINE")}
              >
                Cancel
              </div>
              <div
                style={{
                  display: "flex",
                  width: "113px",
                  height: "20px",
                  padding: "4px 4px",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  borderRadius: "4px",
                  background: "#008651",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                  color: "#fff",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  cursor: "pointer",
                  lineHeight: "20px",
                  marginRight: "20px",
                }}
                onClick={() => handleDataSave("POSTING_TIMELINE")}
              >
                Save Changes
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            marginBottom: "25px",
          }}
        >
          {Array.isArray(dates) &&
            dates.map(({ key, value }, index) => (
              <div key={index} style={{ display: "flex", columnGap: "50px" }}>
                <div style={{ width: "250px" }}>{key}</div>{" "}
                <div style={{ flexGrow: 1 }}>{value}</div>
              </div>
            ))}
        </div>
      ),
    },
  ];

  return (
    <>
      <CommonLayout
        onHandleNextClick={handleNext}
        tooltipData={thingsToKeepInMindTooltip}
        contentHeaders={contentHeaders}
      />
    </>
  );
}

export default ThingsToKeepInMind;
