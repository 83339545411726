import { configureStore } from '@reduxjs/toolkit'
import counterReducer from '../slicers/counterSlice'
import addMoreFlavourSlicerReducer from '../slicers/addMoreFlavourSlicer'
import editYourBriefSlicerReducer from '../slicers/editYourBriefSlicer'
import createBriefSlicerReducer from '../slicers/createBriefSlicer'

export default configureStore({
  reducer: {
    counter: counterReducer,
    addMoreFlavour: addMoreFlavourSlicerReducer,
    editYourBrief:editYourBriefSlicerReducer,
    createBrief: createBriefSlicerReducer,
  },
})