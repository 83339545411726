export const homeBtnRedirectionURL = "/create-brief";
export const nutritionsTopDishImgAltText = "Nutritions AI Top Dish Engine Logo";
export const nutritionLogoImgAltText = "Nutrition Logo";
export const dividerOrientation = "vertical";
export const marketDropdown = {
  label: "Market",
  fieldType: "dropdown",
  className: "select-market-dropdown",
  showAsterisk: false,
  showInfoIcon: false,
  dropdownWidth: 200,
};
export const brandDropdown = {
  label: "Brand",
  fieldType: "dropdown",
  className: "select-brand-dropdown",
  showAsterisk: false,
  showInfoIcon: false,
  dropdownWidth: 110,
};

export const languageDropdown={
  label:"Output Language",
  fieldType: "dropdown",
  className: "select-brand-dropdown",
  showAsterisk: false,
  showInfoIcon: false,
  dropdownWidth: 110,
}

export const cookYourBriefText="Let's cook up your brief!";
export const gatherIngrediantText="Gather your ingredients from";
export const topDistTrendIndexText="Top Dish Trend Index";
export const typeItInText="Type It In";
export const invalidFileErrorText="Invalid file type. Please select a PDF file.";
export const trendIndexUploadText="Trend Index Upload";
