export const platformInfo = ["Instagram", "Youtube", "TikTok"];
export const campaignObjectiveInfo = [
  "Drive Affinity/Advocacy",
  "Drive Awareness",
  "Drive Consideration",
  "Drive Sales",
];
export const mediaTypeInfo = [
  "Static Image",
  "Long form Video",
  "Short form Video",
];
export const additionalUserSelectionsHeading = "Add more flavour to your brief";
export const audienceTypeDropdown = {
  label: "Audience Type",
  fieldType: "text",
  layoutType: "vertical",
  showAsterisk: false,
  showInfoIcon: true,
  disabledState: false,
  tooltipPlacement: "right-start",
  tooltipWidth: 235,
  textBoxWidth: "279px",
};
export const platformDropdown = {
  label: "Platform",
  fieldType: "dropdown",
  className: "select",
  layoutType: "vertical",
  showAsterisk: false,
  showInfoIcon: true,
  dropdownWidth: 279,
  disableDropdown: false,
  tooltipPlacement: "right-start",
  tooltipWidth: 215,
  multiSelect: true,
};
export const campaignObjectiveDropdown = {
  label: "Campaign Objective",
  fieldType: "dropdown",
  className: "select",
  layoutType: "vertical",
  showAsterisk: false,
  showInfoIcon: true,
  dropdownWidth: 279,
  disableDropdown: false,
  tooltipPlacement: "right-start",
  tooltipWidth: 200,
};
