export const brandOptions = [
  {
    brand_id: 1,
    brand_name: "Knorr",
    market_id: 1,
    fontFamily: "Filson Soft Regular",
    logo: "Logo/brandLogo.png",
    templateType: "template1",
    watermarkImage: "Watermark/WatermarkImage.png",
    slide1: {
      backgroundImage: "Slides/Slide1/BackgroundImage.png",
      subHeadingBackgroundImage: "Slides/Slide1/SubHeadingBackgroundImage.png",
      headingFontSize: 50,
      headingFontColor: "FFFFFF",
      subHeadingFontSize: 35,
      subHeadingFontColor: "FFF",
      headingFontSizePreview: 88,
      subHeadingFontSizePreview: 32,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 700,
      illustrationLogo: "Slides/Slide1/illustrationLogo.png",
      watermark: false,
    },
    slide2: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide2/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide2/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageLeft: {
        path: "Slides/Slide2/CoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageRight: {
        path: "Slides/Slide2/CoverImageRight.png",
        placement: "right",
        left: { x: 40 },
        right: { x: 88 },
      },
      headingFontSize: 14,
      headingFontColor: "007A33",
      subHeadingFontSize: 12,
      subHeadingFontColor: "007A33",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      headingSubHeadingBackgroundColor: "0041249e",
      illustrationBottomLeft: {
        path: "Slides/Slide2/illustrationBottomLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 59 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide2/illustrationBottomRight.png",
        placement: "right",
        left: { x: 40 },
        right: { x: 90 },
      },
      aboutBrandText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutBrandDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide3: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide3/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide3/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "007A33",
      subHeadingFontSize: 12,
      subHeadingFontColor: "007A33",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      illustrationTopLeft: {
        path: "Slides/Slide3/illustrationTopLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide3/illustrationBottomRight.png",
        placement: "left",
        left: { x: 40 },
        right: { x: 90 },
      },
      aboutProductText: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutProductDesc: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      productImage: {
        placement: "right",
        left: { x: 0 },
        right: { x: 52 },
      },
    },
    slide4: {
    
      watermark: false,
      defaultCoverImageLeft: {
        path: "Slides/Slide4/DefaultCoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide4/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide4/BackgroundImageLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      campaignImage: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "007A33",
      subHeadingFontSize: 10,
      subHeadingFontColor: "007A33",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      headingSubHeadingBackgroundColor: "0041249e",
      illustrationBottomLeft: {
        path: "Slides/Slide4/illustrationBottomLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide4/illustrationBottomRight.png",
        placement: "right",
        left: { x: 40 },
        right: { x: 90 },
      },
      aboutCampaignText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutCampaignDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide5: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide5/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide5/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageRight: {
        path: "Slides/Slide5/CoverImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 53 },
      },

      headingFontSize: 14,
      headingFontColor: "007A33",
      subHeadingFontSize: 14,
      subHeadingFontColor: "007A33",
      secondSubHeadingFontSize: 12,
      secondSubHeadingFontColor: "FFFFFF",
      secondSubHeadingFillColor: "007A33",
      mainContentFontSize: 9,
      mainContentFontColor: "007A33",
      disclaimerHeadingFontColor: "FFFFFF",
      disclaimerHeadingFillColor: "027931",
      disclaimerHeadingFontSize: 9,
      headlineBackgroundColor: "007A33",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 20,
      listContentFontSizePreview: 14,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,
      headlineFontColor: "FFF",
      subHeadingFontWeightPreview: 500,
      subHeadingFillColor: "027931",
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      subheadingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      listContentText: {
        placement: "left",
        left: { x: 4 },
        right: { x: 54 },
      },
      headlineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
    },
    slide6: {
      watermark: true,
      backgroundImage: "Slides/Slide6/BackgroundImage.jpg",
      backgroundImageLeft: "Slides/Slide6/BackgroundImageLeft.png",
      backgroundImageRight: "Slides/Slide6/BackgroundImageRight.png",
      watermarkImage: "Slides/Slide6/WatermarkImage.png",
      headingFontSize: 14,
      headingFontColor: "007A33",
      subHeadingFontSize: 12,
      subHeadingFontColor: "FFFFFF",
      subHeadingFillColor: "027931",
      subHeadingFontSizePreview: 16,
      headingFontSizePreview: 32,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      illustrationBottomRight: "Slides/Slide6/illustrationBottomRight.png",
      illustrationBottomLeft: "Slides/Slide6/illustrationBottomLeft.png",
    },
    slide7: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide7/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide7/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "007A33",
      subHeadingFontSize: 10,
      subHeadingFontColor: "007A33",
      doHeadingFontSize: 14,
      doFontSubHeadingSize: 14,
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,

      illustrationBottomLeft: {
        path: "Slides/Slide7/illustrationBottomLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide7/illustrationBottomRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 89 },
      },
      doFontColor: "007A33",
      doNotFontColor: "FFFFFF",
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 53 },
      },

      doListText: {
        placement: "left",
        left: { x: 3 },
        right: { x: 53 },
      },
      doNotListText: {
        placement: "right",
        left: { x: 3 },
        right: { x: 53 },
      },
    },
    slide8: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide8/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide8/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "FFFFFF",
      headingFillColor: "FFFFFF",
      subHeadingFontSize: 12,
      subHeadingFontColor: "154734",
      subHeadingFillColor: "FFFFFF",
      deliverablesFillFontColor: "FFFFFF",
      deliverablesHeadingFontColor: "FFFFFF",
      deliverablesHeadingFontSize: 18,
      deliverablesSubHeadingFontColor: "FFFFFF",
      deliverablesSubHeadingFontSize: 10,
      socialMediaFillColor: "FFFFFF",
      socialMediaHeadingFontColor: "FFFFFF",
      socialMediaHeadingFontSize: 18,
      hashtagsHeadingFontColor: "FFFFFF",
      hashtagsHeadingFontSize: 14,
      hashtagsSubHeadingFontColor: "FFFFFF",
      hashtagsSubHeadingFontSize: 10,
      handlesHeadingFontColor: "FFFFFF",
      handlesHeadingFontSize: 14,
      handlesSubHeadingFontColor: "FFFFFF",
      handlesSubHeadingFontSize: 10,
      socialMediaSubHeadingFontColor: "FFFFFF",
      linksToUseHeadingFontColor: "FFFFFF",
      linksToUseHeadingFontSize: 18,
      linksToUseSubHeadingFontColor: "FFFFFF",
      linksToUseSubHeadingFontSize: 10,
      postingTimelineFillColor: "FFFFFF",
      postingTimelineHeadingFontColor: "007A33",
      postingTimelineHeadingFontSize: 18,
      postingTimelineSubHeadingFontColor: "007A33",
      postingTimelineSubHeadingFontSize: 10,
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 14,
      socialMediaHandlesHeadingFontSize: 28,
      subHeadingFontWeight: 500,
      subContentHeadingFontSizePreview: 20,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      illustrationBottomLeft: {
        path: "Slides/Slide8/illustrationBottomLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide8/illustrationBottomRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 90 },
      },
      illustrationBottomMiddle: {
        path: "Slides/Slide8/illustrationBottomMiddle.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 52 },
      },
      deliverableLinkHandlesText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineDate: {
        placement: "right",
        left: { x: 2 },
        right: { x: 81 },
      },
    },
    slide9: {
      watermark: false,
      backgroundImage: "Slides/Slide9/BackgroundImage.png",
      headingFontSize: 28,
      headingFontColor: "FFFFFF",
      subHeadingFontSize: "",
      subHeadingFontColor: "",
      headingFontWeightPreview: 700,
      headingFontSizePreview: 40,
      illustrationLogo: "Slides/Slide9/illustrationLogo.png",
      coverImage: "Slides/Slide9/CoverImage.png",
    },
  },
  {
    brand_id: 2,
    brand_name: "Hellmann's",
    market_id: 1,
    fontFamily: "Dollop Serif Condensed",
    logo: "Logo/brandLogo.png",
    templateType: "template2",
    watermarkImage: "Watermark/WatermarkImage.png",
    slide1: {
      watermark: false,
      backgroundImage: "Slides/Slide1/BackgroundImage1.png",
      subHeadingBackgroundImage: "Slides/Slide1/SubHeadingBackgroundImage.png",
      headingFontSize: 32,
      headingFontColor: "FFFFFF",
      subHeadingFontSize: "",
      subHeadingFontColor: "",
      headingFontSizePreview: 40,
      subHeadingFontSizePreview: 32,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationLogo: "Slides/Slide1/CoverLogo.png",
      coverLogo: "Slides/Slide1/CoverLogo.png",
    },
    slide2: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide2/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide2/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageLeft: {
        path: "Slides/Slide2/CoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageRight: {
        path: "Slides/Slide2/CoverImageRight.png",
        placement: "right",
        left: { x: 40 },
        right: { x: 88 },
      },
      headingFontSize: 14,
      headingFontColor: "26567F",
      subHeadingFontSize: 12,
      subHeadingFontColor: "26567F",
      headingFontSizePreview: 28,
      subHeadingFontSizePreview: 18,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationBottomLeft: {
        path: "Slides/Slide2/illustrationBottomLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 59 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide2/illustrationBottomRight.png",
        placement: "right",
        left: { x: 40 },
        right: { x: 90 },
      },
      aboutBrandText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutBrandDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide3: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide3/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide3/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "26567F",
      subHeadingFontSize: 12,
      subHeadingFontColor: "26567F",
      headingFontSizePreview: 28,
      subHeadingFontSizePreview: 18,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationTopLeft: {
        path: "Slides/Slide3/illustrationTopLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide3/illustrationBottomRight.png",
        placement: "left",
        left: { x: 44 },
        right: { x: 90 },
      },
      aboutProductText: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutProductDesc: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      productImage: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide4: {
      watermark: false,
      headingFontSize: 14,
      headingFontColor: "074875",
      subHeadingFontSize: 10,
      subHeadingFontColor: "074875",
      headingFontSizePreview: 28,
      subHeadingFontSizePreview: 18,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationBottomLeft: "Slides/Slide4/illustrationBottomLeft.png",
      illustrationBottomRight: "Slides/Slide4/illustrationBottomRight.png",
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide4/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide4/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "074875",
      subHeadingFontSize: 10,
      subHeadingFontColor: "074875",
      headingFontSizePreview: 28,
      subHeadingFontSizePreview: 18,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationBottomLeft: {
        path: "Slides/Slide4/illustrationBottomLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide4/illustrationBottomRight.png",
        placement: "right",
        left: { x: 40 },
        right: { x: 90 },
      },
      defaultCoverImageLeft: {
        path: "Slides/Slide4/DefaultCoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutCampaignText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutCampaignDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide5: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide5/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide5/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageRight: {
        path: "Slides/Slide5/CoverImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 53 },
      },
      headingFontSize: 14,
      headingFontColor: "074875",
      subHeadingFontSize: 14,
      subHeadingFontColor: "074875",
      secondSubHeadingFontSize: 12,
      secondSubHeadingFontColor: "FFFF",
      secondSubHeadingFillColor: "074875",
      mainContentFontSize: 9,
      mainContentFontColor: "074875",
      disclaimerHeadingFontColor: "FFFFFF",
      disclaimerHeadingFillColor: "074875",
      disclaimerHeadingFontSize: 9,
      headingFontSizePreview: 28,
      subHeadingFontSizePreview: 20,
      headlineBackgroundColor: "F6C945",
      headlineFontColor: "074875",
      listContentFontSizePreview: 18,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      subheadingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      listContentText: {
        placement: "left",
        left: { x: 4 },
        right: { x: 54 },
      },
      headlineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
    },
    slide6: {
      watermark: true,
      backgroundImage: "Slides/Slide6/BackgroundImage.jpg",
      backgroundImageLeft: "Slides/Slide6/BackgroundImageLeft.png",
      backgroundImageRight: "Slides/Slide6/BackgroundImageRight.png",
      headingFontSize: 14,
      headingFontColor: "074875",
      subHeadingFontSize: 12,
      subHeadingFontColor: "FFFFFF",
      subHeadingFillColor: "04426A",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 14,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationBottomRight: "Slides/Slide6/illustrationBottomRight.png",
      illustrationBottomLeft: "Slides/Slide6/illustrationBottomLeft.png",
      watermarkImage: "Slides/Slide6/WatermarkImage.png",
    },
    slide7: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide7/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide7/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "074875",
      subHeadingFontSize: 10,
      subHeadingFontColor: "074875",
      headingFontSizePreview: 28,
      subHeadingFontSizePreview: 18,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationBottomLeft: {
        path: "Slides/Slide7/illustrationBottomLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide7/illustrationBottomRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 89 },
      },
      doFontColor: "074875",
      doNotFontColor: "074875",
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 53 },
      },

      doListText: {
        placement: "left",
        left: { x: 3 },
        right: { x: 53 },
      },
      doNotListText: {
        placement: "right",
        left: { x: 3 },
        right: { x: 53 },
      },
    },
    slide8: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide8/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide8/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "04426A",
      headingFillColor: "FFFFFF",
      subHeadingFontSize: 12,
      subHeadingFontColor: "04426A",
      subHeadingFillColor: "FFFFFF",
      deliverablesFillFontColor: "04426A",
      deliverablesHeadingFontColor: "04426A",
      deliverablesHeadingFontSize: 18,
      deliverablesSubHeadingFontColor: "04426A",
      deliverablesSubHeadingFontSize: 10,
      socialMediaFillColor: "FFFFFF",
      socialMediaHeadingFontColor: "04426A",
      socialMediaHeadingFontSize: 18,
      socialMediaSubHeadingFontColor: "04426A",
      hashtagsHeadingFontColor: "04426A",
      hashtagsHeadingFontSize: 14,
      hashtagsSubHeadingFontColor: "04426A",
      hashtagsSubHeadingFontSize: 10,
      handlesHeadingFontColor: "04426A",
      handlesHeadingFontSize: 14,
      handlesSubHeadingFontColor: "04426A",
      handlesSubHeadingFontSize: 10,
      linksToUseHeadingFontColor: "04426A",
      linksToUseHeadingFontSize: 18,
      linksToUseSubHeadingFontColor: "04426A",
      linksToUseSubHeadingFontSize: 10,
      postingTimelineFillColor: "04426A",
      postingTimelineHeadingFontColor: "04426A",
      postingTimelineHeadingFontSize: 18,
      postingTimelineSubHeadingFontColor: "04426A",
      postingTimelineSubHeadingFontSize: 10,
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      socialMediaHandlesHeadingFontSize: 28,
      headingFontWeightPreview: 400,
      subHeadingFontWeightPreview: 400,
      illustrationBottomLeft: {
        path: "Slides/Slide8/illustrationBottomLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      illustrationBottomRight: {
        path: "Slides/Slide8/illustrationBottomRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 90 },
      },
      illustrationBottomMiddle: {
        path: "Slides/Slide8/illustrationBottomMiddle.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 52 },
      },
      deliverableLinkHandlesText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineDate: {
        placement: "right",
        left: { x: 2 },
        right: { x: 81 },
      },
    },
    slide9: {
      watermark: false,
      backgroundImage: "Slides/Slide9/BackgroundImage1.png",
      headingFontSize: 28,
      headingFontColor: "F9F1E1",
      subHeadingFontSize: "",
      subHeadingFontColor: "",
      headingFontWeightPreview: 400,
      headingFontSizePreview: 40,
      illustrationLogo: "Slides/Slide9/illustrationLogo.png",
      coverImage: "Slides/Slide9/CoverImage.png",
    },
  },
  {
    brand_id: 11,
    brand_name: "Royco",
    market_id: 10,
    fontFamily: "Unilever Shilling",
    logo: "Logo/brandLogo.png",
    templateType: "template1",
    watermarkImage: "Watermark/WatermarkImage.png",
    slide1: {
      backgroundImage: "Slides/Slide1/BackgroundImage.png",
      subHeadingBackgroundImage: "Slides/Slide1/SubHeadingBackgroundImage.png",
      headingFontSize: 50,
      headingFontColor: "FFFFFF",
      subHeadingFontSize: 35,
      subHeadingFontColor: "FFFFFF",
      headingFontSizePreview: 88,
      subHeadingFontSizePreview: 32,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 700,
     
      watermark: false,
    },
    slide2: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide2/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide2/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageLeft: {
        path: "Slides/Slide2/CoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },

      headingFontSize: 14,
      headingFontColor: "595A59",
      subHeadingFontSize: 12,
      subHeadingFontColor: "A70000",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      headingSubHeadingBackgroundColor: "0041249e",

      aboutBrandText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutBrandDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide3: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide3/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide3/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "A70000",
      subHeadingFontSize: 12,
      subHeadingFontColor: "A70000",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,

      aboutProductText: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutProductDesc: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      productImage: {
        placement: "right",
        left: { x: 0 },
        right: { x: 52 },
      },
    },
    slide4: {
      backgroundImage: "Slides/Slide4/BackgroundImage.jpg",
      watermark: false,
      defaultCoverImageLeft: {
        path: "Slides/Slide4/DefaultCoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide4/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide4/BackgroundImageLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      campaignImage: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "A70000",
      subHeadingFontSize: 10,
      subHeadingFontColor: "A70000",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      headingSubHeadingBackgroundColor: "0041249e",

      aboutCampaignText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutCampaignDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide5: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide5/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide5/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageRight: {
        path: "Slides/Slide5/CoverImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 53 },
      },

      headingFontSize: 14,
      headingFontColor: "A70000",
      subHeadingFontSize: 14,
      subHeadingFontColor: "A70000",
      secondSubHeadingFontSize: 12,
      secondSubHeadingFontColor: "A70000",
      secondSubHeadingFillColor: "FFCD2E",
      mainContentFontSize: 9,
      mainContentFontColor: "A70000",
      disclaimerHeadingFontColor: "A70000",
      disclaimerHeadingFillColor: "FFCD2E",
      disclaimerHeadingFontSize: 9,
      headlineBackgroundColor: "FFCD2E",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 20,
      listContentFontSizePreview: 14,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,
      headlineFontColor: "A70000",
      subHeadingFontWeightPreview: 500,
      subHeadingFillColor: "A70000",
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      subheadingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      listContentText: {
        placement: "left",
        left: { x: 4 },
        right: { x: 54 },
      },
      headlineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
    },
    slide6: {
      watermark: false,
      backgroundImage: "Slides/Slide6/BackgroundImage.jpg",
     
      headingFontSize: 14,
      headingFontColor: "A70000",
      subHeadingFontSize: 12,
      subHeadingFontColor: "A70000",
      subHeadingFillColor: "A70000",
      subHeadingFontSizePreview: 16,
      headingFontSizePreview: 32,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
    },
    slide7: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide7/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide7/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "A70000",
      subHeadingFontSize: 10,
      subHeadingFontColor: "A70000",
      doHeadingFontSize: 14,
      doFontSubHeadingSize: 14,
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,

      doFontColor: "A70000",
      doNotFontColor: "FFFFFF",
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 53 },
      },

      doListText: {
        placement: "left",
        left: { x: 3 },
        right: { x: 53 },
      },
      doNotListText: {
        placement: "right",
        left: { x: 3 },
        right: { x: 53 },
      },
    },
    slide8: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide8/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide8/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "FFFFFF",
      headingFillColor: "FFFFFF",
      subHeadingFontSize: 12,
      subHeadingFontColor: "154734",
      subHeadingFillColor: "FFFFFF",
      deliverablesFillFontColor: "FFFFFF",
      deliverablesHeadingFontColor: "FFFFFF",
      deliverablesHeadingFontSize: 18,
      deliverablesSubHeadingFontColor: "FFFFFF",
      deliverablesSubHeadingFontSize: 10,
      socialMediaFillColor: "FFFFFF",
      socialMediaHeadingFontColor: "FFFFFF",
      socialMediaHeadingFontSize: 18,
      hashtagsHeadingFontColor: "FFFFFF",
      hashtagsHeadingFontSize: 14,
      hashtagsSubHeadingFontColor: "FFFFFF",
      hashtagsSubHeadingFontSize: 10,
      handlesHeadingFontColor: "FFFFFF",
      handlesHeadingFontSize: 14,
      handlesSubHeadingFontColor: "FFFFFF",
      handlesSubHeadingFontSize: 10,
      socialMediaSubHeadingFontColor: "FFFFFF",
      linksToUseHeadingFontColor: "FFFFFF",
      linksToUseHeadingFontSize: 18,
      linksToUseSubHeadingFontColor: "FFFFFF",
      linksToUseSubHeadingFontSize: 10,
      postingTimelineFillColor: "A70000",
      postingTimelineHeadingFontColor: "A70000",
      postingTimelineHeadingFontSize: 18,
      postingTimelineSubHeadingFontColor: "A70000",
      postingTimelineSubHeadingFontSize: 10,
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 14,
      socialMediaHandlesHeadingFontSize: 28,
      subHeadingFontWeight: 500,
      subContentHeadingFontSizePreview: 20,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,

      deliverableLinkHandlesText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineDate: {
        placement: "right",
        left: { x: 2 },
        right: { x: 81 },
      },
    },
    slide9: {
      watermark: false,
      backgroundImage: "Slides/Slide9/BackgroundImage.png",
      headingFontSize: 28,
      headingFontColor: "FFFFFF",
      subHeadingFontSize: "",
      subHeadingFontColor: "",
      headingFontWeightPreview: 700,
      headingFontSizePreview: 40,
      illustrationLogo: "Slides/Slide9/illustrationLogo.png",
      coverImage: "Slides/Slide9/CoverImage.png",
    },
  },
  {
    brand_id: 12,
    brand_name: "Bango",
    market_id: 10,
    fontFamily: "Unilever Shilling",
    logo: "Logo/brandLogo.png",
    templateType: "template1",
    watermarkImage: "Watermark/WatermarkImage.png",
    slide1: {
      backgroundImage: "Slides/Slide1/BackgroundImage.png",
      subHeadingBackgroundImage: "Slides/Slide1/SubHeadingBackgroundImage.png",
      headingFontSize: 50,
      headingFontColor: "FFFFFF",
      subHeadingFontSize: 35,
      subHeadingFontColor: "FFFFFF",
      headingFontSizePreview: 88,
      subHeadingFontSizePreview: 32,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 700,
     
      watermark: false,
    },
    slide2: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide2/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide2/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageLeft: {
        path: "Slides/Slide2/CoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },

      headingFontSize: 14,
      headingFontColor: "595A59",
      subHeadingFontSize: 12,
      subHeadingFontColor: "595A59",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      headingSubHeadingBackgroundColor: "0041249e",

      aboutBrandText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutBrandDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide3: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide3/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide3/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "595A59",
      subHeadingFontSize: 12,
      subHeadingFontColor: "595A59",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,

      aboutProductText: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutProductDesc: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      productImage: {
        placement: "right",
        left: { x: 0 },
        right: { x: 52 },
      },
    },
    slide4: {
     
      watermark: false,
      defaultCoverImageLeft: {
        path: "Slides/Slide4/DefaultCoverImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide4/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide4/BackgroundImageLeft.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      campaignImage: {
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "595A59",
      subHeadingFontSize: 10,
      subHeadingFontColor: "595A59",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
      headingSubHeadingBackgroundColor: "0041249e",

      aboutCampaignText: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      aboutCampaignDesc: {
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
    },
    slide5: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide5/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide5/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      coverImageRight: {
        path: "Slides/Slide5/CoverImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 53 },
      },

      headingFontSize: 14,
      headingFontColor: "595A59",
      subHeadingFontSize: 14,
      subHeadingFontColor: "595A59",
      secondSubHeadingFontSize: 12,
      secondSubHeadingFontColor: "595A59",
      secondSubHeadingFillColor: "FFCD2E",
      mainContentFontSize: 9,
      mainContentFontColor: "595A59",
      disclaimerHeadingFontColor: "595A59",
      disclaimerHeadingFillColor: "FFCD2E",
      disclaimerHeadingFontSize: 9,
      headlineBackgroundColor: "FFCD2E",
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 20,
      listContentFontSizePreview: 14,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,
      headlineFontColor: "595A59",
      subHeadingFontWeightPreview: 500,
      subHeadingFillColor: "595A59",
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      subheadingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      listContentText: {
        placement: "left",
        left: { x: 4 },
        right: { x: 54 },
      },
      headlineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
    },
    slide6: {
      watermark: false,
      backgroundImage: "Slides/Slide6/BackgroundImage.jpg",
     
      headingFontSize: 14,
      headingFontColor: "595A59",
      subHeadingFontSize: 12,
      subHeadingFontColor: "595A59",
      subHeadingFillColor: "595A59",
      subHeadingFontSizePreview: 16,
      headingFontSizePreview: 32,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,
    },
    slide7: {
      placement: "left",
      watermark: false,
      backgroundImageLeft: {
        path: "Slides/Slide7/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide7/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "595A59",
      subHeadingFontSize: 10,
      subHeadingFontColor: "595A59",
      doHeadingFontSize: 14,
      doFontSubHeadingSize: 14,
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 16,
      subHeadingFontWeight: 500,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,

      doFontColor: "595A59",
      doNotFontColor: "FFFFFF",
      headingText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 53 },
      },

      doListText: {
        placement: "left",
        left: { x: 3 },
        right: { x: 53 },
      },
      doNotListText: {
        placement: "right",
        left: { x: 3 },
        right: { x: 53 },
      },
    },
    slide8: {
      watermark: false,
      placement: "left",
      backgroundImageLeft: {
        path: "Slides/Slide8/BackgroundImageLeft.png",
        placement: "left",
        left: { x: 0 },
        right: { x: 50 },
      },
      backgroundImageRight: {
        path: "Slides/Slide8/BackgroundImageRight.png",
        placement: "right",
        left: { x: 0 },
        right: { x: 50 },
      },
      headingFontSize: 14,
      headingFontColor: "FFFFFF",
      headingFillColor: "FFFFFF",
      subHeadingFontSize: 12,
      subHeadingFontColor: "154734",
      subHeadingFillColor: "FFFFFF",
      deliverablesFillFontColor: "FFFFFF",
      deliverablesHeadingFontColor: "FFFFFF",
      deliverablesHeadingFontSize: 18,
      deliverablesSubHeadingFontColor: "FFFFFF",
      deliverablesSubHeadingFontSize: 10,
      socialMediaFillColor: "FFFFFF",
      socialMediaHeadingFontColor: "FFFFFF",
      socialMediaHeadingFontSize: 18,
      hashtagsHeadingFontColor: "FFFFFF",
      hashtagsHeadingFontSize: 14,
      hashtagsSubHeadingFontColor: "FFFFFF",
      hashtagsSubHeadingFontSize: 10,
      handlesHeadingFontColor: "FFFFFF",
      handlesHeadingFontSize: 14,
      handlesSubHeadingFontColor: "FFFFFF",
      handlesSubHeadingFontSize: 10,
      socialMediaSubHeadingFontColor: "FFFFFF",
      linksToUseHeadingFontColor: "FFFFFF",
      linksToUseHeadingFontSize: 18,
      linksToUseSubHeadingFontColor: "FFFFFF",
      linksToUseSubHeadingFontSize: 10,
      postingTimelineFillColor: "595A59",
      postingTimelineHeadingFontColor: "595A59",
      postingTimelineHeadingFontSize: 18,
      postingTimelineSubHeadingFontColor: "595A59",
      postingTimelineSubHeadingFontSize: 10,
      headingFontSizePreview: 32,
      subHeadingFontSizePreview: 14,
      socialMediaHandlesHeadingFontSize: 28,
      subHeadingFontWeight: 500,
      subContentHeadingFontSizePreview: 20,
      headingFontWeightPreview: 700,
      subHeadingFontWeightPreview: 500,

      deliverableLinkHandlesText: {
        placement: "left",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineText: {
        placement: "right",
        left: { x: 2 },
        right: { x: 52 },
      },
      postingTimelineDate: {
        placement: "right",
        left: { x: 2 },
        right: { x: 81 },
      },
    },
    slide9: {
      watermark: false,
      backgroundImage: "Slides/Slide9/BackgroundImage.png",
      headingFontSize: 28,
      headingFontColor: "FFFFFF",
      subHeadingFontSize: "",
      subHeadingFontColor: "",
      headingFontWeightPreview: 700,
      headingFontSizePreview: 40,
      illustrationLogo: "Slides/Slide9/illustrationLogo.png",
      coverImage: "Slides/Slide9/CoverImage.png",
    },
  },
];
