import React, { useState } from "react";
import { TextareaAutosize as BaseTextareaAutosize } from "@mui/base/TextareaAutosize";
import { styled } from "@mui/system";
import { Box, Typography } from "@mui/material";
import ReactDOMServer from "react-dom/server"
import "./CustomInputTextarea.css";

const Textarea = styled(BaseTextareaAutosize)(``);

export default function CustomInputTextarea({
  isCancelSaveRequired = true,
  data = "Hell",
  handleChange,
  handleCancelClick,
  handleSaveChangesClick,
  wordLimit = 120,
  isListItem = false,
  placeholder="",
  isPlaceholder=false
}) {
  const [value, setValue] = useState(data);
  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    if (inputValue.split(" ").length <= wordLimit) {
      setValue(inputValue);
      handleChange(event);
    }
  };


  return (
    <Box marginRight={"51px"}>
      <Textarea
        className="textarea"
        aria-label="maximum height"
        placeholder={isPlaceholder?`${placeholder}`:`Maximum ${wordLimit} words`}
        value={value}
        onChange={handleInputChange}
      />
      {isCancelSaveRequired && (
        <Box className="cancelSaveBtn">
          <Typography className="cancelBtn" onClick={handleCancelClick}>
            Cancel
          </Typography>
          <Typography className="saveBtn" onClick={handleSaveChangesClick}>
            Save Changes
          </Typography>
        </Box>
      )}
    </Box>
  );
}
