import React from "react";
import { Box } from "@mui/material";
import "./Slide1.css";
import { useSelector } from "react-redux";
import Heading from "../Heading";
import { getSelectedValue } from "../../../utilities/common/commonUtils";

function Slide1({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  subHeadingBackgroundImage,
  illustrationBottom,
  illustrationLogo,
  templateType,
}) {
  const {
    topDishSelectedVal,
    brandSelectedVal,
    tastewiseSelectionValue,
    topDishTastewiseSelectedVal,
    trendingRecipeTastewiseSelectedVal,
    trendingRecipeTastewiseOthersSelectedVal,
    trendingRecipeSelectedVal,
    trendingRecipeOthersSelectedVal,
  } = useSelector((state) => state.createBrief);
  return templateType === "template1" ? (
    <Box display={"flex"} columnGap={"20px"}>
      <Box className="briefingText">
        <img width={763} height={448} src={subHeadingBackgroundImage} />
        <Box position={"absolute"} bottom={35} left={"10%"}>
          <Heading
            text={`${brandSelectedVal} Creator Briefing`}
            textColor={fontColor}
            textWeight={headingFontWeight}
            textSize={headingFontSize}
          />
          <Heading
            text={getSelectedValue(
              tastewiseSelectionValue,
              trendingRecipeTastewiseSelectedVal,
              trendingRecipeTastewiseOthersSelectedVal,
              trendingRecipeSelectedVal,
              trendingRecipeOthersSelectedVal
            )}
            textColor={fontColor}
            textWeight={subHeadingFontWeight}
            textSize={subHeadingFontSize}
          />
        </Box>
      </Box>
      <Box className="logo">
       {logo && <img src={logo} />}
      </Box>
    </Box>
  ) : (
    <>
      <Box>
        <img
          src={subHeadingBackgroundImage}
          className="illustartionBottomSlide1"
          height={290}
        />

        <Box className="creatorText">
          <Heading
            text={`${brandSelectedVal} Creator Briefing`}
            textColor={fontColor}
            textWeight={headingFontWeight}
            textSize={headingFontSize}
          />
        </Box>
        <Box className="illustrationLogo">
         { illustrationLogo && <img src={illustrationLogo} />}
        </Box>
        <Box className="productText">
          <Heading
            text={getSelectedValue(
              tastewiseSelectionValue,
              trendingRecipeTastewiseSelectedVal,
              trendingRecipeTastewiseOthersSelectedVal,
              trendingRecipeSelectedVal,
              trendingRecipeOthersSelectedVal
            )}
            textColor={fontColor}
            textWeight={subHeadingFontWeight}
            textSize={subHeadingFontSize}
          />
        </Box>
      </Box>
    </>
  );
}

export default Slide1;
