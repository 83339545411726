import React, { useState } from "react";
import CommonLayout from "../../pages/CommonLayout/CommonLayout";
import CustomInputTextarea from "../CustomInputTextarea/CustomInputTextarea";
import ImageWithIcon from "../ImageWithIcon/ImageWithIcon";
import { campaignOverviewTooltipData } from "../../Constant/EditBreifTooltip";
import { useDispatch, useSelector } from "react-redux";
import apiService from "../../utilities/service/apiService";
import {
  setCampaignOverview,
  setCreateUpdateDateTime,
  setInputEditPrompt,
  setPromptTonality,
} from "../../slicers/editYourBriefSlicer";
import { Box } from "@mui/material";
import { brandOptions as brandInfo } from "../../Constant/PreviewPPTLayoutInfo";
import UploadImageModal from "../UploadImageModal/UploadImageModal";
import { updateBriefData } from "../../utilities/apiUtils/apiUtils";
import {
  campaignBackgroundText,
  campaignImageText,
} from "../../Constant/EditBrief";
import { useTextLabels } from "../../Constant/useTextLabels";
const imageContext = require.context("../../assets/images", true);

function CampaignOverview({ handleNext }) {
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
const labels=useTextLabels()
  const { campaignOverview, inputEditPrompt, promptTonality } = useSelector(
    (state) => state.editYourBrief
  );

  const {
    topDishSelectedVal,
    trendingRecipeSelectedVal,
    productSelectedVal,
    productDescriptionSelectedVal,
    userPromptSelectedVal,
    marketSelectedVal,
    brandSelectedVal,
    campaignImageURL,
    briefId,
    trendingRecipeTastewiseOthersSelectedVal,
    trendingRecipeTastewiseIndexScoreSelectedVal,
    tastewiseSelectionValue,
    productOthersSelectedVal,
    trendingRecipeTastewiseSelectedVal,
    trendingRecipeOthersSelectedVal,
    productDescriptionOthersSelectedVal,
    briefData,
    topDishTastewiseSelectedVal,
    userEmail,
  } = useSelector((state) => state.createBrief);
  const {
    audienceTypeSelectedVal,
    platformSelectedVal,
    campaignObjectiveSelectedVal,
    mediaTypeSelectedVal,
    selectedOutputLanguage,
  } = useSelector((state) => state.addMoreFlavour);
  const [inputData, setInputData] = useState(campaignOverview);
  const [originalData, setOriginalData] = useState(campaignOverview);
  const [inputPrompt, setInputPrompt] = useState(inputEditPrompt);
  const [readyMadePrompt, setReadyMadePrompt] = useState(promptTonality);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const handleEdit = () => {
    setIsEdit(true);
  };
  const handleModalOpenClick = () => {
    setImageDialogOpen(true);
  };
  const onCancelClick = () => {
    setIsEdit(false);
    setInputData(originalData);
  };
  const filteredBrandInfo = brandInfo.filter(
    (brand) => brand.brand_name === brandSelectedVal
  );
  
  const selectedBrand = filteredBrandInfo[0];
  const handleDataSave = async () => {
    try {
      const response = await apiService({
        method: "PUT",
        url: `${window.location.origin}/briefs/${briefId}`,
        body: updateBriefData("CAMPAIGN_BACKGROUND", inputData, briefData,dispatch),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsEdit(false);
      dispatch(setCampaignOverview(response.CAMPAIGN_BACKGROUND));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const editBriefPromptAPICallClick = async () => {
    dispatch(setPromptTonality(readyMadePrompt));
    dispatch(setInputEditPrompt(inputPrompt))
    try {
      const response = await apiService({
        method: "POST",
        url: `${process.env.REACT_APP_AI_API_URL}/brief/edit-brief`,
        body: {
          market:
            marketSelectedVal === "UK" ? "United Kingdom" : marketSelectedVal,
          brand: brandSelectedVal,
          top_dish:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? topDishTastewiseSelectedVal
              : topDishSelectedVal,
          trending_recipe:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? trendingRecipeTastewiseSelectedVal === "Others"
                ? trendingRecipeTastewiseOthersSelectedVal
                : trendingRecipeTastewiseSelectedVal
              : trendingRecipeSelectedVal === "Others"
              ? trendingRecipeOthersSelectedVal
              : trendingRecipeSelectedVal,
          index_score: trendingRecipeTastewiseIndexScoreSelectedVal,
          product:
            productSelectedVal === "Others"
              ? productOthersSelectedVal
              : productSelectedVal,
          product_description:
            productSelectedVal === "Others"
              ? productDescriptionOthersSelectedVal
              : productDescriptionSelectedVal,
          audience_type: audienceTypeSelectedVal,
          platform: platformSelectedVal,
          campaign_objective: campaignObjectiveSelectedVal,
          media_type: mediaTypeSelectedVal,
          default_prompt: userPromptSelectedVal,
          user_name: userEmail,
          question: "CAMPAIGN BACKGROUND",
          feedback_prompt: inputPrompt,
          feedback_tone: [readyMadePrompt],
          output_language : selectedOutputLanguage
        },

        headers: {
          "Content-Type": "application/json",
        },
      });
      // dispatch(setPromptTonality(readyMadePrompt))
      dispatch(setCampaignOverview(response.response));
      dispatch(setCreateUpdateDateTime(response.updated_dttm))
      setInputData(response.response);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  return (
    <CommonLayout
      isPromptRequired
      setInputPrompt={setInputPrompt}
      setReadyMadePrompt={setReadyMadePrompt}
      editBriefPromptAPICallClick={editBriefPromptAPICallClick}
      onHandleNextClick={handleNext}
      tooltipData={campaignOverviewTooltipData}
      contentHeaders={[
        {
          title: labels.CAMPAIGN_BACKGROUND,
          onEdit: handleEdit,
          content: isEdit ? (
            <CustomInputTextarea
              isCancelSaveRequired={true}
              handleCancelClick={onCancelClick}
              handleSaveChangesClick={() => handleDataSave()}
              data={inputData}
              handleChange={handleInputChange}
            />
          ) : (
            <Box>{inputData}</Box>
          ),
          customClass: "descMain",
        },
        {
          title: labels.CAMPAIGN_IMAGES,
          isEditIconReq: false,
          content: (
            <>
              <ImageWithIcon
                src={campaignImageURL || imageContext(
                  `./${brandSelectedVal}/${selectedBrand.slide4.defaultCoverImageLeft.path}`
                )}
                handleModalOpenClick={handleModalOpenClick}
              />
              <UploadImageModal
                open={imageDialogOpen}
                onClose={() => setImageDialogOpen(false)}
                tabNames={["Campaign"]}
                tabSelected={"Campaign"}
              />
            </>
          ),
          customClass: "campaignImage",
        },
      ]}
    />
  );
}

export default CampaignOverview;
