import React, { useEffect, useState } from "react";
import { Box } from "@mui/material";
import CommonLayout from "../../pages/CommonLayout/CommonLayout";
import CustomInputTextarea from "../CustomInputTextarea/CustomInputTextarea";
import ImageWithIcon from "../ImageWithIcon/ImageWithIcon";
import { GetToKnowUsTooltipData } from "../../Constant/EditBreifTooltip";
import brandLogo from "../../assets/images/brandLogo.png";
import { useDispatch, useSelector } from "react-redux";
import hellmannLogo from "../../assets/images/hellmann'sBrandLogo.png";
import apiService from "../../utilities/service/apiService";
import {
  setAboutBrand,
  setAboutProduct,
} from "../../slicers/editYourBriefSlicer";
import UploadImageModal from "../UploadImageModal/UploadImageModal";
import {
  getImageUrl,
  updateBriefData,
} from "../../utilities/apiUtils/apiUtils";
import { useTextLabels } from "../../Constant/useTextLabels";
import { brandOptions } from "../../Constant/PreviewPPTLayoutInfo";
const imageContext = require.context("../../assets/images", true);

function GetToKnow({ activeIndex, handleNext }) {
  const { aboutBrand: aboutBrandData, aboutProduct: aboutProductData } =
    useSelector((state) => state.editYourBrief);
  const labels = useTextLabels();
  const {
    brandSelectedVal,
    brandImageURL,
    productImageURL,
    briefId,
    briefData,
    productOthersSelectedVal,
    productSelectedVal,
    marketSelectedVal,
    blobProductImageURL,
  } = useSelector((state) => state.createBrief);
  const [imageDialogOpen, setImageDialogOpen] = useState(false);
  const [imageDialogProductOpen, setImageDialogProductOpen] = useState(false);
  const dispatch = useDispatch();
  const [editState, setEditState] = useState({
    ABOUT_THE_PRODUCT: {
      isEdit: false,
      data: aboutProductData,
      originalData: aboutProductData,
    },
    ABOUT_THE_BRAND: {
      isEdit: false,
      data: aboutBrandData,
      originalData: aboutBrandData,
    },
  });

  const selectedBrand = brandOptions.find(
    (brand) => brand.brand_name === brandSelectedVal
  );
  const logoPath = imageContext(`./${brandSelectedVal}/${selectedBrand?.logo}`);

  const handleModalOpenClick = () => {
    setImageDialogOpen(true);
  };
  const handleModalOpenProductClick = () => {
    setImageDialogProductOpen(true);
  };
  const handleInputChange = (e, section) => {
    setEditState((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        data: e.target.value,
      },
    }));
  };

  const handleEdit = (section) => {
    setEditState((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        isEdit: true,
      },
    }));
  };

  const handleCancelClick = (section) => {
    setEditState((prev) => ({
      ...prev,
      [section]: {
        ...prev[section],
        isEdit: false,
        data: prev[section].originalData, // Reset data to originalData
      },
    }));
  };

  const handleDataSave = async (section) => {
    try {
      let bodyParam;
      if (section === "ABOUT_THE_BRAND") {
        bodyParam = updateBriefData(
          "ABOUT_THE_BRAND",
          editState.ABOUT_THE_BRAND.data,
          briefData,
          dispatch
        );
      } else if (section === "ABOUT_THE_PRODUCT") {
        bodyParam = updateBriefData(
          "ABOUT_THE_PRODUCT",
          editState.ABOUT_THE_PRODUCT.data,
          briefData,
          dispatch
        );
      } else {
        throw new Error("Invalid section provided");
      }
      console.log(bodyParam,"77777")
      const response = await apiService({
        method: "PUT",
        url: `${window.location.origin}/briefs/${briefId}`,
        body: bodyParam,
        headers: {
          "Content-Type": "application/json",
        },
      });
      setEditState((prev) => ({
        ...prev,
        [section]: {
          ...prev[section],
          isEdit: false,
        },
      }));
      if (section === "ABOUT_THE_BRAND") {
        dispatch(setAboutBrand(response.ABOUT_THE_BRAND));
      } else if (section === "ABOUT_THE_PRODUCT") {
        dispatch(setAboutProduct(response.ABOUT_THE_PRODUCT));
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  const contentHeaders = [
    {
      title: `${labels.ALL_ABOUT} ${brandSelectedVal}`,
      onEdit: () => handleEdit("ABOUT_THE_BRAND"),
      content: editState.ABOUT_THE_BRAND.isEdit ? (
        <Box width={"100%"}>
          <CustomInputTextarea
            wordLimit={200}
            isCancelSaveRequired={true}
            handleCancelClick={() => handleCancelClick("ABOUT_THE_BRAND")}
            handleSaveChangesClick={() => handleDataSave("ABOUT_THE_BRAND")}
            data={editState.ABOUT_THE_BRAND.data}
            handleChange={(e) => handleInputChange(e, "ABOUT_THE_BRAND")}
          />
          <ImageWithIcon
            handleModalOpenClick={handleModalOpenClick}
            src={brandImageURL || brandLogo}
          />
          <UploadImageModal
            open={imageDialogOpen}
            onClose={() => setImageDialogOpen(false)}
            tabNames={["Brand"]}
            tabSelected={"Product"}
          />
        </Box>
      ) : (
        <>
          <Box>{editState.ABOUT_THE_BRAND.data}</Box>
          <ImageWithIcon
            handleModalOpenClick={handleModalOpenClick}
            src={brandImageURL || logoPath}
            disableEditIcon={true}
          />
          <UploadImageModal
            open={imageDialogOpen}
            onClose={() => setImageDialogOpen(false)}
            tabNames={["Brand"]}
            tabSelected={"Product"}
          />
        </>
      ),
    },
    {
      title: `${labels.ALL_ABOUT}  ${
        productSelectedVal === "Others"
          ? productOthersSelectedVal
          : productSelectedVal
      }`,
      onEdit: () => handleEdit("ABOUT_THE_PRODUCT"),
      content: editState.ABOUT_THE_PRODUCT.isEdit ? (
        <Box width={"100%"}>
          <CustomInputTextarea
            wordLimit={200}
            isCancelSaveRequired={true}
            handleCancelClick={() => handleCancelClick("ABOUT_THE_PRODUCT")}
            handleSaveChangesClick={() => handleDataSave("ABOUT_THE_PRODUCT")}
            data={editState.ABOUT_THE_PRODUCT.data}
            handleChange={(e) => handleInputChange(e, "ABOUT_THE_PRODUCT")}
          />
          <ImageWithIcon
            handleModalOpenClick={handleModalOpenClick}
            src={productImageURL || blobProductImageURL}
          />
          <UploadImageModal
            open={imageDialogProductOpen}
            onClose={() => setImageDialogProductOpen(false)}
            tabNames={["Product"]}
            tabSelected="Product"
          />
        </Box>
      ) : (
        <>
          <Box>{editState.ABOUT_THE_PRODUCT.data}</Box>
          <ImageWithIcon
            handleModalOpenClick={handleModalOpenProductClick}
            src={productImageURL || blobProductImageURL}
          />
          <UploadImageModal
            open={imageDialogProductOpen}
            onClose={() => setImageDialogProductOpen(false)}
            tabNames={["Product"]}
            tabSelected={"Product"}
          />
        </>
      ),
    },
  ];

  return (
    <CommonLayout
      onHandleNextClick={handleNext}
      tooltipData={GetToKnowUsTooltipData}
      contentHeaders={contentHeaders}
    />
  );
}

export default GetToKnow;
