import React, { useState } from "react";
import { Box } from "@mui/material";
import CustomizedTooltips from "../../components/Tooltip/CustomizedTooltips";
import TitleEditIcon from "../../components/TitleEditIcon/TitleEditIcon";
import Footer from "../../components/Footer/Footer";
import "./CommonLayout.css";
import { useSelector } from "react-redux";

function CommonLayout({
  tooltipData=[{}],
  contentHeaders,
  onHandleNextClick,
  activeIndex,
  handleNext,
  isPromptRequired = false,
  isCreativeChallenge = false,
  children,
  setInputPrompt,
  editBriefPromptAPICallClick,
  setReadyMadePrompt,
 
}) {
  const tooltipContent = {
    content: (
      <>
        { Array.isArray(tooltipData) && tooltipData.length > 0 && tooltipData.map((item, index) => (
          <Box key={index} className="tooltipItem">
            <Box className="titleText">{item.title}:</Box>
            <Box className="titleDesc">{item.description}:</Box>
          </Box>
        ))}
      </>
    ),
  };
  
  const { selectedOutputLanguage, } = useSelector(
    (state) => state.addMoreFlavour
  );

  return (
    <Box marginRight={"20px"}>
      <div>
        <CustomizedTooltips  data={tooltipContent.content} />
        <div className="contentWrapper">
          <div className="campaignOverview">
            {contentHeaders &&
              contentHeaders.map((item, index) => (
                <div key={index}>
                  <Box className="titleEditIcon">
                    {item.title && (
                      <TitleEditIcon title={item.title} onEdit={item.onEdit}  isEditRequired={item.isEditIconReq } />
                    )}
                   <Box marginTop={"10px"} >
                   {item.subtitle && (
                      <TitleEditIcon
                        isEditRequired={false}
                        title={item.subtitle}
                      />
                    )}
                   </Box>
                  </Box>
                  <div className="briefContent">{item.content}</div>
                </div>
              ))}
          </div>
        </div>
        <Box marginTop={"10px"}>
          <Footer
            setInputPrompt={setInputPrompt}
            setReadyMadePrompt={setReadyMadePrompt}
            isPromptRequired={isPromptRequired}
            handleNextTab={onHandleNextClick}
            editBriefPromptAPICallClick={editBriefPromptAPICallClick}
          />
         
        </Box>
      </div>
    </Box>
  );
}

export default CommonLayout;
