import React from "react";
import { useSelector, useDispatch } from "react-redux";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InfoIcon from "@mui/icons-material/Info";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import {
  setUserPromptSelectedVal,
} from "../../slicers/createBriefSlicer";
import {
  heading,
  subHeadingLine1,
  subHeadingLine2,
  subHeadingLine2TooltipData,
  subHeadingLine2TooltipDataExample,
  disclaimer,
  promptBoxPlaceholder,
} from "../../Constant/ShapeYourBrief";

function ShapeYourBrief({ expanded, onChange }) {
  const dispatch = useDispatch();
  const {
    userPromptSelectedVal,
  } = useSelector((state) => state.createBrief);

  
  const wordLimitUserPrompt = 150;

  const handleChange = (event) => {
    if (event.target.value.split(" ").length <= wordLimitUserPrompt) {
      dispatch(setUserPromptSelectedVal(event.target.value));
    }
  };

  const shapeBriefTooltipData = (
    <>
      <Typography
        sx={{
          color: "#111111",
          fontFamily: "Unilever Shilling",
          fontSize: "12px",
          fontStyle: "italic",
          fontWeight: 400,
          lineHeight: "20px",
        }}
      >
        {subHeadingLine2TooltipData}
      </Typography>
      <Box
        sx={{
          backgroundColor: "rgba(0, 94, 239, 0.1)",
          borderRadius: "10px",
          padding: "6px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#111111",
            fontFamily: "Unilever Shilling",
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          {subHeadingLine2TooltipDataExample}
        </Typography>
      </Box>
    </>
  );

  return (
    <Accordion
      expanded={expanded === "panel1"}
      onChange={onChange("panel1")}
      sx={{ borderBottom: "1px solid #dcdcdc", boxShadow: "none" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box
          sx={{
            width: "8px",
            height: "20px",
            backgroundColor: "#47C8FF",
            marginRight: "8px",
          }}
        ></Box>
        <Box
          style={{
            fontWeight: 600,
            fontSize: "14px",
            fontFamily: "Unilever Shilling",
          }}
        >
          {heading}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box sx={{ display: "flex", alignItems: "center" }}></Box>
            <Box
              style={{
                fontWeight: 400,
                fontSize: "14px",
                fontFamily: "Unilever Shilling",
              }}
            >
              {subHeadingLine1}
            </Box>
            <Box
              style={{
                fontWeight: 400,
                fontSize: "14px",
                fontFamily: "Unilever Shilling",
              }}
            >
              {subHeadingLine2}
              <CustomTooltip
                title={shapeBriefTooltipData}
                tooltipPlacement={"bottom"}
                width={270}
              >
                <InfoIcon style={{ fontSize: "16px" }} />
              </CustomTooltip>
            </Box>
            <Box style={{ height: "4%" }}></Box>
            <Box
              style={{
                fontWeight: 900,
                fontSize: "14px",
                color: "#47C8FF",
                fontFamily: "Unilever Shilling",
              }}
            >
              {disclaimer}
            </Box>
            <Box style={{ height: "10%" }}></Box>
            <Grid container spacing={2} alignItems="center">
              <Grid item xs={10}>
                <TextField
                  id="outlined-multiline-flexible"
                  value={userPromptSelectedVal}
                  multiline
                  fullWidth
                  maxRows={3}
                  placeholder={promptBoxPlaceholder}
                  onChange={(e) => handleChange(e)}
                  style={{ border: "1px solid #EBEBEB" }}
                  InputProps={{
                    sx: {
                      fontSize: "14px",
                      height: "70px",
                      fontFamily: "Unilever Shilling",
                      background: "#ffffff",
                      color: "#767676 !important",
                    },
                  }}
                  InputLabelProps={{
                    sx: {
                      fontSize: "14px",
                      top: "2px",
                      "&.MuiInputLabel-shrink": {
                        top: 0,
                        fontFamily: "Unilever Shilling",
                        background: "#ffffff",
                        color: "#005EEF !important",
                      },
                      fontFamily: "Unilever Shilling",
                      background: "#ffffff",
                      color: "#767676 !important",
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                xs={2}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "left",
                }}
              >
                <Box display="flex" justifyContent="center">
                  {" "}
                </Box>
              </Grid>
              <Grid item xs={12}></Grid>
            </Grid>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
}

export default ShapeYourBrief;
