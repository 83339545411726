import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";


const timeAgo = (dateString) => {
  const pastDate = new Date(dateString);
  const now = new Date();

  let diffInSeconds = Math.floor((now - pastDate) / 1000);

  diffInSeconds = Math.max(diffInSeconds, 1);
  const diffInMinutes = Math.floor(diffInSeconds / 60);
  const diffInHours = Math.floor(diffInMinutes / 60);
  const diffInDays = Math.floor(diffInHours / 24);

  if (diffInDays > 0) {
    return `${diffInDays} day${diffInDays > 1 ? "s" : ""} ago`;
  } else if (diffInHours > 0) {
    return `${diffInHours} hour${diffInHours > 1 ? "s" : ""} ago`;
  } else if (diffInMinutes > 0) {
    return `${diffInMinutes} minute${diffInMinutes > 1 ? "s" : ""} ago`;
  } else {
    return `${diffInSeconds} second${diffInSeconds > 1 ? "s" : ""} ago`;
  }
};

const DateTimeStamp = () => {
  const { createUpdateDateTime } = useSelector((state) => state.editYourBrief);
  
  // Use the createUpdateDateTime directly without splitting
  const formattedPastDateString = createUpdateDateTime; 

  const [timeDifference, setTimeDifference] = useState(
    timeAgo(formattedPastDateString)
  );

  useEffect(() => {
    // Update time difference whenever createUpdateDateTime changes
    if (createUpdateDateTime) {
      setTimeDifference(timeAgo(createUpdateDateTime));
    }
  }, [createUpdateDateTime]);

  return (
    <div>
      <p className="timeStampText">Last Updated: {timeDifference}</p>
    </div>
  );
};

export default DateTimeStamp;
