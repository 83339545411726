import React, { useState } from "react";
import CommonLayout from "../../pages/CommonLayout/CommonLayout";
import ImageListComponent from "../ImageList/ImageList";
import CustomizedTooltips from "../Tooltip/CustomizedTooltips";
import { inspirationTooltip } from "../../Constant/EditBreifTooltip";
import { useDispatch, useSelector } from "react-redux";
import CustomInputTextarea from "../CustomInputTextarea/CustomInputTextarea";
import { setInspirationVideoLink } from "../../slicers/editYourBriefSlicer";
import { Box } from "@mui/material";
import apiService from "../../utilities/service/apiService";
import { updateBriefData } from "../../utilities/apiUtils/apiUtils";
import { useTextLabels } from "../../Constant/useTextLabels";

function Inspiration({ handleNext }) {
  const { inspirationImageURL,  briefData,  briefId, } = useSelector((state) => state.createBrief);
  const { inspirationVideoLink } = useSelector((state) => state.editYourBrief);
  const dispatch = useDispatch();
  let data = [];
  if (Array.isArray(inspirationImageURL)) {
    data = inspirationImageURL.map((url, index) => ({ link: url }));
  } else if (inspirationImageURL) {
    data = [{ link: inspirationImageURL }];
  }
const labels=useTextLabels();
  const [isEdit, setIsEdit] = useState(false);
  const [inputData, setInputData] = useState(inspirationVideoLink);

  const handleEdit = () => {
    setIsEdit(true);
  };
  const onCancelClick = () => {
    setIsEdit(false);
    setInputData("");
  };
  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };

  const handleDataSave =async () => {
  
    try {
      const response = await apiService({
        method: "PUT",
        url: `${window.location.origin}/briefs/${briefId}`,
        body : updateBriefData(
          "VIDEO_LINK",
          inputData,
          briefData,
          dispatch
        ),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setInputData(inputData)
      dispatch(setInspirationVideoLink(inputData));
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const contentHeaders = [
    {
      content: (
        <div className="inspirationLayout">
          <ImageListComponent data={data} />
        </div>
      ),
    },
    {
      title: "Paste here the weblink for any sample videos (optional)",
      onEdit: handleEdit,
      content: isEdit ? (
        <CustomInputTextarea
          placeholder="Paste link here"
          isPlaceholder={true}
          isCancelSaveRequired={isEdit}
          handleCancelClick={onCancelClick}
          handleSaveChangesClick={handleDataSave}
          data={inputData}
          handleChange={handleInputChange}
        />
      ) : (
        <Box style={{paddingBottom:"20px"}}> {inputData}</Box>
      ),
    },
  ];

  return (
    <>
      <CommonLayout
        onHandleNextClick={handleNext}
        tooltipData={inspirationTooltip}
        contentHeaders={contentHeaders}
      />
    </>
  );
}

export default Inspiration;
