import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { setSelectedOutputLanguage } from "../../slicers/addMoreFlavourSlicer";
import "./LanguageChangeLink.css";

function LanguageChangeLink({ text = "View" }) {
  const dispatch = useDispatch();
  const { selectedOutputLanguage, outputLanguageList } = useSelector(
    (state) => state.addMoreFlavour
  );

  const currentLanguage = outputLanguageList.find(
    (lang) => lang.language_name !== selectedOutputLanguage
  );

  const handleLanguageChangeClick = () => {
    if (currentLanguage) {
      dispatch(setSelectedOutputLanguage(currentLanguage.language_name));
    }
  };

  return (
    <p className="changeLanguageText" onClick={handleLanguageChangeClick}>
      {`${text} in ${currentLanguage ? currentLanguage.language_name : selectedOutputLanguage}`}
    </p>
  );
}

export default LanguageChangeLink;
