import React from "react";
import Heading from "../Heading";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import "./Slide8.css";
import {
  deliverablesText,
  handleText,
  hashtagsText,
  linkToUseText,
  postingTimelineText,
  socialMediahandlesText,
} from "../../../Constant/EditBrief";

function Slide8({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  illustrationBottomLeft,
  contentFontSize,
  illustrationBottomMiddle,
  illustrationBottomRight,
  subHeadingContentFontSize,
  timelineFontColor,
  placement,
  labels,
}) {
  const {
    deliverables,
    postingTimeline,
    socialMediaHashtag,
    hashtags,
    linkToUse,
  } = useSelector((state) => state.editYourBrief);

  return (
    <Box display={"flex"} className={`${placement}`} height={"100%"}>
      <Box position={"relative"} width={"50%"}>
        <Box className="leftMainSlide8">
          <Heading
            text={labels.DELIVERABLES}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
          <Heading
            text={deliverables}
            textColor={fontColor}
            textSize={contentFontSize}
            textWeight={headingFontWeight}
          />
          <Box className="handleHastagesMain">
            <Heading
              text={labels.SOCIAL_MEDIA_HANDLES_AND_HASHTAGS}
              textColor={fontColor}
              textSize={headingFontSize}
              textWeight={headingFontWeight}
            />
            <Heading
              text={labels.HASHTAGS}
              textColor={fontColor}
              textSize={subHeadingContentFontSize}
              textWeight={headingFontWeight}
            />
            <Heading
              text={hashtags}
              textColor={fontColor}
              textSize={contentFontSize}
              textWeight={headingFontWeight}
            />
            <Heading
              text={labels.HANDLES}
              textColor={fontColor}
              textSize={subHeadingContentFontSize}
              textWeight={headingFontWeight}
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                columnGap: "10px",
                marginTop: "10px",
              }}
            >
              {socialMediaHashtag.map((handle, index) => (
                <p key={index} style={{ margin: "0" }}>
                  {Object.entries(handle).map(([platform, username]) => (
                    <Box>
                      <Heading
                        text={`${platform}: ${username}`}
                        textColor={fontColor}
                        textSize={subHeadingFontSize}
                        textWeight={subHeadingFontWeight}
                      />
                    </Box>
                  ))}
                </p>
              ))}
            </div>
          </Box>

          <Heading
            text={labels.LINKS_TO_USE_WITHIN_CAPTIONS}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
          <Heading
            text={linkToUse}
            textColor={fontColor}
            textSize={contentFontSize}
            textWeight={headingFontWeight}
          />
        </Box>
        {illustrationBottomLeft && (
          <img src={illustrationBottomLeft} className="illustration1Slide8" />
        )}
      </Box>
      <Box position={"relative"} width={"50%"}>
        <Box className="postingTimeMain">
          <Box className="postingTimeText">
            <Heading
              text={labels.POSTING_TIMELINES}
              textSize={headingFontSize}
              textColor={timelineFontColor}
              textWeight={headingFontWeight}
            />
          </Box>
          {postingTimeline && (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "18px",
              }}
            >
              {Object.entries(postingTimeline).map(([key, value], index) => (
                <div key={index} style={{ display: "flex", columnGap: "60px" }}>
                  <div style={{ width: "250px" }}>
                    <Heading
                      text={key}
                      textColor={timelineFontColor}
                      textSize={subHeadingFontSize}
                      textWeight={subHeadingFontWeight}
                    />
                  </div>
                  <div style={{ flexGrow: 1 }}>
                    <Heading
                      text={value}
                      textColor={timelineFontColor}
                      textSize={subHeadingFontSize}
                      textWeight={subHeadingFontWeight}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </Box>
        {illustrationBottomMiddle && (
          <img src={illustrationBottomMiddle} className="illustration2Slide8" />
        )}
        {illustrationBottomRight && (
          <img src={illustrationBottomRight} className="illustration3Slide8" />
        )}
      </Box>
    </Box>
  );
}

export default Slide8;
