import React, { useState } from "react";
import {
  Box,
  Dialog,
  InputAdornment,
  TextField,
  Typography,
} from "@mui/material";
import PreviewLayout from "../PPTPreviewLayout/PreviewLayout";
import { useDispatch, useSelector } from "react-redux";
import { brandOptions as brandInfo } from "../../Constant/PreviewPPTLayoutInfo";
import Slide1 from "../Slides/Slide1/Slide1";
import Slide2 from "../Slides/Slide2/slide2";
import Slide3 from "../Slides/Slide3/Slide3";
import Slide4 from "../Slides/Slide4/Slide4";
import Slide5 from "../Slides/Slide5/Slide5";
import Slide6 from "../Slides/Slide6/Slide6";
import Slide7 from "../Slides/Slide7/Slide7";
import Slide9 from "../Slides/Slide9/Slide9";
import Slide8 from "../Slides/Slide8/Slide8";
import pptIcon from "../../assets/images/pptIcon.jpg";
import { setPPTDownloadName } from "../../slicers/editYourBriefSlicer";
import PPTGenerator from "../PPTGenerator/PPTGenerator";
import "./PreviewAndDownload.css";
import PPTGeneratorKnorr from "../PPTGenerator/PPTGeneratorKnorr";
import DownloadModalbackground from "../../assets/images/DownloadModalbackground.jpg";
import { useTextLabels } from "../../Constant/useTextLabels";

const imageContext = require.context("../../assets/images", true);

function PreviewAndDownload({ setActiveIndex }) {
  const { brandSelectedVal } = useSelector((state) => state.createBrief);
  const labels = useTextLabels();
  const filteredBrandInfo = brandInfo.filter(
    (brand) => brand.brand_name === brandSelectedVal
  );
  const { pptDownloadName } = useSelector((state) => state.editYourBrief);
 
  const selectedBrand = filteredBrandInfo[0];
  const handleEditClick = (index) => {
    setActiveIndex(index);
  };
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const [pptName, setPPTName] = useState(pptDownloadName);
  const logoPath = imageContext(`./${brandSelectedVal}/${selectedBrand?.logo}`);
  const getImageContext = (path) =>
    path ? imageContext(`./${brandSelectedVal}/${path}`) : undefined;

  return (
    <Box
      className="containerCommon"
      style={{ fontFamily: selectedBrand?.fontFamily }}
    >
      <Box display={"flex"} flexDirection={"column"} rowGap={"20px"}>
        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide1.backgroundImage
          )}
          watermark={selectedBrand?.slide1.watermark}
          Content={
            <Slide1
              subHeadingFontWeight={
                selectedBrand?.slide1.subHeadingFontWeightPreview
              }
              headingFontWeight={selectedBrand?.slide1.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide1.headingFontSizePreview}
              subHeadingFontSize={
                selectedBrand?.slide1.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide1.headingFontColor}
              illustrationLogo={getImageContext(
                selectedBrand?.slide1.illustrationLogo
              )}
              subHeadingBackgroundImage={getImageContext(
                selectedBrand?.slide1.subHeadingBackgroundImage
              )}
              templateType={selectedBrand?.templateType}
              labels={labels}
              logo={logoPath}
            />
          }
          hideLogoEditPen={true}
        />

        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide2.backgroundImageLeft?.path
          )}
          backgroundImageRight={getImageContext(
            selectedBrand?.slide2.backgroundImageRight?.path
          )}
          watermark={selectedBrand?.slide2.watermark}
          handleEditClick={() => handleEditClick(0)}
          placement={selectedBrand?.slide2.placement}
          Content={
            <Slide2
              headingFontWeight={selectedBrand?.slide2.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide2.headingFontSizePreview}
              subHeadingFontWeight={
                selectedBrand?.slide2.subHeadingFontWeightPreview
              }
              subHeadingFontSize={
                selectedBrand?.slide2.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide2.headingFontColor}
              coverImageRight={getImageContext(
                selectedBrand?.slide2.coverImageRight?.path
              )}
              illustrationBottomLeft={getImageContext(
                selectedBrand?.slide2.illustrationBottomLeft?.path
              )}
              illustrationBottomRight={getImageContext(
                selectedBrand?.slide2.illustrationBottomRight?.path
              )}
              coverImageLeft={getImageContext(
                selectedBrand?.slide2.coverImageLeft?.path
              )}
              placement={selectedBrand.slide2.placement}
              labels={labels}
            />
          }
        />

        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide3.backgroundImageLeft?.path
          )}
          backgroundImageRight={getImageContext(
            selectedBrand?.slide3.backgroundImageRight?.path
          )}
          handleEditClick={() => handleEditClick(0)}
          placement={selectedBrand?.slide3.placement}
          watermark={selectedBrand?.slide3.watermark}
          Content={
            <Slide3
              subHeadingFontWeight={
                selectedBrand?.slide3.subHeadingFontWeightPreview
              }
              headingFontWeight={selectedBrand?.slide3.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide3.headingFontSizePreview}
              subHeadingFontSize={
                selectedBrand?.slide3.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide3.headingFontColor}
              illustrationTopLeft={getImageContext(
                selectedBrand?.slide3.illustrationTopLeft?.path
              )}
              illustrationBottomRight={getImageContext(
                selectedBrand?.slide3.illustrationBottomRight?.path
              )}
              placement={selectedBrand.slide3.placement}
              labels={labels}
            />
          }
        />

        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide4.backgroundImageLeft?.path
          )}
          backgroundImageRight={getImageContext(
            selectedBrand?.slide4.backgroundImageRight?.path
          )}
          handleEditClick={() => handleEditClick(1)}
          placement={selectedBrand?.slide4.placement}
          watermark={selectedBrand?.slide4.watermark}
          Content={
            <Slide4
              subHeadingFontWeight={
                selectedBrand?.slide4.subHeadingFontWeightPreview
              }
              headingFontWeight={selectedBrand?.slide4.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide4.headingFontSizePreview}
              subHeadingFontSize={
                selectedBrand?.slide4.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide4.headingFontColor}
              illustrationBottomLeft={getImageContext(
                selectedBrand?.slide4.illustrationBottomLeft?.path
              )}
              illustrationBottomRight={getImageContext(
                selectedBrand?.slide4.illustrationBottomRight?.path
              )}
              placement={selectedBrand?.slide4.placement}
              defaultCoverImageLeft={getImageContext(
                selectedBrand?.slide4.defaultCoverImageLeft?.path
              )}
              labels={labels}
            />
          }
        />

        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide5.backgroundImageLeft?.path
          )}
          backgroundImageRight={getImageContext(
            selectedBrand?.slide5.backgroundImageRight?.path
          )}
          handleEditClick={() => handleEditClick(1)}
          placement={selectedBrand?.slide5.placement}
          watermark={selectedBrand?.slide5.watermark}
          Content={
            <Slide5
              subHeadingFontWeight={
                selectedBrand?.slide5.subHeadingFontWeightPreview
              }
              headingFontWeight={selectedBrand?.slide5.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide5.headingFontSizePreview}
              subHeadingFontSize={
                selectedBrand?.slide5.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide5.headingFontColor}
              listContentFontSize={
                selectedBrand?.slide5.listContentFontSizePreview
              }
              secondSubHeadingFontColor={
                selectedBrand?.slide5.secondSubHeadingFontColor
              }
              subHeadingFillColor={
                selectedBrand?.slide5.secondSubHeadingFillColor
              }
              secondSubHeadingFontSize={
                selectedBrand?.slide5.secondSubHeadingFontSize
              }
              coverImageRight={getImageContext(
                selectedBrand?.slide5.coverImageRight?.path
              )}
              placement={selectedBrand.slide5.placement}
              labels={labels}
            />
          }
        />

        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide6.backgroundImage
          )}
          handleEditClick={() => handleEditClick(1)}
          watermark={selectedBrand?.slide6.watermark}
          Content={
            <Slide6
              subHeadingFontWeight={
                selectedBrand?.slide6.subHeadingFontWeightPreview
              }
              headingFontWeight={selectedBrand?.slide6.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide6.headingFontSizePreview}
              subHeadingFontSize={
                selectedBrand?.slide6.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide6.headingFontColor}
              listContentFontSize={
                selectedBrand?.slide6.listContentFontSizePreview
              }
              subheadingFontColor={selectedBrand?.slide6.subHeadingFontColor}
              subHeadingFillColor={selectedBrand?.slide6.subHeadingFillColor}
              illustrationBottomLeft={getImageContext(
                selectedBrand?.slide6.illustrationBottomLeft
              )}
              illustrationBottomRight={getImageContext(
                selectedBrand?.slide6.illustrationBottomRight
              )}
              labels={labels}
            />
          }
        />

        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide7.backgroundImageLeft?.path
          )}
          backgroundImageRight={getImageContext(
            selectedBrand?.slide7.backgroundImageRight?.path
          )}
          handleEditClick={() => handleEditClick(2)}
          placement={selectedBrand?.slide7.placement}
          watermark={selectedBrand?.slide7.watermark}
          Content={
            <Slide7
              subHeadingFontWeight={
                selectedBrand?.slide7.subHeadingFontWeightPreview
              }
              headingFontWeight={selectedBrand?.slide7.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide7.headingFontSizePreview}
              subHeadingFontSize={
                selectedBrand?.slide7.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide7.headingFontColor}
              illustrationBottomRight={getImageContext(
                selectedBrand?.slide7.illustrationBottomRight?.path
              )}
              illustrationBottomLeft={getImageContext(
                selectedBrand?.slide7.illustrationBottomLeft?.path
              )}
              doFontColor={selectedBrand.slide7.doFontColor}
              doNotFontColor={selectedBrand.slide7.doNotFontColor}
              placement={selectedBrand.slide7.placement}
              labels={labels}
            />
          }
        />

        <PreviewLayout
          backgroundImageLeft={getImageContext(
            selectedBrand?.slide8.backgroundImageLeft?.path
          )}
          backgroundImageRight={getImageContext(
            selectedBrand?.slide8.backgroundImageRight?.path
          )}
          handleEditClick={() => handleEditClick(2)}
          placement={selectedBrand?.slide8.placement}
          watermark={selectedBrand?.slide8.watermark}
          Content={
            <Slide8
              subHeadingFontWeight={
                selectedBrand?.slide8.subHeadingFontWeightPreview
              }
              headingFontWeight={selectedBrand?.slide8.headingFontWeightPreview}
              headingFontSize={selectedBrand?.slide8.headingFontSizePreview}
              subHeadingFontSize={
                selectedBrand?.slide8.subHeadingFontSizePreview
              }
              fontColor={selectedBrand?.slide8.headingFontColor}
              illustrationBottomRight={getImageContext(
                selectedBrand?.slide8.illustrationBottomRight?.path
              )}
              illustrationBottomLeft={getImageContext(
                selectedBrand?.slide8.illustrationBottomLeft?.path
              )}
              illustrationBottomMiddle={getImageContext(
                selectedBrand?.slide8.illustrationBottomMiddle?.path
              )}
              subHeadingContentFontSize={
                selectedBrand?.slide8.subContentHeadingFontSizePreview
              }
              timelineFontColor={
                selectedBrand?.slide8.postingTimelineHeadingFontColor
              }
              placement={selectedBrand.slide8.placement}
              labels={labels}
            />
          }
        />

<PreviewLayout
          backgroundImageLeft={imageContext(
            `./${brandSelectedVal}/${selectedBrand.slide9?.backgroundImage}`
          )}
          watermark={selectedBrand.slide9?.watermark}
          Content={
            <Slide9
              headingFontWeight={selectedBrand.slide9.headingFontWeightPreview}
              headingFontSize={selectedBrand.slide9.headingFontSizePreview}
              logo={imageContext(
                `./${brandSelectedVal}/${selectedBrand?.slide9.illustrationLogo}`
              )}
              fontColor={selectedBrand.slide9.headingFontColor}
              labels={labels}
            />
          }
          hideLogoEditPen={true}
          labels={labels}
        />
      </Box>

      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <img src={DownloadModalbackground} />
        <Box className="downloadingText">You are downloading..</Box>
        <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
          <TextField
            style={{ width: "50%" }}
            placeholder={"Enter the PPT Name"}
            value={pptName}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img src={pptIcon} width={20} height={20} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <p
                    style={{
                      fontFamily: "Unilever Shilling",
                      fontSize: "15px",
                      fontWeight: 400,
                      lineHeight: "18px",
                    }}
                  >
                    .pptx
                  </p>
                </InputAdornment>
              ),
            }}
            onChange={(e) => {
              const newValue = e.target.value;
              setPPTName(newValue);
              dispatch(setPPTDownloadName(e.target.value));
            }}
          />
        </Box>

        <Box display={"flex"} justifyContent={"flex-end"} columnGap={"10px"}>
          <Box
            style={{ cursor: "pointer" }}
            marginTop={"35px"}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Box>
          <PPTGeneratorKnorr />
        </Box>
      </Dialog>

      <Box className="downloadBtnLayout">
        <Box className="downloadPPTBtn" onClick={() => setOpen(true)}>
          Download
        </Box>
        <Typography>Or</Typography>
        <Box className="downloadPPTBtn" onClick={() => handleEditClick(0)}>
          Edit
        </Box>
      </Box>
    </Box>
  );
}

export default PreviewAndDownload;
