import React from "react";
import "./Heading.css";

function Heading({ text, textColor, textSize, textWeight }) {
  const style = {
    "--text-color": `#${textColor}`,
    "--text-size": `${textSize}px`,
    "--text-weight": textWeight,
  };

  return (
    <div className="headingText" style={style}>
      {text}
    </div>
  );
}

export default Heading;
