import React, { useState } from "react";
import { Box, Grid } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import {
  EDITBRIEFDATA,
  makeTweaksText,
  previewBriefText,
} from "../../Constant/EditBrief";
import "./EditYourBrief.css";
import peopleChefImg from "../../assets/images/People chef transparent background 1.png";
import studyImg from "../../assets/images/studyImg.png";
import backArrow from "../../assets/images/backArrow.png";
import peopleChefBackground from "../../assets/images/peopleChefBackground.png";
import instaIcon from "../../assets/images/instaIcon.png";
import tiktokIcon from "../../assets/images/tiktokIcon.png";
import youtubeIcon from "../../assets/images/youtubeIcon.png";
import GetToKnow from "../../components/GetToKnow/GetToKnow";
import YourCreativeChallenges from "../../components/YourCreativeChallenges/YourCreativeChallenge";
import ThingsToKeepInMind from "../../components/ThingsToKeepInMind/ThingsToKeepInMind";
import BriefEditSteps from "../../components/BriefEditSteps/BriefEditSteps";
import Header from "../../components/Header/Header";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import PreviewAndDownload from "../../components/PreviewAndDownload/PreviewAndDownload";
import { setIsViewPreviousBriefModalOpen } from "../../slicers/createBriefSlicer";
import LanguageChangeLink from "../../components/LanguageChangeLink/LanguageChangeLink";

const CheckCircle = ({ checked }) => (
  <Box>
    {checked ? (
      <CheckCircleIcon className="checkedCircleIcon" />
    ) : (
      <RadioButtonUncheckedIcon className="uncheckedCircleIcon" />
    )}
  </Box>
);

const EditContentRendering = ({
  step,
  handleNext,
  selectedTab,
  setSelectedTab,
}) => {
  switch (step) {
    case 0:
      return (
        <GetToKnow
          isEditPPT={true}
          activeIndex={step}
          handleNext={handleNext}
        />
      );
      break;
    case 1:
      return (
        <YourCreativeChallenges
          selectedTab={selectedTab}
          setSelectedTab={setSelectedTab}
          activeIndex={step}
          handleNext={handleNext}
        />
      );
      break;
    case 2:
      return <ThingsToKeepInMind activeIndex={step} handleNext={handleNext} />;
      break;
      break;
    default:
      return null;
  }
};

function EditYourBrief() {
  const { activeIndexofTab } = useSelector((state) => state.editYourBrief);
  const { isLoading, brandSelectedVal } = useSelector(
    (state) => state.createBrief
  );
  const [activeIndex, setActiveIndex] = useState(activeIndexofTab); //setting the active tab based on redux state
  const [checkedStates, setCheckedStates] = useState(
    Array(EDITBRIEFDATA.length).fill(false)
  );
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [selectedTab, setSelectedTab] = useState(1);
  const handleCardClick = (index) => {
    setActiveIndex(index);
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[index] = true;
      return newStates;
    });
    setSelectedTab(1);
  };

  const handleNext = () => {
    setActiveIndex(activeIndex + 1);
    setCheckedStates((prevStates) => {
      const newStates = [...prevStates];
      newStates[activeIndex + 1] = true;
      return newStates;
    });
    setSelectedTab(1);
  };
  const handleGoBack = () => {
    if (activeIndex === 3) {
      dispatch(setIsViewPreviousBriefModalOpen(true));
      navigate("/create-brief");
    } else {
      setActiveIndex(3);
    }
  };

  const handlePreviewLanguageChangeClick = () => {
    console.log("preview language click api");
  };

  return (
    <Box>
      <Header />
      {isLoading && <LoadingScreen />}
      <Box className="goBack">
        <img
          onClick={handleGoBack}
          src={backArrow}
          style={{ marginTop: "7px" }}
          height={24}
          width={24}
        />
        <Box className="goBackText">
          {activeIndex === 3 ? previewBriefText : makeTweaksText}
        </Box>
      </Box>

      {activeIndex === 3 ? (
        <>
          {/* <Box className="changeLanguageTextPreviewPosition">
            <LanguageChangeLink text="Preview" />
          </Box> */}
          <PreviewAndDownload setActiveIndex={setActiveIndex} />
        </>
      ) : (
        <>
          <Box className="studyImg">
            <img src={studyImg} />
          </Box>
          <Grid
            container
            spacing={5}
            position={"relative"}
            zIndex={1}
            marginTop={"10px"}
          >
            <Grid item xs={6} md={4}>
              <div className="editStepsLayout">
                {EDITBRIEFDATA.map((item, index) => (
                  <Box className="cardCheckLayout" key={index}>
                    <BriefEditSteps
                      item={item}
                      index={index}
                      activeIndex={activeIndex}
                      isActive={activeIndex === index}
                      onClick={handleCardClick}
                    />
                    <CheckCircle
                      checked={index === 0 ? true : checkedStates[index]}
                    />
                  </Box>
                ))}
              </div>
              <Box position="relative" bottom={200} zIndex={-1}>
                <img
                  src={peopleChefBackground}
                  alt="People Chef Background"
                  className="briefStepBackground"
                />
                <img
                  src={peopleChefImg}
                  alt="People Chef"
                  className="chefImg"
                />
                <Box>
                  <img src={instaIcon} className="instaIcon" />
                  <img src={youtubeIcon} className="youTubeIcon" />
                </Box>
              </Box>
            </Grid>
            <Grid item xs={6} md={8}>
              <Box>
                <EditContentRendering
                  step={activeIndex}
                  selectedTab={selectedTab}
                  setSelectedTab={setSelectedTab}
                  handleNext={handleNext}
                />
              </Box>
            </Grid>
          </Grid>
        </>
      )}
    </Box>
  );
}

export default EditYourBrief;
