import React from "react";
import { Box, Grid } from "@mui/material";

const CustomImageList = ({ images }) => {
  const imageCount = images.length;

  // Define the grid layout structure based on the number of images
  let firstRowGrid;
  let secondRowGrid;

  if (imageCount === 1) {
    firstRowGrid = 4;
    secondRowGrid = 4;
  } else if (imageCount === 2) {
    firstRowGrid = 2;
    secondRowGrid = 2;
  } else if (imageCount === 3) {
    firstRowGrid = 0;
    secondRowGrid = 0;
  } else if (imageCount === 4) {
    firstRowGrid = 2;
    secondRowGrid = 2;
  } else if (imageCount === 5) {
    firstRowGrid = 4;
    secondRowGrid = 4;
  }

  return (
    <Grid container spacing={2} height={"100%"}>
      <Grid item xs={firstRowGrid}></Grid>

      <Grid container item xs={12} spacing={2} justifyContent="center">
        {images.map((image, index) => (
          <Grid
            key={index}
            item
            xs={imageCount === 1 ? 12 : imageCount === 4 ? 6 : 4}
            style={{ display: "flex", alignItems: "center",justifyContent:"center", height:"300px" }}
          >
           <Box style={{
              height:"285px",
              width:"380px"
           }}>
           <img
              src={image}
              alt={`Image ${index + 1}`}
           width={"100%"}
           height={"100%"}
            />
           </Box>
          </Grid>
        ))}
      </Grid>
      <Grid item xs={secondRowGrid}></Grid>
    </Grid>
  );
};

export default CustomImageList;
