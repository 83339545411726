import React from "react";
import { Box } from "@mui/material";
import "./Slide4.css";
import { useSelector } from "react-redux";
import Heading from "../Heading";
import { campainBackgroundText, theAskText } from "../../../Constant/EditBrief";

function Slide4({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  illustrationBottomLeft,
  illustrationBottomRight,
  placement,
  defaultCoverImageLeft,
  labels,
}) {
  const { campaignOverview, campaignGoal } = useSelector(
    (state) => state.editYourBrief
  );
  const { campaignImageURL } = useSelector((state) => state.createBrief);
  return (
    <Box display={"flex"} height={"100%"} className={`${placement}`}>
      <Box className="slide4Left" height={"100%"}>
       <Box margin={"35px"}>
       <img
          src={campaignImageURL || defaultCoverImageLeft}
          alt="Campaign Image"
          className="slide4CampaignImage"
        />
       </Box>
      </Box>
      <Box className="slide4Right" height={"100%"}>
        <Box className="campaignBGMain">
          <Heading
            text={labels.CAMPAIGN_BACKGROUND}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
          <Heading
            text={campaignOverview}
            textColor={fontColor}
            textSize={subHeadingFontSize}
            textWeight={subHeadingFontWeight}
          />
        </Box>
        <Box className="campaignGoalMain">
          <Heading
            text={labels.THE_ASK}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
          <Heading
            text={campaignGoal}
            textColor={fontColor}
            textSize={subHeadingFontSize}
            textWeight={subHeadingFontWeight}
          />
        </Box>
        <Box>
          {illustrationBottomLeft && (
            <img src={illustrationBottomLeft} className="illustration1Slide4" />
          )}
          {illustrationBottomRight && (
            <img
              src={illustrationBottomRight}
              className="illustration2Slide4"
            />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Slide4;
