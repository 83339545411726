import { removeIndexScore } from "../stringUtils";

const getSelectedValue = (
  tastewiseSelectionValue,
  trendingRecipeTastewiseSelectedVal,
  trendingRecipeTastewiseOthersSelectedVal,
  trendingRecipeSelectedVal,
  trendingRecipeOthersSelectedVal
) => {
  if (tastewiseSelectionValue === "Top Dish Trend Index") {
    if (trendingRecipeTastewiseSelectedVal === "Others") {
      return trendingRecipeTastewiseOthersSelectedVal;
    } else {
      return removeIndexScore(trendingRecipeTastewiseSelectedVal);
    }
  } else {
    if (trendingRecipeSelectedVal === "Others") {
      return trendingRecipeOthersSelectedVal;
    } else {
      return trendingRecipeSelectedVal;
    }
  }
};

export { getSelectedValue };
