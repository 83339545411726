import React from "react";
import Heading from "../Heading";
import CustomImageList from "../../CustomImageList/CustomImageList";
import "./Slide6.css";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { inspirationText } from "../../../Constant/EditBrief";

function Slide6({
  logo,
  fontColor,
  headingFontWeight,
  subheadingFontColor,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFillColor,
  subHeadingFontWeight,

  illustrationBottomLeft,
  headlineFontColor,
  illustrationBottomRight,
  labels,
}) {
  const { inspirationVideoLink } = useSelector((state) => state.editYourBrief);
  const { inspirationImageURL } = useSelector((state) => state.createBrief);

  return (
    <Box className="slide6-container">
      <Box className="headingMain">
        <Heading
          text={labels.INSPIRATION}
          textColor={fontColor}
          textSize={headingFontSize}
          textWeight={headingFontWeight}
        />
      </Box>
     
      <Box className="customImageListContainer">
        <CustomImageList images={inspirationImageURL} />
      </Box>
      <Box className="illustrationMainSlide6">
      {illustrationBottomLeft &&  <img src={illustrationBottomLeft} className="illustration1Slide6" alt="Illustration Left" />}
       {illustrationBottomRight && <img src={illustrationBottomRight} className="illustration2Slide6" alt="Illustration Right" />}
      </Box>
      {inspirationVideoLink && (
        <Box
          className="videoLinkMain"
          style={{
            background: `#${subHeadingFillColor}`,
            padding: "10px",
          }}
        >
          <Heading
            text={`Video Link: ${inspirationVideoLink}`}
            textColor={subheadingFontColor}
            textSize={subHeadingFontSize}
            textWeight={subHeadingFontWeight}
          />
        </Box>
      )}
    </Box>
  );
}
export default Slide6;
