import React from "react";
import { useSelector } from "react-redux";
import pptxgen from "pptxgenjs";
import { Box } from "@mui/material";
import { getProductImageKey } from "../../Constant/ProductImages";
import { removeIndexScore } from "../../utilities/stringUtils";
import "../../assets/styles/fonts.css";

const imageContext = require.context("../../assets/images", true);

function PPTGenerator() {
  const {
    aboutBrand,
    aboutProduct,
    campaignOverview,
    campaignGoal,
    captionIdeas,
    suggestedHeadlines,
    contentMandatories,
    socialMediaHashtag,
    hashtags,
    linkToUse,
    postingTimeline,
    deliverables,
    pptDownloadName,
    inspirationVideoLink,
  } = useSelector((state) => state.editYourBrief);
  const {
    brandOptions,
    brandSelectedVal,
    topDishSelectedVal,
    trendingRecipeTastewiseSelectedVal,
    productSelectedVal,
    productOthersSelectedVal,
    topDishTastewiseSelectedVal,
    trendingRecipeTastewiseOptions,
    trendingRecipeSelectedVal,
    tastewiseSelectionValue,
    marketSelectedVal,
    productImageURL,
    brandImageURL,
    campaignImageURL,
    inspirationImageURL,
    trendingRecipeTastewiseOthersSelectedVal,
    trendingRecipeOthersSelectedVal,
  } = useSelector((state) => state.createBrief);

  const generatePPT = () => {
    const getSelectedValue = (
      tastewiseSelectionValue,
      trendingRecipeTastewiseSelectedVal,
      trendingRecipeTastewiseOthersSelectedVal,
      trendingRecipeSelectedVal,
      trendingRecipeOthersSelectedVal
    ) => {
      if (tastewiseSelectionValue === "Top Dish Trend Index") {
        if (trendingRecipeTastewiseSelectedVal === "Others") {
          return trendingRecipeTastewiseOthersSelectedVal;
        } else {
          return removeIndexScore(trendingRecipeTastewiseSelectedVal);
        }
      } else {
        if (trendingRecipeSelectedVal === "Others") {
          return trendingRecipeOthersSelectedVal;
        } else {
          return trendingRecipeSelectedVal;
        }
      }
    };

    const ppt = new pptxgen();
    const slide1 = ppt.addSlide();

    const filteredBrandInfo = brandOptions.filter(
      (brand) => brand.brand_name === brandSelectedVal
    );

    // this object contains brand related properties like font, color, brackground etc
    const selectedBrandInfo = filteredBrandInfo[0];

    // Dynamically import logo based on brandSelectedVal
    const logoPath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.logo}`
    );

    // Dynamically import image based on brandSelectedVal
    const slide1BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide1.backgroundImage}`
    );

    slide1.addImage({
      path: slide1BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      sizing: { type: "cover" },
    });

    {
      brandSelectedVal !== "Hellmann's" &&
        slide1.addImage({
          path: logoPath,
          x: "36%",
          y: "8%",
          w: "30%",
          h: "40%",
          sizing: { type: "cover" },
          align: "center",
        });
    }

    {
      brandSelectedVal !== "Hellmann's" &&
        slide1.addText(`${brandSelectedVal} Creator Briefing`, {
          x: 1,
          y: "55%",
          fontSize: selectedBrandInfo.slide1.headingFontSize,
          fontFace: selectedBrandInfo.fontFamily,
          bold: true,
          color: selectedBrandInfo.slide1.headingFontColor,
          align: "center",
        });
    }

    {
      brandSelectedVal !== "Hellmann's" &&
        slide1.addText(
          getSelectedValue(
            tastewiseSelectionValue,
            trendingRecipeTastewiseSelectedVal,
            trendingRecipeTastewiseOthersSelectedVal,
            trendingRecipeSelectedVal,
            trendingRecipeOthersSelectedVal
          ),
          {
            x: 1,
            y: "65%",
            fontSize: selectedBrandInfo.slide1.headingFontSize,
            fontFace: selectedBrandInfo.fontFamily,
            bold: true,
            color: selectedBrandInfo.slide1.headingFontColor,
            align: "center",
          }
        );
    }

    const slide2 = ppt.addSlide();
    // Dynamically import image based on brandSelectedVal
    const slide2BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide2.backgroundImage}`
    );
    slide2.addImage({
      path: slide2BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      sizing: { type: "cover" },
    });

    // Define the width for the image and text area
    const imageWidth = "41.7%";
    const imageHeight = "74.1%";
    const textWidth = "50%";

    // Add the image to the left side
    slide2.addImage({
      path: logoPath,
      x: "3%",
      y: "12%",

      sizing: { type: "contain", w: imageWidth, h: imageHeight },
      // sizing: { type: "contain" },
    });

    slide2.addText(`About ${brandSelectedVal}`, {
      x: "50%",
      y: "5%",
      fontSize: selectedBrandInfo.slide2.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide2.headingFontColor,
      align: "left",
      valign: "top",
      w: textWidth,
    });

    slide2.addText(aboutBrand, {
      x: "50%",
      y: "35%",
      w: textWidth,
      fontFace: selectedBrandInfo.fontFamily,
      fontSize: selectedBrandInfo.slide2.subHeadingFontSize,
      color: selectedBrandInfo.slide2.subHeadingFontColor,
      align: "left",
      valign: "middle",
    });

    const slide3 = ppt.addSlide();
    // Dynamically import image based on brandSelectedVal
    const slide3BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide3.backgroundImage}`
    );
    slide3.addImage({
      path: slide3BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      sizing: { type: "cover" },
    });
    slide3.addText(
      `About ${
        productSelectedVal === "Others"
          ? productOthersSelectedVal
          : productSelectedVal
      }`,
      {
        x: "2%",
        y: "15%",
        w: "50%",
        fontSize: selectedBrandInfo.slide3.headingFontSize,
        bold: true,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide3.headingFontColor,
      }
    );
    slide3.addText(aboutProduct, {
      x: "2%",
      y: "38%",
      w: "40%",
      align: "left",
      fontFace: selectedBrandInfo.fontFamily,
      fontSize: selectedBrandInfo.slide3.subHeadingFontSize,
      color: selectedBrandInfo.slide3.subHeadingFontColor,
    });

    slide3.addImage({
      path:
        productImageURL ||
        getProductImageKey(productSelectedVal, marketSelectedVal),
      x: "50%",
      y: "2%",
      w: "50%",
      h: "90%",
    });

    console.log("Slide dimensions: ", slide3);

    const slide4 = ppt.addSlide();
    // Dynamically import image based on brandSelectedVal
    const slide4BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide4.backgroundImage}`
    );

    slide4.addImage({
      path: slide4BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      sizing: { type: "cover" },
    });

    slide4.addText("Campaign Background", {
      x: "2%",
      y: "3%",
      w: "48%",
      fontSize: selectedBrandInfo.slide4.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide4.headingFontColor,
    });

    slide4.addText(campaignOverview, {
      x: "2%",
      y: "23%",
      w: "48%",
      fontSize: selectedBrandInfo.slide4.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      align: "left",
      color: selectedBrandInfo.slide4.subHeadingFontColor,
    });

    slide4.addText("The Ask", {
      x: "2%",
      y: "43%",
      w: "48%",
      fontSize: selectedBrandInfo.slide4.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide4.headingFontColor,
    });

    slide4.addText(campaignGoal, {
      x: "2%",
      y: "67%",
      w: "48%",
      fontSize: selectedBrandInfo.slide4.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      align: "left",
      color: selectedBrandInfo.slide4.subHeadingFontColor,
    });

    slide4.addImage({
      path: campaignImageURL,
      x: "55%",
      y: "20%",
      w: "40%",
      h: "50%",
      sizing: { type: "contain" },
    });

    const slide5 = ppt.addSlide();
    // Dynamically import image based on brandSelectedVal
    const slide5BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide5.backgroundImage}`
    );

    slide5.addImage({
      path: slide5BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      sizing: { type: "cover" },
    });
    slide5.addText(`Thought Starters`, {
      x: "2%",
      y: "3%",
      fontSize: selectedBrandInfo.slide5.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide5.headingFontColor,
    });
    slide5.addText(`Campaign Key Messages`, {
      x: "2%",
      y: "10%",
      fontSize: selectedBrandInfo.slide5.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide5.subHeadingFontColor,
    });
    let initialYCaptions = 16;
    let spacingCaptions = 8;

    captionIdeas.forEach((idea, index) => {
      slide5.addText(idea, {
        x: "1%",
        y: `${initialYCaptions + index * spacingCaptions}%`, // Adjust Y position dynamically
        fontSize: selectedBrandInfo.slide5.mainContentFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        bullet: true,
        w: "98%",
        bulletType: "dash",
        indentLevel: 1,
        color: selectedBrandInfo.slide5.mainContentFontColor,
      });
    });

    slide5.addText(`Suggested Captions`, {
      x: "2%",
      y: "50%",
      fontSize: selectedBrandInfo.slide5.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide5.subHeadingFontColor,
    });
    slide5.addText(
      `These caption ideas are here to inspire you. We encourage you to reword in your tone of voice and align to your idea`,
      {
        x: "3%",
        y: "55%",
        h: "5.5%",
        w: "96%",
        fontSize: selectedBrandInfo.slide5.secondSubHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide5.secondSubHeadingFontColor,
        fill: selectedBrandInfo.slide5.secondSubHeadingFillColor,
      }
    );
    let initialY = 65;
    let spacing = 8;

    suggestedHeadlines.forEach((headline, index) => {
      const numLines = Math.ceil((headline.length * 13) / 98);

      slide5.addText(headline, {
        x: "1%",
        w: "98%",
        h: "5%",
        y: `${initialY + index * spacing}%`,
        fontSize: selectedBrandInfo.slide5.mainContentFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide5.mainContentFontColor,
        bullet: true,
        bulletType: "dash",
        indentLevel: 1,
        lineSpacing: numLines > 1 ? 12 : 7,
        wrap: true,
      });
    });

    const slide6 = ppt.addSlide();
    // Dynamically import image based on brandSelectedVal
    const slide6BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide6.backgroundImage}`
    );

    slide6.addImage({
      path: slide6BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      color: "ffffff",
      sizing: { type: "cover" },
    });

    slide6.addText(`Inspiration:`, {
      x: "2%",
      y: "3%",
      fontSize: selectedBrandInfo.slide6.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide6.headingFontColor,
    });
    // Calculate the number of images
    const numImages = inspirationImageURL.length;

    // Define the positioning and dimensions based on the number of images
    let xPos, yPos, width, height;

    if (numImages === 1) {
      // For 1 image
      xPos = "35%";
      yPos = "28%";
      width = "31%";
      height = "38%";
    } else if (numImages === 2) {
      // For 2 images
      xPos = ["17%", "53%"];
      yPos = ["28%", "28%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 3) {
      // For 3 images
      xPos = ["3%", "35%", "68%"];
      yPos = ["28%", "28%", "28%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 4) {
      // For 4 images
      xPos = ["10%", "57%", "10%", "57%"];
      yPos = ["7%", "7%", "50%", "50%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 5) {
      // For 5 images
      xPos = ["3%", "35%", "67.5%", "17%", "49%"];
      yPos = ["7%", "7%", "7%", "50%", "50%"];
      width = "31%";
      height = "38%";
    } else if (numImages === 6) {
      // For 6 images
      xPos = ["3%", "35%", "67.5%", "3%", "35%", "67.5%"];
      yPos = ["7%", "7%", "7%", "50%", "50%", "50%"];
      width = "31%";
      height = "38%";
    }

    // Add the images to slide8
    inspirationImageURL.forEach((imageUrl, index) => {
      // Calculate the position based on the index
      let calculatedXPos, calculatedYPos;

      calculatedXPos = Array.isArray(xPos) ? xPos[index] : xPos;
      calculatedYPos = Array.isArray(yPos) ? yPos[index] : yPos;

      // Add the image to slide8
      slide6.addImage({
        path: imageUrl,
        x: calculatedXPos,
        y: calculatedYPos,
        w: width,
        h: height,
        sizing: { type: "contain" },
      });
    });

    inspirationVideoLink &&
      slide6.addText(`Video Link:  ${inspirationVideoLink}`, {
        x: "2%",
        y: "90%",
        h: "5.5%",
        w: "96%",
        fontSize: selectedBrandInfo.slide6.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide6.subHeadingFontColor,
        fill: selectedBrandInfo.slide6.subHeadingFillColor,
      });

    const slide7 = ppt.addSlide();

    // Dynamically import image based on brandSelectedVal
    const slide7BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide7.backgroundImage}`
    );

    slide7.addImage({
      path: slide7BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      color: "ffffff",
      sizing: { type: "cover" },
    });

    slide7.addText(`Content Mandatories`, {
      x: "2%",
      y: "3%",
      fontSize: selectedBrandInfo.slide7.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide7.headingFontColor,
    });

    slide7.addText(`Do's:`, {
      x: "2%",
      w: "98%",
      y: "8%", // Adjusted Y position for 'Do's'
      fontSize: selectedBrandInfo.slide7.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide7.subHeadingFontColor,
    });

    // Separate 'Do's' and 'Don'ts' into different arrays
    const doItems = [];
    const dontItems = [];

    contentMandatories.forEach((mandatories) => {
      doItems.push(...mandatories.do);
      dontItems.push(...mandatories.donts);
    });

    // Initialize y position
    let yDo = 10;
    let yPreviousDo = 7;
    // Container width (75% of the available width)
    const containerWidth = 0.54 * window.innerWidth;

    // Function to calculate text width
    function getTextWidth(text, font) {
      const canvas = document.createElement("canvas");
      const context = canvas.getContext("2d");
      context.font = font;
      const metrics = context.measureText(text);
      return metrics.width;
    }

    let numOfLinesDoPrevious = 1;
    let numOfLinesDoCurrent = 1;

    // Check if each bullet point will wrap
    doItems.forEach((bullet, index) => {
      // bulletPoints.forEach((bullet, index) => {
      const textWidth = getTextWidth(
        bullet,
        `${selectedBrandInfo.slide7.subHeadingFontSize} ${selectedBrandInfo.fontFamily}`
      ); // Assuming font size is 16px Arial

      numOfLinesDoCurrent = Math.ceil(textWidth / containerWidth);

      if (numOfLinesDoCurrent === 1 && numOfLinesDoPrevious === 1) {
        yDo = yPreviousDo + 3;
      } else if (numOfLinesDoCurrent === 2 && numOfLinesDoPrevious === 1) {
        yDo = yPreviousDo + 4;
      } else if (numOfLinesDoCurrent === 2 && numOfLinesDoPrevious === 2) {
        yDo = yPreviousDo + 5;
      } else if (numOfLinesDoCurrent === 3 && numOfLinesDoPrevious === 1) {
        yDo = yPreviousDo + 5;
      } else if (numOfLinesDoCurrent === 1 && numOfLinesDoPrevious === 2) {
        yDo = yPreviousDo + 4;
      } else if (numOfLinesDoCurrent === 1 && numOfLinesDoPrevious === 3) {
        yDo = yPreviousDo + 5;
      }

      slide7.addText(bullet, {
        x: "2%",
        y: yDo + "%",
        w: "96%",
        h: 0.25,
        fontSize: selectedBrandInfo.slide7.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide7.subHeadingFontColor,
        bullet: true,
        lineSpacing: 10,
      });
      // updating previous y position and number of lines
      yPreviousDo = yDo;
      numOfLinesDoPrevious = Math.ceil(textWidth / containerWidth);
    });

    // Add 'Don'ts'
    slide7.addText(`Don'ts:`, {
      x: "2%",
      w: "98%",
      y: yDo + 7 + "%",
      fontSize: selectedBrandInfo.slide7.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide7.subHeadingFontColor,
    });

    // Initialize y position
    let yDont = yDo + 9;
    let yPreviousDont = yDo + 6;

    let numOfLinesDontPrevious = 1;
    let numOfLinesDontCurrent = 1;

    // Check if each bullet point will wrap
    dontItems.forEach((bullet, index) => {
      const textWidth = getTextWidth(
        bullet,
        `${selectedBrandInfo.slide7.subHeadingFontSize} ${selectedBrandInfo.fontFamily}`
      );

      numOfLinesDontCurrent = Math.ceil(textWidth / containerWidth);

      if (numOfLinesDontCurrent === 1 && numOfLinesDontPrevious === 1) {
        yDont = yPreviousDont + 3;
      } else if (numOfLinesDontCurrent === 2 && numOfLinesDontPrevious === 1) {
        yDont = yPreviousDont + 4;
      } else if (numOfLinesDontCurrent === 2 && numOfLinesDontPrevious === 2) {
        yDont = yPreviousDont + 5;
      } else if (numOfLinesDontCurrent === 1 && numOfLinesDontPrevious === 2) {
        yDont = yPreviousDont + 4;
      }

      slide7.addText(bullet, {
        x: "2%",
        y: yDont + "%",
        w: "96%",
        h: 0.25,
        fontSize: selectedBrandInfo.slide7.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        color: selectedBrandInfo.slide7.subHeadingFontColor,
        bullet: true,
        lineSpacing: 10,
      });
      // updating previous y position and number of lines
      yPreviousDont = yDont;
      numOfLinesDontPrevious = Math.ceil(textWidth / containerWidth);
    });

    // Dynamically import image based on brandSelectedVal
    const slide8BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide8.backgroundImage}`
    );

    const slide8 = ppt.addSlide();
    slide8.addImage({
      path: slide8BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      color: "ffffff",
      sizing: { type: "cover" },
    });

    slide8.addShape(ppt.shapes.RECTANGLE, {
      x: "2%",
      y: "3%",
      w: "96%",
      h: "22%",
      fill: {
        color: selectedBrandInfo.slide8.deliverablesFillFontColor,
      },
      line: { type: "none" },
    });

    slide8.addText(`Deliverables`, {
      x: "4%",
      y: "9%",
      fontSize: selectedBrandInfo.slide8.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide8.deliverablesHeadingFontColor,
    });
    slide8.addText(deliverables, {
      x: "4%",
      y: "16%",
      fontSize: selectedBrandInfo.slide8.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide8.deliverablesSubHeadingFontColor,
    });

    slide8.addShape(ppt.shapes.RECTANGLE, {
      x: "2%",
      y: "28%",
      w: "42%",
      h: "65%",
      fill: { color: selectedBrandInfo.slide8.socialMediaFillColor },
      line: { type: "none" },
    });

    slide8.addText(`Social Media Handles and Hashtags`, {
      x: "4%",
      y: "34%",
      fontSize: selectedBrandInfo.slide8.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide8.socialMediaHeadingFontColor,
    });

    socialMediaHashtag.forEach((handle, index) => {
      Object.entries(handle).forEach(([platform, username]) => {
        slide8.addText(`${platform}: ${username}`, {
          x: "4%",
          y: "41%",
          w: "38%",
          fontSize: selectedBrandInfo.slide8.subHeadingFontSize,
          fontFace: selectedBrandInfo.fontFamily,
          bold: true,
          color: selectedBrandInfo.slide8.socialMediaSubHeadingFontColor,
        });
      });
    });

    slide8.addText(hashtags, {
      x: "4%",
      y: "50%",
      w: "38%",
      fontSize: selectedBrandInfo.slide8.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide8.socialMediaSubHeadingFontColor,
    });

    slide8.addText(`Links To Use Within Captions`, {
      x: "4%",
      y: "70%",
      fontSize: selectedBrandInfo.slide8.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide8.linksToUseHeadingFontColor,
    });
    slide8.addText(linkToUse, {
      x: "4%",
      y: "78%",
      w: "38%",
      fontSize: selectedBrandInfo.slide8.subHeadingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide8.linksToUseSubHeadingFontColor,
    });

    slide8.addShape(ppt.shapes.RECTANGLE, {
      x: "46%",
      y: "28%",
      w: "52%",
      h: "65%",
      fill: {
        color: selectedBrandInfo.slide8.postingTimelineFillColor,
      },
      line: { type: "none" },
    });

    slide8.addText(`Posting Timelines`, {
      x: "48%",
      y: "34%",
      fontSize: selectedBrandInfo.slide8.headingFontSize,
      fontFace: selectedBrandInfo.fontFamily,
      bold: true,
      color: selectedBrandInfo.slide8.postingTimelineHeadingFontColor,
    });

    Object.entries(postingTimeline).forEach(([key, value], index) => {
      slide8.addText(key, {
        x: "48%",
        y: `${44 + index * 5}%`,
        fontSize: selectedBrandInfo.slide8.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide8.postingTimelineSubHeadingFontColor,
      });

      slide8.addText(value, {
        x: "81%",
        y: `${42 + index * 5}%`,
        w: "33%",
        fontSize: selectedBrandInfo.slide8.subHeadingFontSize,
        fontFace: selectedBrandInfo.fontFamily,
        bold: true,
        color: selectedBrandInfo.slide8.postingTimelineSubHeadingFontColor,
      });
    });

    // Dynamically import image based on brandSelectedVal
    const slide9BackgroundImagePath = imageContext(
      `./${brandSelectedVal}/${selectedBrandInfo.slide9.backgroundImage}`
    );

    const slide9 = ppt.addSlide();
    slide9.addImage({
      path: slide9BackgroundImagePath,
      x: 0,
      y: 0,
      w: "100%",
      h: "100%",
      color: "ffffff",
      sizing: { type: "cover" },
    });

    ppt.writeFile(`${pptDownloadName}.pptx`);
  };

  return (
    <div>
      <Box className="downloadPPTBtn" onClick={generatePPT}>
        Download
      </Box>
    </div>
  );
}

export default PPTGenerator;
