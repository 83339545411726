const removeIndexScore = (trendingRecipe) => {
    // Check if the input string contains the pattern "- <number> Index Score"
    const regex = / - \d+ Index Score$/;
    const match = trendingRecipe.match(regex);
    
    if (match) {
        // If the pattern is found, remove it from the string
        return trendingRecipe.replace(match[0], '');
    } else {
        // If the pattern is not found, return the original string
        return trendingRecipe;
    }
}

//  extract the initials from the user name
const getInitials = (inputString) => {
    let result = "";
    let parts = inputString.split(", ");

    for (let part of parts) {
        result += part.charAt(0).toUpperCase();
    }

    return result;
}

// Export the functions
export { removeIndexScore, getInitials };