export const topDishTrendIndexTooltipData = "For access to the latest Trending Top Dish data, visit the Top Dish Trend Index at https://app.tastewise.io/pro/IN/insights/dashboard/custom-trends. You can download the PDF report and conveniently use the 'upload' button below to shape your influencer brief."
export const typeItInTooltipData = "If you do not have Top Dish Trend Index data available, pick a Top Dish and trending recipe for your brief"
export const topDishTooltipData = "Pick a 'Top Dish' from the list of focused dishes defined by your brand (e.g.  Pasta, Burgers, Soups)"
export const trendingRecipeTooltipData = "Pick a trending Top Dish recipe within the category of one of your Top Dishes (e.g. Tomato and Feta Pasta or Mushroom Burger)"
export const productTooltipData = "Choose the product that your influencer should feature in their recipe and content."
export const productDescriptionTooltipData = "Select a product or SKU for your influencer brief"
export const uploadImagesTooltipData = "Upload images for your brief here: one for product, and campaign, plus three to six inspiration images. If none are selected for brand and product, default images will be added."
export const audienceTypeTooltipData = "Describe your target group or audience. You can choose to include an age group or gender or any identifier best suited for your requirement. For more than one entries, separate them using “;”"
export const platformTooltipData = "Name the platform(s) that you want the influencer to create content for"
export const campaignObjectiveTooltipData = "What is the campaign attempting to achieve - Drive Awareness / Consideration/ Conversion/ Advocacy"
export const mediaTypeTooltipData = "What kind of media do you want the influencer to create - videos, static post/ images, story"
export const shapeBriefTooltipData = "Shape your brief"
export const topDishDisabled="This feature is not available"
export const outputLanguageTooltipData="Selected output language will appear in the brief"