import React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import Typography from "@mui/material/Typography";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";
import InfoIcon from "@mui/icons-material/Info";
import Checkbox from "@mui/material/Checkbox";
import { useDispatch, useSelector } from "react-redux";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import {
  setAudienceTypeSelectedVal,
  setPlatformSelectedVal,
  setCampaignObjectiveSelectedVal,
  setMediaTypeSelectedVal,
  setSelectedOutputLanguage,
  setSelectedOutputLanguageId,
} from "../../slicers/addMoreFlavourSlicer";
import {
  setTopDishSelectedVal,
  setTrendingRecipeOthersSelectedVal,
  setTrendingRecipeSelectedVal,
  setProductOthersSelectedStatus,
  setProductOthersSelectedVal,
  setProductSelectedVal,
  setProductDescriptionOthersSelectedVal,
  setProductDescriptionSelectedVal,
  setMarketSelectedVal,
  setBrandSelectedVal,
  setTrendingRecipeTastewiseOthersSelectedVal,
  setTrendingRecipeTastewiseSelectedVal,
  setTrendingRecipeTastewiseIndexScoreSelectedVal,
} from "../../slicers/createBriefSlicer";
import StandardButton from "../StandardButton/StandardButton";
import {
  apiCallFetchBrand,
  apiCallFetchTopDishAndProduct,
  apiCallFetchTrendingRecipe,
} from "../../utilities/apiUtils/apiUtils";
import "./styles.css";

function UserInputs({
  label,
  dropdownOptions,
  fieldType,
  fileUpload,
  className,
  layoutType,
  labelStyle,
  showAsterisk,
  buttonLabel,
  showInfoIcon,
  open,
  onClick,
  dropdownWidth,
  dropdownOthersWidth,
  dropdownSelectedValue,
  disableDropdown,
  textFieldValue,
  disabledState,
  tooltipData,
  tooltipPlacement,
  tooltipWidth,
  multiSelect,
  textBoxHeight,
  textBoxWidth,
  buttonFormControlWidth,
  ...props
}) {
  const dispatch = useDispatch();
  const { platformSelectedVal, outputLanguageList } = useSelector((state) => state.addMoreFlavour);
  const {
    marketOptions,
    marketSelectedVal,
    brandOptions,
    brandSelectedVal,
    tastewiseSelectionValue,
    productOptions,
    trendingRecipeTastewiseOptions,
    topDishOptions,
    productOthersSelectedStatus,
  } = useSelector((state) => state.createBrief);

  const handleChange = (event, label) => {

     // Retrieve the current selected values
  const currentSelectedValues = Array.isArray(event.target.value)
  ? event.target.value
  : [event.target.value];

    // Retrieve the previous selected values from the state or props
  const previousSelectedValues = [];

  // Check if the user is trying to deselect all options
  const isDeselectingAll = currentSelectedValues.length === 0;

  // Check if multiSelect is true
  const isMultiSelect = multiSelect === true;

  if (isMultiSelect && isDeselectingAll) {
    // If multiSelect is true and all options are being deselected,
    // prevent it by keeping at least one option selected
    const firstOption = dropdownOptions[0]; // Get the first option
    event.target.value = [firstOption]; // Keep the first option selected
    // You can also show a message or notification to inform the user
    console.log('At least one value must be selected.');
  }


    switch (label) {
      case "Market":
        dispatch(setMarketSelectedVal(event.target.value));
        apiCallFetchBrand(event.target.value, dispatch, marketOptions);
        break;
      case "Brand":
        dispatch(setBrandSelectedVal(event.target.value));
        apiCallFetchTopDishAndProduct(
          event.target.value,
          dispatch,
          marketOptions,
          marketSelectedVal,
          brandOptions
        );
        break;
      case "Top Dish":
        if (tastewiseSelectionValue === "Type It In") {
          dispatch(setTopDishSelectedVal(event.target.value));
          // apiCallFetchTrendingRecipe(event.target.value, dispatch, marketOptions, marketSelectedVal, brandOptions, brandSelectedVal, topDishOptions);
        }
        break;
      case "Trending Twist":
        // the below if is used when user is uploading tastewise pdf i.e. TOP DISH TREND INDEX Radio button is selected
        if (tastewiseSelectionValue === "Top Dish Trend Index") {
          dispatch(setTrendingRecipeTastewiseSelectedVal(event.target.value)); //save user selected value for trending recipe
          dispatch(setTrendingRecipeTastewiseOthersSelectedVal("")); // empty the redux state which stores value when Others option is selected
          // when user is not selecting others from the dropdown
          if (event.target.value !== "Others") {
            const selectedRecipe = trendingRecipeTastewiseOptions.find(
              (recipe_name) =>
                recipe_name.trending_recipe === event.target.value
            );
            dispatch(
              setTrendingRecipeTastewiseIndexScoreSelectedVal(
                Number(selectedRecipe.index_score)
              )
            ); // save the index score in redux
          } else {
            // when user is selecting others from the dropdown
            dispatch(setTrendingRecipeTastewiseIndexScoreSelectedVal(0)); // set 0 for the redux state which stores index score
          }
        } else {
          // this else block is used when - if radio button - TYPE IT IN is selected
          dispatch(setTrendingRecipeSelectedVal(event.target.value)); //save user selected value for trending recipe
          dispatch(setTrendingRecipeOthersSelectedVal("")); // empty the redux state which stores value when Others option is selected
        }
        break;
      case "Trending Twist Others":
        if (tastewiseSelectionValue === "Top Dish Trend Index") {
          dispatch(
            setTrendingRecipeTastewiseOthersSelectedVal(event.target.value)
          );
          dispatch(setTrendingRecipeTastewiseIndexScoreSelectedVal(0));
        } else {
          dispatch(setTrendingRecipeOthersSelectedVal(event.target.value));
          dispatch(setTrendingRecipeTastewiseIndexScoreSelectedVal(0));
        }
        break;
      case "Product":
        if (event.target.value !== "Others") {
          dispatch(setProductOthersSelectedStatus(false));
          dispatch(setProductSelectedVal(event.target.value));
          dispatch(setProductOthersSelectedVal(""));
          const selectedProduct = productOptions.find(
            (product) => product.product_name === event.target.value
          );
          dispatch(
            setProductDescriptionSelectedVal(
              selectedProduct.product_description
            )
          );
          dispatch(setProductDescriptionOthersSelectedVal(""));
        } else {
          dispatch(setProductOthersSelectedStatus(true));
          dispatch(setProductSelectedVal(event.target.value));
          dispatch(setProductDescriptionSelectedVal(""));
        }
        break;
      case "Product Others":
        dispatch(setProductOthersSelectedVal(event.target.value));
        break;
      case "Product Description":
        dispatch(setProductDescriptionOthersSelectedVal(event.target.value));
        break;
      case "Audience Type":
        dispatch(setAudienceTypeSelectedVal(event.target.value));
        break;
      case "Platform":
        dispatch(setPlatformSelectedVal(event.target.value));
        break;
      case "Campaign Objective":
        dispatch(setCampaignObjectiveSelectedVal(event.target.value));
        break;
      case "Media Type":
        dispatch(setMediaTypeSelectedVal(event.target.value));
        break;
        case "Output Language":
          const outputLanguage = outputLanguageList.find(
            (language) => language.language_name === event.target.value
          );
          dispatch(setSelectedOutputLanguageId(outputLanguage.language_id))
          dispatch(setSelectedOutputLanguage(event.target.value))
          break;
      default:
        break;
    }
  };

  const handleButtonClick = () => {
    if (onClick) {
      onClick(); // Call the onClick event handler
    }
  };

  const gridXs = layoutType === "vertical" ? 12 : 6;

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Grid container spacing={0} justifyContent="center" alignItems="center" sx={{ marginLeft:label==="Output Language" && "50px",marginRight: (label==="Brand" || label ==="Market") && "40px"}}>
        <Grid item xs={gridXs} 
        sx={{ textAlign:
          (label === "Market" || label === "Brand" || label === "Output Language") ? 'right' : 'left' ,}}
         >
          <Typography >
            {showAsterisk && (
              <span
                style={{
                  color: "#EC3225",
                  fontFamily: "Unilever Shilling",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 600,
                  lineHeight: "20px",
                }}
              >
                *
              </span>
            )}
            <span style={labelStyle}>{label}</span>
            <span>&nbsp;</span>
            {showInfoIcon && (
              <CustomTooltip
                title={tooltipData}
                tooltipPlacement={tooltipPlacement}
                width={tooltipWidth}
              >
                <InfoIcon style={{ fontSize: "14px" }} />
              </CustomTooltip>
            )}
          </Typography>
        </Grid>
        <Grid item xs={layoutType === "vertical" ? 12 : 12 - gridXs} sx={{ display:  (label === "Market" || label === "Brand" || label === "Output Language") ? 'flex' : 'block', textAlign: layoutType === "vertical" ? 'left' : 'right' }}>
          {fieldType === "dropdown" && dropdownOptions ? (
            <>
              <FormControl
                sx={{
                  m: 0,
                  width:
                    dropdownSelectedValue === "Others"
                      ? dropdownOthersWidth
                      : dropdownWidth,
                  height: 30,
                  textAlign: "left"
                }}
                variant="standard"
                disabled={disableDropdown}
              >
                <Select
                  labelId="simple-select-label"
                  id="simple-select"
                  onChange={(e) => handleChange(e, label)}
                  value={dropdownSelectedValue}
                  multiple={multiSelect}
                  className={
                    dropdownSelectedValue === "Others"
                      ? "select-others"
                      : className
                  }
                  renderValue={(selected) => {
                    if (Array.isArray(selected)) {
                      return selected.join(", ");
                    }
                    return selected;
                  }}
                  sx={{
                    width: dropdownSelectedValue === "Others"
                    ? `${dropdownOthersWidth}px`
                    : `${dropdownWidth}px`,
                    "&:before": {
                      borderBottom: "none !important",
                    },
                    "&:after": {
                      borderBottom: "none !important",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: 200, // Adjust the max height of the dropdown menu as needed
                        fontFamily: "Unilever Shilling",
                      },
                    },
                  }}
                >
                  {dropdownOptions.map((item, index) => (
                    <MenuItem key={index} value={item} className="menuItem">
                      {multiSelect && (
                        <Checkbox
                          checked={dropdownSelectedValue.indexOf(item) > -1}
                        />
                      )}
                      {item}
                    </MenuItem>
                  ))}
                  {/* Add "Others" option manually and conditionally render it */}
                  {(label === "Trending Twist" || label === "Product") && (
                    <MenuItem value="Others" className="menuItem">
                      Others
                    </MenuItem>
                  )}
                </Select>
              </FormControl>
              {/* Conditionally render TextField */}
              {dropdownSelectedValue === "Others" && (
                <>
                  <span>&nbsp; &nbsp;</span>
                  <FormControl
                    sx={{
                      m: 0,
                      textAlign: "left",
                      justifyContent: "center"
                    }}
                    variant="standard"
                  >
                    <TextField
                      id="outlined-basic"
                      variant="outlined"
                      style={{
                        width: "160px",
                        borderRadius: "4px",
                        background: "#EDF4FF",
                        top: "16%",
                        fontFamily: "Unilever Shilling",
                      }}
                      onChange={(e) => handleChange(e, `${label} Others`)}
                      value={textFieldValue}
                      disabled={disabledState}
                      // InputProps={{ sx: { fontSize: "1.6vh", height: "3.3vh" } }}
                      InputProps={{
                        sx: {
                          fontSize: "14px",
                          height: "32px",
                          fontFamily: "Unilever Shilling",
                          background: "#cadaf3",
                          color: "#005EEF !important",
                        },
                      }}
                      // InputLabelProps={{ sx: { fontSize: "1.6vh", top: "-1.6vh", "&.MuiInputLabel-shrink": { top: 0 } } }}
                      InputLabelProps={{
                        sx: {
                          fontSize: "14px",
                          top: "5px",
                          "&.MuiInputLabel-shrink": {
                            top: 0,
                            fontFamily: "Unilever Shilling",
                          },
                          fontFamily: "Unilever Shilling",
                          background: "#cadaf3",
                          color: "#005EEF !important",
                        },
                      }}
                    />
                  </FormControl>
                </>
              )}
            </>
          ) : fieldType === "button" ? (
            <FormControl
              sx={{
                m: 0,
                width: buttonFormControlWidth,
                textAlign: "left",
                justifyContent: "center"
              }}
              variant="standard"
            >
              <StandardButton
                label={buttonLabel}
                fileUpload={fileUpload}
                onClick={handleButtonClick}
                {...props}
              />
            </FormControl>
          ) : (
            <TextField
              id="outlined-basic"
              variant="outlined"
              style={{
                width: textBoxWidth || "279px",
                borderRadius: "4px",
                background: "#cadaf3",
                fontFamily: "Unilever Shilling",
                color: "#005EEF !important",
              }}
              onChange={(e) => handleChange(e, label)}
              value={textFieldValue}
              disabled={disabledState}
              InputProps={{
                sx: {
                  fontSize: "14px",
                  height: textBoxHeight || "65px",
                  fontFamily: "Unilever Shilling",
                  background: "#cadaf3",
                  color: "#005EEF !important",
                },
              }}
              InputLabelProps={{
                sx: {
                  fontSize: "14px",
                  top: "2px",
                  "&.MuiInputLabel-shrink": {
                    top: 0,
                    fontFamily: "Unilever Shilling",
                    background: "#cadaf3",
                    color: "#005EEF !important",
                  },
                  fontFamily: "Unilever Shilling",
                  background: "#cadaf3",
                  color: "#005EEF !important",
                },
              }}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default UserInputs;
