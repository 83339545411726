import { Box } from "@mui/material";
import React, { useState } from "react";
import CampaignOverview from "../CampaignOverview/CampaignOverview";
import CampaignGoal from "../CampaignGoals/CampaignGoals";
import ContentIdea from "../ContentIdeas/ContentIdea";
import Inspiration from "../Inspiration/Inspiration";
import TabComponent from "../Tabs/CustomTabs";

function YourCreativeChallenges({ setSelectedTab,selectedTab, handleNext }) {
  // const [selectedTab, setSelectedTab] = useState(1);

  const handleTabClick = (buttonId) => {
    setSelectedTab(buttonId);
  };

  const handleNextTab = () => {
    const maxTabs = 4;
    const nextTab = selectedTab + 1;
    if (nextTab <= maxTabs) {
      setSelectedTab(selectedTab + 1);
    } else {
      handleNext();
    }
  };

  const CreativeChallageRendering = ({ step }) => {
    switch (step) {
      case 1:
        return <CampaignOverview handleNext={handleNextTab} />;
        break;
      case 2:
        return <CampaignGoal handleNext={handleNextTab} />;
        break;
      case 3:
        return <ContentIdea handleNext={handleNextTab} />;
        break;
      case 4:
        return <Inspiration handleNext={handleNext} />;
        break;
      default:
        return null;
    }
  };

  return (
    <Box >
      <TabComponent
        selectedButtonId={selectedTab}
        onSelectedTabClick={handleTabClick}
      />
      <CreativeChallageRendering step={selectedTab} />
    </Box>
  );
}

export default YourCreativeChallenges;
