import React, { useState } from "react";
import { Box } from "@mui/material";
import CommonLayout from "../../pages/CommonLayout/CommonLayout";
import CustomInputTextarea from "../CustomInputTextarea/CustomInputTextarea";
import { GetToKnowUsTooltipData, contentIdeasTooltip } from "../../Constant/EditBreifTooltip";
import { useDispatch, useSelector } from "react-redux";
import apiService from "../../utilities/service/apiService";
import {
  setCaptionIdeas,
  setInputEditPrompt,
  setPromptTonality,
  setSuggestedHeadlines,
} from "../../slicers/editYourBriefSlicer";
import { updateBriefData } from "../../utilities/apiUtils/apiUtils";
import {
  campaignKeyMessageText,
  suggestedCaptionsText,
} from "../../Constant/EditBrief";
import { useTextLabels } from "../../Constant/useTextLabels";

function ContentIdea({ handleNext }) {
  const dispatch = useDispatch();
  const { captionIdeas, suggestedHeadlines, promptTonality, inputEditPrompt } =
    useSelector((state) => state.editYourBrief);
  const { briefId, briefData } = useSelector((state) => state.createBrief);
  const labels = useTextLabels();
  const [captionIdeasData, setCaptionIdeasData] = useState({
    isEdit: false,
    data: captionIdeas,
    originalData: captionIdeas,
  });

  const [suggestedCaptionsData, setSuggestedCaptionsData] = useState({
    isEdit: false,
    data: suggestedHeadlines,
    originalData: suggestedHeadlines,
  });

  const handleInputChange = (e, section, index) => {
    if (
      section === "CAMPAIGN_KEY_MESSAGE" ||
      section === "SUGGESTED_CAPTIONS"
    ) {
      const { value } = e.target;
      const newData = [
        ...(section === "CAMPAIGN_KEY_MESSAGE"
          ? captionIdeasData.data
          : suggestedCaptionsData.data),
      ];
      newData[index] = value;
      if (section === "CAMPAIGN_KEY_MESSAGE") {
        setCaptionIdeasData((prev) => ({
          ...prev,
          data: newData,
        }));
      } else {
        setSuggestedCaptionsData((prev) => ({
          ...prev,
          data: newData,
        }));
      }
    }
  };

  const handleEdit = (section) => {
    if (
      section === "CAMPAIGN_KEY_MESSAGE" ||
      section === "SUGGESTED_CAPTIONS"
    ) {
      if (section === "CAMPAIGN_KEY_MESSAGE") {
        setCaptionIdeasData((prev) => ({
          ...prev,
          isEdit: true,
        }));
      } else {
        setSuggestedCaptionsData((prev) => ({
          ...prev,
          isEdit: true,
        }));
      }
    }
  };

  const handleCancelClick = (section) => {
    if (
      section === "CAMPAIGN_KEY_MESSAGE" ||
      section === "SUGGESTED_CAPTIONS"
    ) {
      const originalData =
        section === "CAMPAIGN_KEY_MESSAGE"
          ? captionIdeasData.originalData
          : suggestedCaptionsData.originalData;
      if (section === "CAMPAIGN_KEY_MESSAGE") {
        setCaptionIdeasData((prev) => ({
          ...prev,
          isEdit: false,
          data: originalData,
        }));
      } else {
        setSuggestedCaptionsData((prev) => ({
          ...prev,
          isEdit: false,
          data: originalData,
        }));
      }
    }
  };

  const handleDataSave = async (section) => {
    try {
      let bodyParam;
      let newData;
      if (
        section === "CAMPAIGN_KEY_MESSAGE" ||
        section === "SUGGESTED_CAPTIONS"
      ) {
        const newData =
          section === "CAMPAIGN_KEY_MESSAGE"
            ? captionIdeasData.data.join("\n")
            : suggestedCaptionsData.data.join("\n");
        // newData = section === "CAMPAIGN_KEY_MESSAGE" ? captionIdeasData.data : suggestedCaptionsData.data;
        bodyParam = updateBriefData(section, newData, briefData, dispatch);

        const response = await apiService({
          method: "PUT",
          url: `${window.location.origin}/briefs/${briefId}`,
          body: bodyParam,
          headers: {
            "Content-Type": "application/json",
          },
        });

        if (section === "CAMPAIGN_KEY_MESSAGE") {
          dispatch(setCaptionIdeas(response.CAMPAIGN_KEY_MESSAGE.split("\n")));
          setCaptionIdeasData((prev) => ({
            ...prev,
            data: response.CAMPAIGN_KEY_MESSAGE,
            isEdit: false,
          }));
        } else {
          const res = response.SUGGESTED_CAPTIONS.split("\n");
          dispatch(setSuggestedHeadlines(res));
          setSuggestedCaptionsData((prev) => ({
            ...prev,
            data: res,
            isEdit: false,
          }));
        }
      } else {
        throw new Error("Invalid section provided");
      }
    } catch (error) {
      console.log("Error:", error);
    }
  };

  console.log(Array.isArray(captionIdeasData.data), "88888");
  console.log(Array.isArray(suggestedCaptionsData.data), "88888");

  const contentHeaders = [
    {
      title: labels.CAMPAIGN_KEY_MESSAGES,
      onEdit: () => handleEdit("CAMPAIGN_KEY_MESSAGE"),
      content: captionIdeasData.isEdit ? (
        <div style={{ margin: "10px" }}>
          <ul>
            {captionIdeasData.data.map((message, index) => (
              <li key={index}>
                <input
                  type="text"
                  value={message}
                  onChange={(e) =>
                    handleInputChange(e, "CAMPAIGN_KEY_MESSAGE", index)
                  }
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    padding: "8px 12px",
                    borderRadius: "8px",
                    color: "black",
                    background: "#fff",
                    border: "none",
                    resize: "none",
                  }}
                />
              </li>
            ))}
          </ul>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "20px",
            }}
          >
            <div className="cancelSaveBtn">
              <div
                style={{
                  display: "flex",
                  width: "64px",
                  height: "24px",
                  padding: "16px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  color: "#111",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  cursor: "pointer",
                  lineHeight: "20px",
                }}
                onClick={() => handleCancelClick("CAMPAIGN_KEY_MESSAGE")}
              >
                Cancel
              </div>
              <div
                style={{
                  display: "flex",
                  width: "113px",
                  height: "20px",
                  padding: "4px 4px",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  borderRadius: "4px",
                  background: "#008651",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                  color: "#fff",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  cursor: "pointer",
                  lineHeight: "20px",
                }}
                onClick={() => handleDataSave("CAMPAIGN_KEY_MESSAGE")}
              >
                Save Changes
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Array.isArray(captionIdeasData.data) &&
          captionIdeasData.data.length > 0 ? (
            <div>
              <ul>
                {captionIdeasData.data.map((message, index) => (
                  <li key={index}>{message}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </>
      ),
    },
    {
      title: `${labels.SUGGESTED_CAPTIONS}*`,
      onEdit: () => handleEdit("SUGGESTED_CAPTIONS"),
      content: suggestedCaptionsData.isEdit ? (
        <div style={{ margin: "10px" }}>
          <ul>
            {suggestedCaptionsData.data.map((caption, index) => (
              <li key={index}>
                <input
                  type="text"
                  value={caption}
                  onChange={(e) =>
                    handleInputChange(e, "SUGGESTED_CAPTIONS", index)
                  }
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    fontFamily: '"IBM Plex Sans", sans-serif',
                    fontSize: "16px",
                    fontWeight: 400,
                    lineHeight: 1.5,
                    padding: "8px 12px",
                    borderRadius: "8px",
                    color: "black",
                    background: "#fff",
                    border: "none",
                    resize: "none",
                  }}
                />
              </li>
            ))}
          </ul>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              columnGap: "20px",
            }}
          >
            <div className="cancelSaveBtn">
              <div
                style={{
                  display: "flex",
                  width: "64px",
                  height: "24px",
                  padding: "16px 8px",
                  justifyContent: "center",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  color: "#111",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  cursor: "pointer",
                  lineHeight: "20px",
                }}
                onClick={() => handleCancelClick("SUGGESTED_CAPTIONS")}
              >
                Cancel
              </div>
              <div
                style={{
                  display: "flex",
                  width: "113px",
                  height: "20px",
                  padding: "4px 4px",
                  alignItems: "center",
                  gap: "8px",
                  flexShrink: 0,
                  borderRadius: "4px",
                  background: "#008651",
                  boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.14)",
                  color: "#fff",
                  fontSize: "14px",
                  fontStyle: "normal",
                  fontWeight: 400,
                  cursor: "pointer",
                  lineHeight: "20px",
                }}
                onClick={() => handleDataSave("SUGGESTED_CAPTIONS")}
              >
                Save Changes
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          {Array.isArray(suggestedCaptionsData.data) &&
          suggestedCaptionsData.data.length > 0 ? (
            <div>
              <ul>
                {suggestedCaptionsData.data.map((caption, index) => (
                  <li key={index}>{caption}</li>
                ))}
              </ul>
            </div>
          ) : null}
        </>
      ),
    },
  ];
  const {
    topDishSelectedVal,
    trendingRecipeSelectedVal,
    productSelectedVal,
    productDescriptionSelectedVal,
    userPromptSelectedVal,
    marketSelectedVal,
    brandSelectedVal,
    tastewiseSelectionValue,
    trendingRecipeTastewiseSelectedVal,
    trendingRecipeTastewiseIndexScoreSelectedVal,
    trendingRecipeTastewiseOthersSelectedVal,
    trendingRecipeOthersSelectedVal,
    productOthersSelectedVal,
    productDescriptionOthersSelectedVal,
    topDishTastewiseSelectedVal,
    userEmail,
  } = useSelector((state) => state.createBrief);
  const {
    audienceTypeSelectedVal,
    platformSelectedVal,
    campaignObjectiveSelectedVal,
    mediaTypeSelectedVal,
    selectedOutputLanguage,
  } = useSelector((state) => state.addMoreFlavour);

  const [inputPrompt, setInputPrompt] = useState(inputEditPrompt);
  const [readyMadePrompt, setReadyMadePrompt] = useState(promptTonality);

  const handleAIPromptData = async (section) => {
    dispatch(setPromptTonality(readyMadePrompt));
    dispatch(setInputEditPrompt(inputPrompt));
    try {
      const response = await apiService({
        method: "POST",
        url: `${process.env.REACT_APP_AI_API_URL}/brief/edit-brief`,
        body: {
          market:
            marketSelectedVal === "UK" ? "United Kingdom" : marketSelectedVal,
          brand: brandSelectedVal,
          top_dish:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? topDishTastewiseSelectedVal
              : topDishSelectedVal,
          trending_recipe:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? trendingRecipeTastewiseSelectedVal === "Others"
                ? trendingRecipeTastewiseOthersSelectedVal
                : trendingRecipeTastewiseSelectedVal
              : trendingRecipeSelectedVal === "Others"
              ? trendingRecipeOthersSelectedVal
              : trendingRecipeSelectedVal,
          index_score: trendingRecipeTastewiseIndexScoreSelectedVal,
          product:
            productSelectedVal === "Others"
              ? productOthersSelectedVal
              : productSelectedVal,
          product_description:
            productSelectedVal === "Others"
              ? productDescriptionOthersSelectedVal
              : productDescriptionSelectedVal,
          audience_type: audienceTypeSelectedVal,
          platform: platformSelectedVal,
          campaign_objective: campaignObjectiveSelectedVal,
          media_type: mediaTypeSelectedVal,
          default_prompt: userPromptSelectedVal,
          user_name: userEmail,
          question:
            section === "CAMPAIGN_KEY_MESSAGE"
              ? "CAMPAIGN KEY MESSAGE"
              : "SUGGESTED CAPTIONS",
          feedback_prompt: inputPrompt,
          feedback_tone: [readyMadePrompt],
          output_language: selectedOutputLanguage,
        },
        headers: {
          "Content-Type": "application/json",
        },
      });
      let responseData = response.response;

      if (section === "SUGGESTED_CAPTIONS") {
        responseData = responseData
          .split("\n")
          .map((line) => line.replace(/^\d+\. /, ""));
        dispatch(setSuggestedHeadlines(responseData));
        setSuggestedCaptionsData((prev) => ({
          ...prev,
          data: responseData,
          isEdit: false,
        }));
      } else {
        responseData = responseData
          .split("\n")
          .map((line) => line.replace(/^\d+\. /, ""));
        dispatch(setCaptionIdeas(responseData));
        setCaptionIdeasData((prev) => ({
          ...prev,
          data: responseData,
          isEdit: false,
        }));
        dispatch(setCreateUpdateDateTime(response.updated_dttm));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  const editBriefPromptAPICallClick = async () => {
    await handleAIPromptData("CAMPAIGN_KEY_MESSAGE");
    await handleAIPromptData("SUGGESTED_CAPTIONS");
  };
  return (
    <CommonLayout
      setInputPrompt={setInputPrompt}
      setReadyMadePrompt={setReadyMadePrompt}
      editBriefPromptAPICallClick={editBriefPromptAPICallClick}
      isPromptRequired
      onHandleNextClick={handleNext}
      tooltipData={contentIdeasTooltip}
      contentHeaders={contentHeaders}
    />
  );
}

export default ContentIdea;
