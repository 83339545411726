import * as React from "react";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageWithIcon from "../ImageWithIcon/ImageWithIcon";
import emptyImage from "../../assets/images/emptyImage.png";
import UploadImageModal from "../UploadImageModal/UploadImageModal";

function ImageListComponent({ data }) {
  const totalImages = data?.length;
  const maxImages = 6;

  const numPlaceholders = maxImages - totalImages;

  const [imageDialogOpen, setImageDialogOpen] = React.useState(false);

  const handleImageClick = (image) => {
    setImageDialogOpen(true);
  };

  return (
    <center>
      <div style={{ display: "flex" }}>
        <ImageList sx={{ width: 682 }} cols={3} rowHeight={220}>
          {data?.map((item) => (
            <ImageListItem key={item.img}>
              <ImageWithIcon isImageList={true} src={item.link} handleModalOpenClick={handleImageClick} />
            </ImageListItem>
          ))}
          {Array.from({ length: numPlaceholders }, (_, index) => (
            <ImageListItem key={`placeholder-${index}`}>
              <ImageWithIcon isEmptyImg={true} isImageList={true} src={emptyImage} handleModalOpenClick={handleImageClick} />
            </ImageListItem>
          ))}
        </ImageList>
        <UploadImageModal
            open={imageDialogOpen}
            onClose={() => setImageDialogOpen(false)}
            tabNames={["Brand"]}
            tabSelected={"Inspiration"}
          />
      </div>
    </center>
  );
}

export default ImageListComponent;
