import React, { useState } from "react";
import CommonLayout from "../../pages/CommonLayout/CommonLayout";
import CustomInputTextarea from "../CustomInputTextarea/CustomInputTextarea";
import CustomizedTooltips from "../Tooltip/CustomizedTooltips";
import { campaignGoalTooltipData } from "../../Constant/EditBreifTooltip";
import { useDispatch, useSelector } from "react-redux";
import apiService from "../../utilities/service/apiService";
import { setCampaignGoal, setCreateUpdateDateTime, setInputEditPrompt, setPromptTonality } from "../../slicers/editYourBriefSlicer";
import { updateBriefData } from "../../utilities/apiUtils/apiUtils";
import { theAsktext } from "../../Constant/EditBrief";
import { useTextLabels } from "../../Constant/useTextLabels";

function CampaignGoal({ handleNext }) {
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const labels = useTextLabels()
  const { campaignGoal,promptTonality,inputEditPrompt } = useSelector((state) => state.editYourBrief);
  const [inputData, setInputData] = useState(campaignGoal);
  const [originalData, setOriginalData] = useState(campaignGoal);
  const handleEdit = () => {
    setIsEdit(true);
  };

  const {
    topDishSelectedVal,
    trendingRecipeSelectedVal,
    productSelectedVal,
    productDescriptionSelectedVal,
    userPromptSelectedVal,
    marketSelectedVal,
    brandSelectedVal,
    briefId,
    briefData,
    tastewiseSelectionValue,
    trendingRecipeTastewiseSelectedVal,
    trendingRecipeTastewiseIndexScoreSelectedVal,
    trendingRecipeTastewiseOthersSelectedVal,
    trendingRecipeOthersSelectedVal,
    productOthersSelectedVal,
    productDescriptionOthersSelectedVal,
    topDishTastewiseSelectedVal,
    userEmail,
  } = useSelector((state) => state.createBrief);
  const {
    audienceTypeSelectedVal,
    platformSelectedVal,
    campaignObjectiveSelectedVal,
    mediaTypeSelectedVal,
    selectedOutputLanguage
  } = useSelector((state) => state.addMoreFlavour);

  const onCancelClick = () => {
    setIsEdit(false);
    setInputData(originalData); 
  };
  const handleDataSave = async () => {
    try {
      const response = await apiService({
        method: "PUT",
        url: `${window.location.origin}/briefs/${briefId}`,
        body : updateBriefData(
          "THE_ASK",
          inputData,
          briefData,
          dispatch
        ),
        headers: {
          "Content-Type": "application/json",
        },
      });
      setIsEdit(false);
      dispatch(setCampaignGoal(response.THE_ASK));
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const [inputPrompt, setInputPrompt] = useState(inputEditPrompt);
  const [readyMadePrompt, setReadyMadePrompt] = useState(promptTonality);

  const handleInputChange = (e) => {
    setInputData(e.target.value);
  };
  const editBriefPromptAPICallClick = async () => {
    dispatch(setPromptTonality(readyMadePrompt));
    dispatch(setInputEditPrompt(inputPrompt))
    try {
      const response = await apiService({
        method: "POST",
        url: `${process.env.REACT_APP_AI_API_URL}/brief/edit-brief`,
        body: {
          market:
            marketSelectedVal === "UK" ? "United Kingdom" : marketSelectedVal,
          brand: brandSelectedVal,
          top_dish:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? topDishTastewiseSelectedVal
              : topDishSelectedVal,
          trending_recipe:
            tastewiseSelectionValue === "Top Dish Trend Index"
              ? trendingRecipeTastewiseSelectedVal === "Others"
                ? trendingRecipeTastewiseOthersSelectedVal
                : trendingRecipeTastewiseSelectedVal
              : trendingRecipeSelectedVal === "Others"
              ? trendingRecipeOthersSelectedVal
              : trendingRecipeSelectedVal,
          index_score: trendingRecipeTastewiseIndexScoreSelectedVal,
          product:
            productSelectedVal === "Others"
              ? productOthersSelectedVal
              : productSelectedVal,
          product_description:
            productSelectedVal === "Others"
              ? productDescriptionOthersSelectedVal
              : productDescriptionSelectedVal,
          audience_type: audienceTypeSelectedVal,
          platform: platformSelectedVal,
          campaign_objective: campaignObjectiveSelectedVal,
          media_type: mediaTypeSelectedVal,
          default_prompt: userPromptSelectedVal,
          user_name: userEmail,
          question: "The Ask",
          feedback_prompt: inputPrompt,
          feedback_tone: [readyMadePrompt],
          output_language : selectedOutputLanguage,

        },

        headers: {
          "Content-Type": "application/json",
        },
      });
      setInputData(response.response)
      dispatch(setCreateUpdateDateTime(response.updated_dttm))
      dispatch(setCampaignGoal(response.response))
    
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle API call error here
    }
  };

  return (
    <CommonLayout
      isPromptRequired
      setInputPrompt={setInputPrompt}
      setReadyMadePrompt={setReadyMadePrompt}
      editBriefPromptAPICallClick={editBriefPromptAPICallClick}
      onHandleNextClick={handleNext}
      tooltipData={campaignGoalTooltipData}
      contentHeaders={[
        {
          title: labels.THE_ASK,
          onEdit: handleEdit,
          content: isEdit ? (
            <CustomInputTextarea
              wordLimit={100}
              isCancelSaveRequired={isEdit}
              handleCancelClick={onCancelClick}
              handleSaveChangesClick={handleDataSave}
              data={inputData}
              handleChange={handleInputChange}
            />
          ) : (
            inputData
          ),
          customClass: "campaignGoalLayout",
        },
      ]}
    />
  );
}

export default CampaignGoal;
