import React from "react";
import { CircularProgress } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  overlay: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(255, 255, 255, 0.5)", // Semi-transparent white background
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    zIndex: 9999, // Ensure the loading screen appears above other content
  },
}));

const LoadingScreen = () => {
  const classes = useStyles();

  return (
    <div className={classes.overlay}>
      <CircularProgress color="primary" /> {/* Use primary color for the circular progress indicator */}
    </div>
  );
};

export default LoadingScreen;
