import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    topDishTastewiseSelectedVal: "",
    trendingRecipeTastewiseOthersSelectedVal: "",
    trendingRecipeTastewiseSelectedVal: "",
    trendingRecipeTastewiseOptions: [],
    trendingRecipeTastewiseIndexScoreSelectedVal: 0.0,
    topDishSelectedVal: "",
    topDishOptions: [],
    trendingRecipeOthersSelectedVal: "",
    trendingRecipeSelectedVal: "Others",
    trendingRecipeOptions: [],
    productOthersSelectedStatus: false,
    productOthersSelectedVal: "",
    productSelectedVal: "",
    productOptions: [],
    productDescriptionOthersSelectedVal: "",
    productDescriptionSelectedVal: "",
    userPromptSelectedVal: "",
    marketSelectedVal: "",
    marketOptions: [],
    brandSelectedVal: "",
    brandOptions: [],
    tastewiseSelectionValue: "Type It In",
    uploadedImageURL: '',
    uploadedFileName: '',
    brandImageFileName: '',
    productImageFileName: '',
    campaignImageFileName: '',
    inspirationImageFileName: [],
    brandImageURL: '',
    productImageURL: '',
    campaignImageURL: '',
    inspirationImageURL: [],
    briefId: '',
    briefData:{},
    isLoading: false,
    isViewPreviousBriefModalOpen: false,
    userName: '',
    userEmail: '',
    blobProductImageURL:"",
    isTastewiseFeatureRequired:true,
  };
  

export const createBriefSlicer = createSlice({
  name: "createBrief",
  initialState,
  reducers: {
    // Tastewise section, Top Dish Trend Index
    setTastewiseSelectionValue: (state, action) => ({
      ...state,
      tastewiseSelectionValue: action.payload,
    }),
    setIsTastewiseFeatureRequired: (state, action) => ({
      ...state,
      isTastewiseFeatureRequired: action.payload,
    }),
    setBlobProductImageURL: (state, action) => ({
      ...state,
      blobProductImageURL: action.payload,
    }),
    setTopDishTastewiseSelectedVal: (state, action) => ({
      ...state,
      topDishTastewiseSelectedVal: action.payload,
    }),
    setTrendingRecipeTastewiseOthersSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeTastewiseOthersSelectedVal: action.payload,
    }),
    setTrendingRecipeTastewiseSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeTastewiseSelectedVal: action.payload,
    }),
    setTrendingRecipeTastewiseOptions: (state, action) => ({
      ...state,
      trendingRecipeTastewiseOptions: action.payload,
    }),
    setTrendingRecipeTastewiseIndexScoreSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeTastewiseIndexScoreSelectedVal: action.payload,
    }),
    // Non-tastewise section, Type it in section
    setTopDishSelectedVal: (state, action) => ({
      ...state,
      topDishSelectedVal: action.payload,
    }),
    setTopDishOptions: (state, action) => ({
      ...state,
      topDishOptions: action.payload,
    }),
    setTrendingRecipeOthersSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeOthersSelectedVal: action.payload,
    }),
    setTrendingRecipeSelectedVal: (state, action) => ({
      ...state,
      trendingRecipeSelectedVal: action.payload,
    }),
    setTrendingRecipeOptions: (state, action) => ({
      ...state,
      trendingRecipeOptions: action.payload,
    }),
    setProductOthersSelectedStatus: (state, action) => ({
      ...state,
      productOthersSelectedStatus: action.payload,
    }),
    setProductOthersSelectedVal: (state, action) => ({
      ...state,
      productOthersSelectedVal: action.payload,
    }),
    setProductSelectedVal: (state, action) => ({
      ...state,
      productSelectedVal: action.payload,
    }),
    setProductOptions: (state, action) => ({
      ...state,
      productOptions: action.payload,
    }),
    setProductDescriptionOthersSelectedVal: (state, action) => ({
      ...state,
      productDescriptionOthersSelectedVal: action.payload,
    }),
    setProductDescriptionSelectedVal: (state, action) => ({
      ...state,
      productDescriptionSelectedVal: action.payload,
    }),
    setUserPromptSelectedVal: (state, action) => ({
      ...state,
      userPromptSelectedVal: action.payload,
    }),
    setMarketSelectedVal: (state, action) => ({
      ...state,
      marketSelectedVal: action.payload,
    }),
    setMarketOptions: (state, action) => ({
      ...state,
      marketOptions: action.payload,
    }),
    setBrandSelectedVal: (state, action) => ({
      ...state,
      brandSelectedVal: action.payload,
    }),
    setBrandOptions: (state, action) => ({
      ...state,
      brandOptions: action.payload,
    }),
    setUploadedImage: (state, action) => ({
      ...state,
      uploadedImageURL: action.payload,
    }),
    setUploadedImageURL: (state, action) => {
      const { tab, url } = action.payload;
      switch (tab) {
        case "Brand":
          return {
            ...state,
            brandImageURL: url,
          };
        case "Product":
          return {
            ...state,
            productImageURL: url,
          };
        case "Campaign":
          return {
            ...state,
            campaignImageURL: url,
          };
        case "Inspiration":
          return {
            ...state,
            inspirationImageURL: [...state.inspirationImageURL, url],
          };
        default:
          return state;
      }
    },
    setUploadedImageFileName: (state, action) => {
      const { tab, fileName } = action.payload;
      switch (tab) {
        case "Brand":
          return {
            ...state,
            brandImageFileName: fileName,
          };
        case "Product":
          return {
            ...state,
            productImageFileName: fileName,
          };
        case "Campaign":
          return {
            ...state,
            campaignImageFileName: fileName,
          };
        case "Inspiration":
          return {
            ...state,
            inspirationImageFileName: [
              ...state.inspirationImageFileName,
              fileName,
            ],
          };
        default:
          return state;
      }
    },
    removeUploadedImage: (state, action) => {
      const { tab, index } = action.payload;
      switch (tab) {
        case "Inspiration":
          return {
            ...state,
            inspirationImageFileName: state.inspirationImageFileName.filter(
              (_, i) => i !== index
            ),
            inspirationImageURL: state.inspirationImageURL.filter(
              (_, i) => i !== index
            ),
          };
        default:
          return state;
      }
    },
    setEmptyInsipirationImages: (state, action) => ({
      ...state,
      inspirationImageFileName: [],
      inspirationImageURL: [],
    }),
    setBriefId: (state, action) => ({
      ...state,
      briefId: action.payload,
    }),
    setBriefData: (state, action) => ({
      ...state,
      briefData: action.payload,
    }),
    setIsLoading: (state, action) => ({
      ...state,
      isLoading: action.payload,
    }),
    setIsViewPreviousBriefModalOpen: (state, action) => ({
      ...state,
      isViewPreviousBriefModalOpen: action.payload,
    }),
    setUserName: (state, action) => ({
      ...state,
      userName: action.payload,
    }),
    setUserEmail: (state, action) => ({
      ...state,
      userEmail: action.payload,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setTastewiseSelectionValue,
  setTrendingRecipeTastewiseOthersSelectedVal,
  setTrendingRecipeTastewiseSelectedVal,
  setTrendingRecipeTastewiseOptions,
  setTrendingRecipeTastewiseIndexScoreSelectedVal,
  setTopDishSelectedVal,
  setTopDishOptions,
  setTrendingRecipeOthersSelectedVal,
  setTrendingRecipeSelectedVal,
  setTrendingRecipeOptions,
  setProductOthersSelectedStatus,
  setProductOthersSelectedVal,
  setProductSelectedVal,
  setProductOptions,
  setProductDescriptionOthersSelectedVal,
  setProductDescriptionSelectedVal,
  setUserPromptSelectedVal,
  setMarketSelectedVal,
  setMarketOptions,
  setBrandSelectedVal,
  setBrandOptions,
  setTopDishTastewiseSelectedVal,
  setUploadedImage,
  setUploadedImageURL,
  setUploadedImageFileName,
  removeUploadedImage,
  setEmptyInsipirationImages,
  setBriefId,
  setBriefData,
  setIsLoading,
  setIsViewPreviousBriefModalOpen,
  setUserName,
  setBlobProductImageURL,
  setUserEmail,
  setIsTastewiseFeatureRequired
} = createBriefSlicer.actions;

export default createBriefSlicer.reducer