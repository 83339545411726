import React, { useEffect } from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import Home from "./pages/Home/Home";
import EditYourBrief from "./pages/EditYourBrief/EditYourBrief";
import Landing from "./pages/Landing/Landing";

const router = createBrowserRouter([
  {
    path: "/create-brief",
    element: <Home />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/EditYourBrief",
    element: <EditYourBrief />,
    // errorElement: <ErrorPage />,
  },
  {
    path: "/",
    element: <Landing />,
    // errorElement: <ErrorPage />,
  },
]);

function App() {
  const { instance, accounts } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  // const { loginHint } = useAccount(accounts[0] || {});

  useEffect(() => {

    const currentAccount = instance.getActiveAccount();

    if (!isAuthenticated) {
      instance
        .ssoSilent({
          scopes: ["user.read"],
          loginHint: "",
        })
        .then((response) => {
          instance.setActiveAccount(response.account);
          console.log("response", response);
          // dispatch(setUserName(currentAccount.name));
        })
        .catch((error) => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.loginPopup({
              scopes: ["user.read"],
            });
          }
        });
    }
  }, [instance]);
  return <RouterProvider router={router} />;
}

export default App;
