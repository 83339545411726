import React from "react";
import { Box } from "@mui/material";
import "./Slide5.css";
import { useSelector } from "react-redux";
import Heading from "../Heading";
import BulletPoints from "../BulletPoints";
import { campaignKeyMessageText, creativeChallengTabs, suggestedCaptionText, suggestedHeadlineText, thoughtStarterText } from "../../../Constant/EditBrief";

function Slide5({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  illustriation1,
  subHeadingFillColor,
  secondSubHeadingFontSize,
  illustriation2,
  secondSubHeadingFontColor,
  listContentFontSize,
  coverImageRight,
  placement,
  labels,
}) {
  const { captionIdeas, suggestedHeadlines } = useSelector(
    (state) => state.editYourBrief
  );
  const { campaignImageURL } = useSelector((state) => state.createBrief);
  
  return (
    <Box display={"flex"} className={`${placement}`}>
      <Box className="slide5Left">
        <Box className="thoughtStart">
          <Heading
            text={labels.THOUGHT_STARTER}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
        </Box>
        <Box className="keyMessageMain">
          <Heading
            text={labels.CAMPAIGN_KEY_MESSAGES}
            textColor={fontColor}
            textSize={subHeadingFontSize}
            textWeight={headingFontWeight}
          />
          <BulletPoints
            items={captionIdeas}
            textColor={fontColor}
            textSize={listContentFontSize}
            textWeight={subHeadingFontWeight}
          />
        </Box>
        <Box className="suggestedCaptionMain">
          <Heading
            text={labels.SUGGESTED_CAPTIONS}
            textColor={fontColor}
            textSize={subHeadingFontSize}
            textWeight={headingFontWeight}
          />
          <BulletPoints
            items={suggestedHeadlines}
            textColor={fontColor}
            textSize={listContentFontSize}
            textWeight={subHeadingFontWeight}
          />
        </Box>
      </Box>
      <Box className="slide5RightMain">
    {coverImageRight &&  <img src={coverImageRight} width={530} height={360} style={{
        marginTop:"140px"
      }} />}
     <Box style={{
        background:`#${subHeadingFillColor}`,
        padding:"6px 23px 7px 10px",
        width:"90%"
     }}>
     <Heading
        text={suggestedHeadlineText}
        textColor={secondSubHeadingFontColor}
        textSize={secondSubHeadingFontSize}
        textWeight={400}
      />
     </Box>
    </Box>
    </Box>
  );
}

export default Slide5;
