import React from "react";
import { Box, Grid } from "@mui/material";
import { useSelector } from "react-redux";
import EditIcon from "@mui/icons-material/Edit";
import { brandOptions } from "../../Constant/PreviewPPTLayoutInfo";
import "./PreviewLayout.css";

const imageContext = require.context("../../assets/images", true);

function PreviewLayout({
  backgroundImageLeft,
  backgroundImageRight,
  Content,
  handleEditClick,
  hideLogoEditPen = false,
  placement,
  watermark,
}) {
  const { brandSelectedVal } = useSelector((state) => state.createBrief);
  const selectedBrand = brandOptions.find(
    (brand) => brand.brand_name === brandSelectedVal
  );
  const logoPath = imageContext(`./${brandSelectedVal}/${selectedBrand?.logo}`);
  const isBothImagesPresent = backgroundImageLeft && backgroundImageRight;

  return (
    <Box className="previewLayoutContainer">
      <div style={{ display: "flex" }} className={`${placement}`}>
        {isBothImagesPresent ? (
          <>
            <div style={{ flex: 1 }}>
              <img
                src={backgroundImageLeft}
                alt="Background 1"
                className="backgroundImage"
              />
            </div>
            <div style={{ flex: 1 }}>
              <img
                src={backgroundImageRight}
                alt="Background 2"
                className="backgroundImage"
              />
            </div>
          </>
        ) : (
          <div style={{ flex: 1 }}>
            <img
              src={backgroundImageLeft || backgroundImageRight}
              alt="Background"
              className="backgroundImage"
            />
          </div>
        )}
      </div>

      <Box className="contentLogoMain">
        <Box style={{ width: "100%" }}>{Content}</Box>
        {!hideLogoEditPen && selectedBrand && (
          <img
            src={logoPath}
            alt={`${brandSelectedVal} Logo`}
            width={58}
            height={48}
            className="logoImg"
          />
        )}
      </Box>
      {watermark && (
        <Box
          position="absolute"
          top="50%"
          left="50%"
          style={{
            transform: "translate(-50%, -50%)",
            opacity: 0.3,
            fontSize: "48px",
            fontWeight: "bold",
            color: "white",
            pointerEvents: "none",
            zIndex: 1,
          }}
        >
          <img
            src={imageContext(
              `./${brandSelectedVal}/${selectedBrand.watermarkImage}`
            )}
          />
        </Box>
      )}

      {!hideLogoEditPen &&
       (
          <Box className="editPen">
            <EditIcon onClick={handleEditClick} />
          </Box>
        )}
    </Box>
  );
}

export default PreviewLayout;
