import { useSelector } from 'react-redux';

// Define a hook to fetch and return labels from Redux store
export const useTextLabels = () => {
  const {headerQuestionsLabels} = useSelector(state => state.editYourBrief);

  // Create an object to map label_name IDs to their values
  const labelsMap = headerQuestionsLabels.reduce((acc, { label_key, label_name }) => {
    switch (label_key) {
      case "ALL_ABOUT":
        acc.ALL_ABOUT = label_name;
        break;
      case "CAMPAIGN_BACKGROUND":
        acc.CAMPAIGN_BACKGROUND = label_name;
        break;
      case "THE_ASK":
        acc.THE_ASK = label_name;
        break;
      case "THOUGHT_STARTER":
        acc.THUGHT_STARTER = label_name;
        break;
      case "INSPIRATION":
        acc.INSPIRATION = label_name;
        break;
      case "CONTENT_MANDATORIES":
        acc.CONTENT_MANDATORIES = label_name;
        break;
      case "DELIVERABLES":
        acc.DELIVERABLES = label_name;
        break;
      case "SOCIAL_MEDIA_HANDLES_AND_HASHTAGS":
        acc.SOCIAL_MEDIA_HANDLES_AND_HASHTAGS = label_name;
        break;
      case "LINKS_TO_USE_WITHIN_CAPTIONS":
        acc.LINKS_TO_USE_WITHIN_CAPTIONS = label_name;
        break;
      case "POSTING_TIMELINES":
        acc.POSTING_TIMELINES = label_name;
        break;
      case "CAMPAIGN_IMAGES":
        acc.CAMPAIGN_IMAGES = label_name;
        break;
      case "CAMPAIGN_KEY_MESSAGES":
        acc.CAMPAIGN_KEY_MESSAGES = label_name;
        break;
      case "SUGGESTED_CAPTIONS":
        acc.SUGGESTED_CAPTIONS = label_name;
        break;
      case "DO_S":
        acc.DO_S = label_name;
        break;
      case "DON_TS":
        acc.DON_TS = label_name;
        break;
      case "HASHTAGS":
        acc.HASHTAGS = label_name;
        break;
      case "HANDLES":
        acc.HANDLES = label_name;
        break;
      case "CONTRACTUAL_BRIEFING":
        acc.CONTRACTUAL_BRIEFING = label_name;
        break;
      case "SHOOTING_PRODUCTION":
        acc.SHOOTING_PRODUCTION = label_name;
        break;
      case "SEND_CONTENT_TO_BRAND_TEAM_FOR_REVIEW":
        acc.SEND_CONTENT_TO_BRAND_TEAM_FOR_REVIEW = label_name;
        break;
      case "EDIT_2_2":
        acc.EDIT_2_2 = label_name;
        break;
      case "FINAL_APPROVAL":
        acc.FINAL_APPROVAL = label_name;
        break;
      case "CONTENT_GO_LIVE":
        acc.CONTENT_GO_LIVE = label_name;
        break;
      case "CREATOR_BRIEFING":
        acc.CREATOR_BRIEFING = label_name;
        break;
      case "THANK_YOU":
        acc.THANK_YOU = label_name;
        break;
      case "CAPTION_IDEAS_TO_INSPIRE":
        acc.CAPTION_IDEAS_TO_INSPIRE = label_name;
        break;
      case "PASTE_HERE_THE_WEBLINK_FOR_ANY_SAMPLE_VIDEOS_(OPTIONAL)":
        acc.PASTE_HERE_THE_WEBLINK_FOR_ANY_SAMPLE_VIDEOS_OPTIONAL = label_name;
        break;
      default:
        break;
    }
    
    return acc;
  }, {});

  return labelsMap;
};
