import apiService from "../service/apiService";
import {
  setMarketOptions,
  setBrandOptions,
  setBrandSelectedVal,
  setTopDishOptions,
  setTopDishSelectedVal,
  setTopDishTastewiseSelectedVal,
  setTrendingRecipeOptions,
  setTrendingRecipeSelectedVal,
  setTrendingRecipeOthersSelectedVal,
  setTrendingRecipeTastewiseOptions,
  setTrendingRecipeTastewiseSelectedVal,
  setTrendingRecipeTastewiseOthersSelectedVal,
  setProductOptions,
  setProductSelectedVal,
  setProductDescriptionSelectedVal,
  setProductDescriptionOthersSelectedVal,
  setProductOthersSelectedVal,
  setUploadedImageURL,
  setUploadedImageFileName,
  setMarketSelectedVal,
  setTastewiseSelectionValue,
  setUserPromptSelectedVal,
  setIsTastewiseFeatureRequired,
} from "../../slicers/createBriefSlicer";
import {
  setAudienceTypeSelectedVal,
  setPlatformSelectedVal,
  setCampaignObjectiveSelectedVal,
  setMediaTypeSelectedVal,
  setOutputLanguageList,
  setSelectedOutputLanguage,
  setSelectedOutputLanguageId
} from "../../slicers/addMoreFlavourSlicer";
import { setActiveIndexofTab, setCreateUpdateDateTime } from "../../slicers/editYourBriefSlicer";
import axios from "axios";

const fetchData = async (
  dispatch,
  tastewiseSelectionValue,
  marketSelectedVal,
  brandSelectedVal,
  topDishSelectedVal,
  productSelectedVal,
  productDescriptionSelectedVal,
  trendingRecipeSelectedVal,
  dataSourceUserSelectionName,
  trendingRecipeOptionsTastewise
) => {
  try {
    console.log(window.location.origin,"8888")
    const data = await apiService({
      method: "GET",
      url:`${window.location.origin}/data/?`,
    });
    
    dispatch(setMarketOptions(data));
    dispatch(setMarketSelectedVal(marketSelectedVal || data[0].market_name));

    // fetch data for brand
    apiCallFetchBrand(
      marketSelectedVal || data[0].market_name,
      dispatch,
      data,
      brandSelectedVal,
      topDishSelectedVal,
      productSelectedVal,
      productDescriptionSelectedVal,
      trendingRecipeSelectedVal,
      dataSourceUserSelectionName,
      tastewiseSelectionValue,
      trendingRecipeOptionsTastewise
    );
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error; // Throw the error for handling in the calling component
  } finally {
  }
};

// API call to populate brand dropdown in create your brief section
const apiCallFetchBrand = async (
  userSelection,
  dispatch,
  marketOptions,
  brandSelectedVal,
  topDishSelectedVal,
  productSelectedVal,
  productDescriptionSelectedVal,
  trendingRecipeSelectedVal,
  dataSourceUserSelectionName,
  tastewiseSelectionValue,
  trendingRecipeOptionsTastewise
) => {
  // Get the selected market object from marketOptions array
  const selectedOptions = marketOptions.find(
    (market) => market.market_name === userSelection
  );
  dispatch(setIsTastewiseFeatureRequired(selectedOptions.is_tastewise_required))
  const kpi = "market";
  try {
    const response = await apiService({
      method: "GET",
      url: `${window.location.origin}/data/?${kpi}_id=${
        selectedOptions[kpi + "_id"]
      }`,
      // Add any additional parameters or headers if needed
    });

    // Dispatch actions to update Redux state based on API response
    console.log(response.languages,"88888")
    dispatch(setSelectedOutputLanguage())
    dispatch(setSelectedOutputLanguageId())
    if (response ) {
      dispatch(setBrandOptions(response.brands));
      dispatch(setBrandSelectedVal(brandSelectedVal || response.brands[0].brand_name));
      dispatch(setOutputLanguageList(response.languages))
      const baseLanguage = response.languages.find(lang => lang.base_language);
      dispatch(setSelectedOutputLanguage(baseLanguage.language_name));
      dispatch(setSelectedOutputLanguageId(baseLanguage.language_id));
      apiCallFetchTopDishAndProduct(
        brandSelectedVal || response.brands[0].brand_name,
        dispatch,
        marketOptions,
        selectedOptions.market_name,
        response.brands,
        topDishSelectedVal,
        productSelectedVal,
        productDescriptionSelectedVal,
        trendingRecipeSelectedVal,
        dataSourceUserSelectionName,
        tastewiseSelectionValue,
        trendingRecipeOptionsTastewise
      );
    } else {
      console.log("No brand data received.");
    }
  } catch (error) {
    console.error("Error fetching Brand API:", error.message);
  } finally {
  }
};

// Function to fetch top dish and product data
const apiCallFetchTopDishAndProduct = async (
  userSelection,
  dispatch,
  marketOptions,
  marketSelectedVal,
  brandOptions,
  topDishSelectedVal,
  productSelectedVal,
  productDescriptionSelectedVal,
  trendingRecipeSelectedVal,
  dataSourceUserSelectionName,
  tastewiseSelectionValue,
  trendingRecipeOptionsTastewise
) => {

  // Get the selected market object from marketOptions array
  const selectedMarket = marketOptions.find(
    (market) => market.market_name === marketSelectedVal
  );
    console.log(brandOptions)
  // Get the selected brand object from brandOptions array
  const selectedOptions = brandOptions.find(
    (brand) => brand.brand_name === userSelection
  );
  const kpi = "brand";

  console.log("topDishSelectedVal", topDishSelectedVal);

  try {
    const response = await apiService({
      method: "GET",
      url: `${window.location.origin}/data/?market_id=${selectedMarket.market_id}&brand_id=${selectedOptions.brand_id}`,
    });

    // Saving top dish dropdown - options, default selected value in Redux
    if (response.top_dishes && response.top_dishes.length > 0) {
      if (dataSourceUserSelectionName) {
        // previous brief
        if (dataSourceUserSelectionName === "Top Dish Trend Index") {
          dispatch(setTopDishTastewiseSelectedVal(topDishSelectedVal));
          dispatch(
            setTrendingRecipeTastewiseOptions(trendingRecipeOptionsTastewise)
          );

          const checkTrendingRecipe = trendingRecipeOptionsTastewise.find(
            (recipe) => recipe.trending_recipe === trendingRecipeSelectedVal
          );
          if (checkTrendingRecipe) {
            // trending recipe exists in options and is not others
            dispatch(
              setTrendingRecipeTastewiseSelectedVal(trendingRecipeSelectedVal)
            );
          } else {
            // trending recipe doesn't exist in options and is others, manually typed in
            dispatch(setTrendingRecipeTastewiseSelectedVal("Others"));
            dispatch(
              setTrendingRecipeTastewiseOthersSelectedVal(
                trendingRecipeSelectedVal
              )
            );
          }
        } else {
          dispatch(setTopDishOptions(response.top_dishes));
          dispatch(setTopDishSelectedVal(topDishSelectedVal));
          dispatch(setTrendingRecipeSelectedVal("Others"));
          dispatch(
            setTrendingRecipeOthersSelectedVal(trendingRecipeSelectedVal)
          );
        }
      } else {
        // normal api call
        if (tastewiseSelectionValue === "Top Dish Trend Index") {
          dispatch(setTopDishOptions(response.top_dishes));
          dispatch(setTopDishSelectedVal(response.top_dishes[0].top_dish_name));
        } else {
          dispatch(setTopDishOptions(response.top_dishes));
          setTopDishOptions(response.top_dishes);
          dispatch(
            setTopDishSelectedVal(
              topDishSelectedVal || response.top_dishes[0].top_dish_name
            )
          );
          dispatch(setTrendingRecipeSelectedVal("Others"));
        }
      }
    } else {
      console.log("No top dishes data received.");
    }

    // Saving product dropdown - options, default selected value in Redux
    if (response.products && response.products.length > 0) {
      dispatch(setProductOptions(response.products));

      // The below if block is when the user is clicking on view previous brief
      if (productSelectedVal) {
        const checkProduct = response.products.find(
          (product) => product.product_name === productSelectedVal
        );
        if (checkProduct) {
          dispatch(setProductSelectedVal(productSelectedVal));
          dispatch(
            setProductDescriptionSelectedVal(checkProduct.product_description)
          );
          dispatch(setProductDescriptionOthersSelectedVal(""));
        } else {
          dispatch(setProductSelectedVal("Others"));
          dispatch(setProductOthersSelectedVal(productSelectedVal));
          dispatch(
            setProductDescriptionOthersSelectedVal(
              productDescriptionSelectedVal
            )
          );
          dispatch(setProductDescriptionSelectedVal(""));
        }
      } else {
        //the below else block is when the user is creating a new brief
        dispatch(
          setProductSelectedVal(
            productSelectedVal || response.products[0].product_name
          )
        );
        dispatch(
          setProductDescriptionSelectedVal(
            productDescriptionSelectedVal || response.products[0].product_description
          )
        );
      }
    } else {
      console.log("No product data received.");
    }
  } catch (error) {
    console.error("Error fetching top dish API:", error.message);
  } finally {
  }
};

// Function to fetch trending recipe data
const apiCallFetchTrendingRecipe = async (
  userSelection,
  dispatch,
  marketOptions,
  marketSelectedVal,
  brandOptions,
  brandSelectedVal,
  topDishOptions,
  trendingRecipeSelectedVal,
  dataSourceUserSelectionName,
  tastewiseSelectionValue
) => {
  // Get the selected market object from marketOptions array
  const selectedMarket = marketOptions.find(
    (market) => market.market_name === marketSelectedVal
  );

  // Get the selected brand object from brandOptions array
  const selectedBrand = brandOptions.find(
    (brand) => brand.brand_name === brandSelectedVal
  );

  // Get the selected top dish object from topDishOptions array
  const selectedTopDish = topDishOptions.find(
    (topDish) => topDish.top_dish_name === userSelection
  );

  const kpi = "trending_dish";

  try {
    const response = await apiService({
      method: "GET",
      url: `${window.location.origin}/data/?market_id=${selectedMarket.market_id}&brand_id=${selectedBrand.brand_id}&top_dish_id=${selectedTopDish.top_dish_id}`,
    });

    if (response && response.length > 0) {
      dispatch(setTrendingRecipeOptions(response));
      dispatch(setTrendingRecipeSelectedVal(response[0].trending_recipe_name));
    } else {
      console.log("No trending recipe data received.");
    }
  } catch (error) {
    console.error("Error fetching Market API:", error.message);
  } finally {
  }
};

// Function to fetch last brief generated by the user
const fetchPreviousBrief = async (
  dispatch,
  tastewiseSelectionValue,
  marketOptions,
  navigate,
  userEmail
) => {
  try {
    const data = await apiService({
      method: "GET",
      url: `${window.location.origin}/view-previous-brief/${userEmail}`,
    });
    dispatch(setTastewiseSelectionValue(data.DATASOURCE_USER_SELECTION_NAME));
    dispatch(setAudienceTypeSelectedVal(data.AUDIENCE_TYPE));
    dispatch(setPlatformSelectedVal(JSON.parse(data.PLATFORM)));
    dispatch(setCampaignObjectiveSelectedVal(data.CAMPAIGN_OBJECTIVE));
    dispatch(setMediaTypeSelectedVal(JSON.parse(data.MEDIA_TYPE)));
    dispatch(setUserPromptSelectedVal(data.USER_PROMPT));
    fetchData(
      dispatch,
      tastewiseSelectionValue,
      data.MARKET_NAME,
      data.BRAND_NAME,
      data.TOP_DISH_NAME,
      data.PRODUCT_NAME,
      data.PRODUCT_DESCRIPTION,
      data.TRENDING_RECIPE_NAME,
      data.DATASOURCE_USER_SELECTION_NAME,
      JSON.parse(data.TRENDING_RECIPE_OPTIONS_TASTEWISE)
    );

    // before navigating to edit brief page, setting the active tab to 1st tab
    dispatch(setActiveIndexofTab(0));
    navigate("/EditYourBrief");
  } catch (error) {
    console.error("Error fetching data:", error.message);
    throw error; // Throw the error for handling in the calling component
  } finally {
  }
};

// Function to handle file input change
const handleFileInputImageChange = (
  e,
  dispatch,
  tabName,
  inspirationImageFileName
) => {
  const maxImages = tabName === "Inspiration" ? 6 : 1; // Determine max images based on tab
  const files = e.target.files;

  // Check if the number of images uploaded exceeds the limit for inspiration tab
  if (
    tabName === "Inspiration" &&
    inspirationImageFileName.length + files.length > maxImages
  ) {
    alert(
      `You can upload only ${maxImages} image${
        maxImages > 1 ? "s" : ""
      } for ${tabName}`
    );
    return;
  }

  // Dispatch action to handle file change for each file uploaded
  for (let i = 0; i < files.length; i++) {
    const file = files[i];
    handleFileChange(file, dispatch, tabName); // Pass the file to handleFileChange function
  }
};

// Function to handle file change
const handleFileChange = (file, dispatch, tabName) => {
  if (file) {
    dispatch(setUploadedImageFileName({ tab: tabName, fileName: file.name }));

    const reader = new FileReader();
    reader.onload = () => {
      const imageData = reader.result; // Base64-encoded data
      dispatch(setUploadedImageURL({ tab: tabName, url: imageData })); // Dispatch action with image data
    };
    reader.readAsDataURL(file);
  }
};

const updateBriefData = (section, newData, briefData,dispatch) => {
  const {
    [section]: excludedSection,
    BRIEF_ID,
    ...restOfBriefData
  } = briefData;

  const updatedBriefData = {
    ...restOfBriefData,
    [section]: newData,
    UPDATE_TIME: new Date().toISOString() 
  };
  dispatch(setCreateUpdateDateTime(updatedBriefData.UPDATE_TIME));
  return updatedBriefData;
};


// Function to handle get image from blob

const getImageUrl = (container, market, brand, imageName) => {
  // Construct the URL for the image from blob storage
  // Adjust the base URL according to your blob storage endpoint
  const baseUrl = `${window.location.origin}/download`;
  const encodedImageName = encodeURIComponent(imageName);
  const url = `${baseUrl}/?container_name=${container}&blob_folder=${market}&blob_subfolder=${brand}&filename=${encodedImageName}`;
  return url;
};

const uploadImageUrl = async (container, market, brand, file) => {
  try {
    const baseUrl = `${window.location.origin}/upload_to_blob/`;
    const formData = new FormData();
    
    formData.append('container_name', container);
    formData.append('blob_folder', market);
    formData.append('blob_subfolder', brand);
    formData.append('file', file);
    
    const response = await axios.post(baseUrl, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    
    return response.data;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

// Export the functions
export {
  fetchData,
  getImageUrl,
  apiCallFetchBrand,
  apiCallFetchTopDishAndProduct,
  apiCallFetchTrendingRecipe,
  fetchPreviousBrief,
  handleFileInputImageChange,
  updateBriefData,
  uploadImageUrl
};
