import React, { useState, useEffect, useRef } from "react";
import { Box, InputBase, Typography } from "@mui/material";
import uploadIcon from "../../assets/images/Up.png";
import infoIcon from "../../assets/images/infoIcon.png";
import CustomTooltip from "../CustomTooltip/CustomTooltip";
import {
  setInputEditPrompt,
  setPromptTonality,
} from "../../slicers/editYourBriefSlicer";
import { useDispatch, useSelector } from "react-redux";
import "./Footer.css";

const Footer = ({
  isPromptRequired = true,
  handleNextTab,
  setInputPrompt,
  setReadyMadePrompt,
  editBriefPromptAPICallClick,
}) => {
  const dispatch = useDispatch();
  const dropdownRef = useRef(null);
  const { inputEditPrompt, promptTonality } = useSelector(
    (state) => state.editYourBrief
  );
  const [inputValue, setInputValue] = useState(inputEditPrompt);
  const [selectedOption, setSelectedOption] = useState(promptTonality);
  const [isOpen, setIsOpen] = useState(false);

  const handleInputChange = (event) => {
    const value = event.target.value;
    const wordCount = value.trim().split(/\s+/).length;
    if (wordCount <= 150) {
      setInputValue(value);
      setInputPrompt(value);
    }
  };

  const handleDropdownToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleDropdownSelect = (option) => {
    setSelectedOption(selectedOption === option ? "" : option);
    setReadyMadePrompt(selectedOption === option ? "" : option);
    setIsOpen(false);
  };

  const editPrmptTooltipData = (
    <>
      <Typography
        sx={{
          color: "#111111",
          fontFamily: "Unilever Shilling",
          fontSize: "12px",
          fontStyle: "italic",
          fontWeight: 400,
          lineHeight: "20px",
        }}
      >
        Being explicit, descriptive, detailed will help you get better results!
      </Typography>
      <Box
        sx={{
          backgroundColor: "rgba(0, 94, 239, 0.1)",
          borderRadius: "10px",
          padding: "6px",
          marginTop: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#111111",
            fontFamily: "Unilever Shilling",
            fontSize: "12px",
            fontStyle: "italic",
            fontWeight: 400,
            lineHeight: "20px",
          }}
        >
          Ex: Create a Christmas brief to build on the concept of meal
          leftovers, which is humorous, for an influencer who does cook-with-me
          kind of recipe videos.
        </Typography>
      </Box>
    </>
  );

  const options = ["Enthausiastic", "Joyful", "Inviting", "Honest"];

  console.log(selectedOption);

  const isUploadDisabled = !(inputValue.trim() !== "" || (selectedOption.trim() !== "" && selectedOption !== "Select Tonality"));



  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: isPromptRequired ? "space-between" : "flex-end",
        alignItems: "center",
        marginBottom: "20px",
      }}
    >
      {isPromptRequired ? (
        <>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              border: "1px solid #ccc",
              borderRadius: "4px",
              padding: "4px",
              width: "50%",
              columnGap: "5px",
            }}
          >
            <CustomTooltip
              title={editPrmptTooltipData}
              tooltipPlacement={"bottom"}
              width={270}
            >
              <img src={infoIcon} height={15} width={15} alt="Info" />
            </CustomTooltip>
            <InputBase
              placeholder="Add your prompt"
              value={inputValue}
              onChange={(e) => handleInputChange(e)}
              sx={{
                flexGrow: 1,
                border: "none",
                padding: "4px",
                margin: "0 8px",
                width: 300,
                overflowY: "auto",
              }}
              multiline
              maxRows={2}
            />
            <div className="custom-dropdown" ref={dropdownRef}>
              <button
                className="dropdown-toggle"
                onClick={handleDropdownToggle}
              >
               {selectedOption || "Select Tonality"}
              </button>
              {isOpen && (
                <ul className="dropdown-menu">
                  {options.map((option, index) => (
                    <li
                      key={index}
                      onClick={() => handleDropdownSelect(option)}
                      style={{
                        color:
                          selectedOption === option ? " #074EE8" : "inherit",
                      }}
                    >
                      <input
                        type="checkbox"
                        checked={selectedOption === option}
                        readOnly
                      />
                      {option}
                    </li>
                  ))}
                </ul>
              )}
            </div>
            <Box
              className={`uploadIconBack ${isUploadDisabled ? "disabled" : ""}`}
              onClick={editBriefPromptAPICallClick}
            >
              <img src={uploadIcon} alt="Upload" />
            </Box>
          </Box>
          <Box className="nextBtn" onClick={handleNextTab}>
            <Typography className="btnText">Next</Typography>
          </Box>
        </>
      ) : (
        <Box className="nextBtn" onClick={handleNextTab}>
          <Typography className="btnText">Next</Typography>
        </Box>
      )}
    </Box>
  );
};

export default Footer;
