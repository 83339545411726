import React from "react";
import Heading from "../Heading";
import "./Slide9.css"
import { Box } from "@mui/material";

function Slide9({ logo, fontColor, headingFontWeight, headingFontSize, labels }) {
  return (
    <Box className="slide9Main">
      <img src={logo}  />
      <Heading
        text={labels.THANK_YOU}
        textColor={fontColor}
        textSize={headingFontSize}
        textWeight={headingFontWeight}
      />
    </Box>
  );
}

export default Slide9;
