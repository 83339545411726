import { Box, Paper, Typography } from "@mui/material";
import "./CustomTabs.css";
import { creativeChallengTabs } from "../../Constant/EditBrief";
import React from "react";

function TabComponent({ selectedButtonId, onSelectedTabClick }) {
  return (
    <Paper sx={{ borderRadius: "50px" }} className="creativeChallegeTabsLayout">
      {creativeChallengTabs.map((button) => (
        <Box
          key={button.id}
          className={`buttonTab ${
            selectedButtonId === button.id ? "selected" : "unselected"
          }`}
          onClick={() => onSelectedTabClick(button.id)}
        >
          <Box
            className={`buttonText ${
              selectedButtonId === button.id
                ? "selectedBtnText"
                : "unselectedBtnText"
            }`}
          >
            {button.label}
          </Box>
        </Box>
      ))}
    </Paper>
  );
}

export default TabComponent;
