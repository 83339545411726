import React from 'react';
import { styled } from '@mui/material/styles';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';
import PropTypes from 'prop-types';

const CustomWidthTooltip = styled(({ className, width, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ width }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: width || 250, // Use the provided width prop or default to 250px
    backgroundColor: '#FFFFFF',
    borderRadius: "10px",
    border: "0.5px solid #005EEF",
    background: "#FFF",
    color: "#001F82",
    boxShadow: "0px 4px 4px 0px rgba(0, 0, 0, 0.12)",
    padding: "10px 17px 10px 13px",
    wordBreak: "keep-all",
    fontFamily: "Unilever Shilling",
  },
}));

const CustomTooltip = ({ title, children, width, tooltipPlacement }) => {
  return (
    <CustomWidthTooltip title={title} width={width} placement={tooltipPlacement}>
      {children}
    </CustomWidthTooltip>
  );
};

CustomTooltip.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  width: PropTypes.number, // Define width prop as a number
};

export default CustomTooltip;
