import React from "react";
import Tooltip from "@mui/material/Tooltip";
import { styled } from "@mui/system";
import { Box } from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import infoIcon from "../../assets/images/Circle.png";
import "./CustomTooltip.css";
import LanguageChangeLink from "../LanguageChangeLink/LanguageChangeLink";
import DateTimeStamp from "../DateTimeStamp/DateTimeStamp";

const CustomTooltip = styled(({ className, width, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ width }) => ({
  "& .MuiTooltip-tooltip": {
    borderRadius: "4px",
    border: "0.5px solid #074EE8",
    background: "#FFF",
    color: "#001F82",
    maxWidth: width,
    padding: "10px 17px 10px 13px",
    wordBreak: "keep-all",
  },

  "& .MuiTooltip-arrow": {
    color: "blue",
  },
}));

export default function CustomizedTooltips({
  data = "Hello",
  width = "541px",
}) {

  return (
    <Box className="tooltipMain">
      <Box className="tooltipImg">
        <InfoIcon style={{ fontSize: "16px" }} />
        <CustomTooltip
          title={data}
          arrow
          placement="bottom-start"
          width={width}
        >
          <span className="infoTitle">Info</span>
        </CustomTooltip>
      </Box>
      <Box className="timeStampLink">
        {/* <p className="timeStampText">Last Updated: 5 mins ago</p> */}
      <DateTimeStamp/>
       {/* <LanguageChangeLink/> */}
      </Box>
    </Box>
  );
}
