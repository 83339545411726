import React from "react";
import { Box } from "@mui/material";
import "./Slide2.css";
import { useSelector } from "react-redux";
import Heading from "../Heading";
import { aboutText } from "../../../Constant/EditBrief";

function Slide2({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  illustrationBottomLeft,
  illustrationBottomRight,
  coverImageRight,
  coverImageLeft,
  placement,
  labels
}) {
  const { brandSelectedVal } = useSelector((state) => state.createBrief);
  const { aboutBrand } = useSelector((state) => state.editYourBrief);
  console.log(`${placement}`);
  return (
    <Box height={"100%"} display={"flex"} className={`${placement}`}>
      <Box className="leftSlide2">
        <img src={coverImageLeft} width={490} height={570} />
      </Box>
      <Box className="rightSlide2" position={"relative"} height={"100%"}>
        <Box className="aboutMain">
          <Heading
            text={`${labels.ALL_ABOUT} ${brandSelectedVal}`}
            textColor={fontColor}
            textSize={headingFontSize}
            textWeight={headingFontWeight}
          />
          <Heading
            text={aboutBrand}
            textColor={fontColor}
            textSize={subHeadingFontSize}
            textWeight={subHeadingFontWeight}
          />
        </Box>
        <Box className="illustrationFounderLogo">
          {coverImageRight && <img src={coverImageRight} />}
        </Box>
        <Box>
          {illustrationBottomLeft && (
            <img src={illustrationBottomLeft} className="illustration1" />
          )}
          {illustrationBottomRight && (
            <img src={illustrationBottomRight} className="illustration2" />
          )}
        </Box>
      </Box>
    </Box>
  );
}

export default Slide2;
