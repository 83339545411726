import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Avatar from "@mui/material/Avatar";
import Divider from "@mui/material/Divider";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { makeStyles } from "@mui/styles";
import AppBar from "@mui/material/AppBar";
import CustomButton from "../CustomButton/CustomButton";
import tabBarImage from "./../../assets/images/tabBar.png";
import nutritionsAITopDishEng from "./../../assets/images/Nutritions-AI-Top-Dish-Engine-Logo.png";
import nutritionLogo from "./../../assets/images/Nutition_logo.png";
import UserInputs from "../UserInputs/UserInputs";
import LoadingScreen from "../../components/LoadingScreen/LoadingScreen";
import { getInitials } from "../../utilities/stringUtils";
import {
  setUserName,
  setUserEmail,
  setIsViewPreviousBriefModalOpen,
  setTrendingRecipeSelectedVal,
  setTrendingRecipeOthersSelectedVal,
} from "../../slicers/createBriefSlicer";
import {
  homeBtnRedirectionURL,
  nutritionsTopDishImgAltText,
  nutritionLogoImgAltText,
  dividerOrientation,
  marketDropdown,
  brandDropdown,
  topDistTrendIndexText,
  languageDropdown,
} from "../../Constant/Header";
import "./styles.css";
import { seResetAddMoreFlavour } from "../../slicers/addMoreFlavourSlicer";

const useStyles = makeStyles((theme) => ({
  appBar: {
    backgroundImage: `url(${tabBarImage})`,
    backgroundSize: "cover", // Adjust this according to your image
  },
}));

function Header() {
  const classes = useStyles();
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const {
    marketSelectedVal,
    brandSelectedVal,
    marketOptions,
    brandOptions,
    tastewiseSelectionValue,
    isLoading,
    userName,
  } = useSelector((state) => state.createBrief);
  const { outputLanguageList, selectedOutputLanguage } = useSelector(
    (state) => state.addMoreFlavour
  );

  const prevMarketRef = useRef(marketSelectedVal);

  useEffect(() => {
    if (marketSelectedVal) {
      if (prevMarketRef.current !== marketSelectedVal) {
        dispatch(seResetAddMoreFlavour());
        dispatch(setTrendingRecipeOthersSelectedVal(""));
      }
    }
    prevMarketRef.current = marketSelectedVal;
  }, [marketSelectedVal, dispatch]);

  const { instance } = useMsal();

  useEffect(() => {
    const currentAccount = instance.getActiveAccount();

    if (currentAccount) {
      dispatch(setUserName(currentAccount.name));
      dispatch(setUserEmail(currentAccount.username));
    }
  }, [instance]);

  const handleHomeClick = () => {
    if (location.pathname !== homeBtnRedirectionURL) {
      dispatch(setIsViewPreviousBriefModalOpen(true));
      navigate(homeBtnRedirectionURL); // Navigate to the create-brief page
    }
  };

  const inputLabelStyle = {
    color: "#111111",
    fontFamily: "Unilever Shilling",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
    marginRight:"5px"
  };

  // properties related to user menu
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpen = () => {
    setAnchorEl(true);
  };

  const logOut = async () => {
    instance.logoutRedirect();
  };

  return (
    <Box sx={{ flexGrow: 1, backgroundImage: `url(${tabBarImage})` }}>
      <AppBar position="static" className={classes.appBar}>
        {isLoading && <LoadingScreen />}

        <Grid container spacing={0.5}>
          <Grid
            item
            xs={3}
            sm={3}
            md={3}
            lg={3}
            xl={3}
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <img
              src={nutritionsAITopDishEng}
              alt={nutritionsTopDishImgAltText}
              style={{ height: "40px", objectFit: "contain" }}
            />
            <Divider
              orientation={dividerOrientation}
              style={{
                border: "0.78px solid #1F36C7",
                height: "85%",
                marginLeft: "2px",
              }}
            />
            <img
              src={nutritionLogo}
              alt={nutritionLogoImgAltText}
              style={{
                height: "35px",
                objectFit: "contain",
                marginLeft: "2px",
              }}
            />
            <CustomButton
              onClick={handleHomeClick}
              style={{ marginLeft: "5px" }}
            />{" "}
            {/* Reduced margin */}
          </Grid>

          <Grid item xs={1} sm={1} md={3} lg={1} xl={1}></Grid>

          <Grid
            item
            xs={8}
            sm={8}
            md={6}
            lg={8}
            xl={8}
            style={{
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            {marketOptions && (
              <UserInputs
                label={marketDropdown.label}
                dropdownOptions={marketOptions.map((info) => info.market_name)}
                fieldType={marketDropdown.fieldType}
                labelStyle={inputLabelStyle}
                className={marketDropdown.className}
                showAsterisk={marketDropdown.showAsterisk}
                showInfoIcon={marketDropdown.showInfoIcon}
                dropdownWidth={marketDropdown.dropdownWidth}
                dropdownSelectedValue={marketSelectedVal}
                disableDropdown={
                  tastewiseSelectionValue === topDistTrendIndexText ||
                  location.pathname === "/EditYourBrief"
                }
              />
            )}

            <UserInputs
              label={languageDropdown.label}
              dropdownOptions={outputLanguageList?.map((e) => e.language_name)}
              fieldType={languageDropdown.fieldType}
              labelStyle={inputLabelStyle}
              className={languageDropdown.className}
              showAsterisk={languageDropdown.showAsterisk}
              showInfoIcon={languageDropdown.showInfoIcon}
              dropdownWidth={languageDropdown.dropdownWidth} // Adjust width
              dropdownSelectedValue={selectedOutputLanguage}
              disableDropdown={
                tastewiseSelectionValue === topDistTrendIndexText ||
                location.pathname === "/EditYourBrief"
              }
            />

            <UserInputs
              label={brandDropdown.label}
              dropdownOptions={brandOptions.map((info) => info.brand_name)}
              fieldType={brandDropdown.fieldType}
              labelStyle={inputLabelStyle}
              className={brandDropdown.className}
              showAsterisk={brandDropdown.showAsterisk}
              showInfoIcon={brandDropdown.showInfoIcon}
              dropdownWidth={brandDropdown.dropdownWidth}
              dropdownSelectedValue={brandSelectedVal}
              disableDropdown={
                tastewiseSelectionValue === topDistTrendIndexText ||
                location.pathname === "/EditYourBrief"
              }
            />

            <Avatar
              sx={{
                bgcolor: "#ffffff",
                color: "#000000",
                width: 30,
                height: 30,
                fontSize: "12px",
                fontFamily: "Unilever Shilling",
                marginLeft: "5px",
              }}
            >
              {getInitials(userName)}
            </Avatar>

            <Menu
              id="user-menu"
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              PaperProps={{
                elevation: 0,
                sx: {
                  overflow: "visible",
                  filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                  mt: 2.5,
                  ml: 1.5,
                },
              }}
              transformOrigin={{ horizontal: "right", vertical: "top" }}
              anchorOrigin={{ horizontal: "right", vertical: "top" }}
            >
              <MenuItem className="userMenuItem" onClick={logOut}>
                Logout
              </MenuItem>
            </Menu>
          </Grid>
        </Grid>
      </AppBar>
    </Box>
  );
}

export default Header;
