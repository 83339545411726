import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  aboutBrand: "",
  aboutProduct: "",
  campaignOverview: "",
  campaignImages: "",
  campaignGoal: "",
  captionIdeas: "",
  suggestedHeadlines: [],
  inspiration: ["campignImage"],
  contentMandatories: {},
  deliverables: "",
  socialMediaHashtag: [],
  hashtags: "",
  linkToUse: "",
  postingTimeline: "",
  contentMandatoriesEditData: {},
  postingTimelineData: {},
  pptDownloadName: "Influencer-Brief",
  inspirationVideoLink: "",
  activeIndexofTab: 3,
  promptTonality: "Select Tonality",
  createUpdateDateTime: "05-07-2024-19-36-00",
  inputEditPrompt: "",
  headerQuestionsLabels: [
    { id: 1, label: "All About" },
    { id: 2, label: "All About" },
    { id: 3, label: "Campaign Background" },
    { id: 4, label: "Campaign Images" },
    { id: 5, label: "Thought Starters" },
    { id: 6, label: "Campaign Key Messages" },
    { id: 7, label: "Suggested Captions" },
    { id: 8, label: "Inspiration" },
    { id: 9, label: "The Ask" },
    { id: 10, label: "Content Mandatories" },
    { id: 11, label: "Do's" },
    { id: 12, label: "Don'ts" },
    { id: 13, label: "Deliverables" },
    { id: 14, label: "Social Media Handles and Hashtags" },
    { id: 15, label: "Links To Use Within Captions" },
    { id: 16, label: "Handles" },
    { id: 17, label: "Hashtags" },
    { id: 18, label: "Posting Timelines" },
    { id: 19, label: "Contractual Briefing" },
    { id: 20, label: "Shooting/Production" },
    { id: 21, label: "Send Content to Brand Team for Review" },
    { id: 22, label: "Edit 2 / 2" },
    { id: 23, label: "Send Content to Brand Team for Review" },
    { id: 24, label: "Final Approval" },
    { id: 25, label: "Content Go Live" },
  ],
};

export const editYourBriefSlice = createSlice({
  name: "editYourBrief",
  initialState,
  reducers: {
    setAboutBrand: (state, action) => {
      state.aboutBrand = action.payload;
    },
    setCreateUpdateDateTime: (state, action) => {
      state.createUpdateDateTime = action.payload;
    },
    setInputEditPrompt: (state, action) => ({
      ...state,
      inputEditPrompt: action.payload,
    }),
    setInspirationVideoLink: (state, action) => {
      state.inspirationVideoLink = action.payload;
    },
    setPromptTonality: (state, action) => {
      state.promptTonality = action.payload;
    },
    setAboutProduct: (state, action) => {
      state.aboutProduct = action.payload;
    },
    setCampaignOverview: (state, action) => ({
      ...state,
      campaignOverview: action.payload,
    }),
    setCampaignImages: (state, action) => {
      state.campaignImages = action.payload;
    },
    setCampaignGoal: (state, action) => {
      state.campaignGoal = action.payload;
    },
    setCaptionIdeas: (state, action) => {
      state.captionIdeas = action.payload;
    },
    setSuggestedHeadlines: (state, action) => {
      state.suggestedHeadlines = action.payload;
    },
    setInspiration: (state, action) => {
      state.inspiration = action.payload;
    },
    setContentMandatories: (state, action) => {
      state.contentMandatories = action.payload;
    },
    setDeliverables: (state, action) => {
      state.deliverables = action.payload;
    },
    setSocialMediaHashtag: (state, action) => {
      state.socialMediaHashtag = action.payload;
    },
    setHashtags: (state, action) => {
      state.hashtags = action.payload;
    },
    setLinkToUse: (state, action) => {
      state.linkToUse = action.payload;
    },
    setPostingTimeLine: (state, action) => {
      state.postingTimeline = action.payload;
    },

    setContentMandatoriesEditData: (state, action) => {
      state.contentMandatoriesEditData = action.payload;
    },
    setPPTDownloadName: (state, action) => {
      state.pptDownloadName = action.payload;
    },
    setActiveIndexofTab: (state, action) => ({
      ...state,
      activeIndexofTab: action.payload,
    }),
    setHeaderQuestionsLabels: (state, action) => {
      state.headerQuestionsLabels = action.payload;
    },
  },
});

export const {
  setAboutBrand,
  setAboutProduct,
  setCampaignOverview,
  setCampaignImages,
  setCampaignGoal,
  setCaptionIdeas,
  setSuggestedHeadlines,
  setInspiration,
  setContentMandatories,
  setPostingTimeLine,
  setDeliverables,
  setLinkToUse,
  setSocialMediaHashtag,
  setHashtags,
  setPPTDownloadName,
  setContentMandatoriesEditData,
  setInspirationVideoLink,
  setActiveIndexofTab,
  setPromptTonality,
  setInputEditPrompt,
  setHeaderQuestionsLabels,
  setCreateUpdateDateTime,
} = editYourBriefSlice.actions;

export default editYourBriefSlice.reducer;
