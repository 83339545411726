import { Box, Button } from "@mui/material";
import React from "react";
import Heading from "../Heading";
import BulletPoints from "../BulletPoints";
import { useSelector } from "react-redux";
import "./Slide7.css";
import { contentMandatoriesText } from "../../../Constant/EditBrief";

function Slide7({
  logo,
  fontColor,
  headingFontWeight,
  headingFontSize,
  subHeadingFontSize,
  subHeadingFontWeight,
  illustrationBottomLeft,
  illustrationBottomRight,
  doFontColor,
  doNotFontColor,
  placement,
  labels,
}) {
  const { contentMandatories } = useSelector((state) => state.editYourBrief);
  return (
    <Box height={"86%"}>
      <Box className="contentMandatoriesText">
        <Heading
          text={labels.CONTENT_MANDATORIES}
          textColor={fontColor}
          textSize={headingFontSize}
          textWeight={headingFontWeight}
        />
      </Box>
      <Box className={`${placement}`}>
        <Box className="leftSlide7">
          <Box className="doDontTextMain">
            <Heading
              text={labels.DO_S}
              textColor={fontColor}
              textSize={subHeadingFontSize}
              textWeight={subHeadingFontWeight}
            />
          </Box>
          <Box className="contentMain">
            {contentMandatories?.map((item, index) => (
              <BulletPoints
                key={index}
                items={item.do}
                textColor={doFontColor}
                textSize={subHeadingFontSize}
                textWeight={subHeadingFontWeight}
              />
            ))}
          </Box>
        </Box>
        <Box className="rightSlide7">
          <Box className="doDontTextMain">
            <Heading
              text={labels.DON_TS}
              textColor={doNotFontColor}
              textSize={subHeadingFontSize}
              textWeight={subHeadingFontWeight}
            />
          </Box>
          <Box className="contentMainR">
            {contentMandatories?.map((item, index) => (
              <BulletPoints
                key={index}
                items={item.donts}
                textColor={doNotFontColor}
                textSize={subHeadingFontSize}
                textWeight={subHeadingFontWeight}
              />
            ))}
          </Box>
         {illustrationBottomLeft && <img
            src={illustrationBottomLeft}
            className="illustration1Slide7"
            alt="Left Illustration"
          />}
         {illustrationBottomRight && <img
            src={illustrationBottomRight}
            className="illustration2Slide7"
            alt="Right Illustration"
          />}
        </Box>
      </Box>
    </Box>
  );
}

export default Slide7;
