import React from 'react';
import Button from '@mui/material/Button';
import { styled } from "@mui/material/styles";
import { type } from '@testing-library/user-event/dist/type';

const VisuallyHiddenInput = styled("input")({
  clip: "rect(0 0 0 0)",
  clipPath: "inset(50%)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  whiteSpace: "nowrap",
  width: 1,
});

const CustomButton = ({ label, buttonStyle, fileUpload, ...props }) => {
  return (
    <>
      <Button style={buttonStyle} {...props}>
        {label}
        {fileUpload && <VisuallyHiddenInput type="file" />}
      </Button>
    </>
  );
};

export default CustomButton;
