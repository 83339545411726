import { Box, Modal } from "@mui/material";
import React, { useState } from "react";
import iconSrc from "../../assets/images/imageUploadIcon.png";

import "./ImageWithIcon.css";

const ImageWithIcon = ({
  handleModalOpenClick,
  src,
  isImageList = false,
  isEmptyImg = false,
  disableEditIcon = false,
}) => {
  return isImageList ? (
    <Box className={isEmptyImg && "emptyImageLayout"} position="relative">
      <img src={src} className={"productImageList"} />
      <Box
        className="galleryIcon"
        position="absolute"
        bottom="15px"
        right="25px"
        onClick={handleModalOpenClick}
        style={{ cursor: "pointer" }}
      >
        <img src={iconSrc} style={{ zIndex: 1 }} />
      </Box>
    </Box>
  ) : (
    <Box marginBottom={"20px"} className={"campaignImage"} position="relative">
      {src && (
        <img src={src} width={"100%"} height={"100%"} style={{ objectFit:"contain" }} />
      )}
      {!disableEditIcon && (
        <Box
          className="galleryIcon"
          position="absolute"
          bottom="5px"
          right="5px"
          onClick={handleModalOpenClick}
          style={{ cursor: "pointer" }}
        >
          <img src={iconSrc} style={{ padding: "5px", zIndex: 1 }} />
        </Box>
      )}
    </Box>
  );
};

export default ImageWithIcon;
