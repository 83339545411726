import { Box } from "@mui/material";
import "./BriefEditSteps.css"
import React from "react";

function BriefEditSteps ({ isActive, onClick, index, item,activeIndex })  {
 isActive=activeIndex===index
    return (
      <Box
        className={`steps ${isActive ? "active" : ""}`}
        onClick={() => onClick(index)}
      >
        <Box className="stepIcon">
          <img src={isActive?item.editLogo:item.logo} alt="Card logo" width={22.3} height={21.3} />
        </Box>
        <Box className="cardContent">
          <Box className={`stepTitle ${isActive ? "active" : ""}`}>
            {item.title}
          </Box>
          <Box className={`stepSubTitle ${isActive ? "active" : ""}`}>
            {item.subTitle}
          </Box>
        </Box>
      </Box>
    );
  };

  export default BriefEditSteps