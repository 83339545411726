export const GetToKnowUsTooltipData = [
  {
    title: "About Brand",
    description:
      "Brief context about the Brand, what does it stand for, key values, maybe some fun facts on origins",
  },
  {
    title: "About Product",
    description:
      "Brief info about the Product, claims, any key differentiators, Top Dishes and recipes made with this ingredient",
  },
];

export const campaignOverviewTooltipData = [
  {
    title: "Campaign Overview",
    description:
      " What is the key concept or theme for the campaign. Can include images as reference material about the campaign and its execution.",
  },
];

export const campaignGoalTooltipData=[{
  title:"Campaign Goal",
  description:"Objective of the campaign (Awareness / Consideration/ Conversion/ Advocacy) and how the activation will achieve it"
}]

export const contentIdeasTooltip = [
  {
    title: "Campaign Key Messages",
    description:
      " What are some key messages that the content should land. May be tailored to an influencer",
  },
  {
    title: "Suggested Captions",
    description:
      " Key caption / headline, includes the Recipe/ Top dish, may include reference of the season/ cultural moment, such as Halloween/ Valentine’s/ the Super Bowl etc.",
  },
  {
    title:"*The prompt will be applied to both Campaign Key Messgaes and Suggested Captions"
  }
];

export const inspirationTooltip=[{
  title:"Inspiration",
  description:"  Any reference images or web links to sample videos to suggest tonality, type of content, look & feel, content that has worked well in past, moodboards etc."
}]

export const thingsToKeepInMindTooltip=[
  {
    title:"Deliverables",
    description:"Key deliverables expected, number of videos/ stories expected, platform, including any technical specifications"
  },
  {
    title:"Content Mandatories + Dos and Don’ts",
    description:"Important Do’s / Don’ts or mandatories about how to show/ use/ talk about the product and brand assets in the content"
  },
  {
    title:"Social Media Handles and Hashtags",
    description:" Standard Brand social media handles and hashtags, or any hashtags specially created for the activation or campaign"
  },
  {
    title:" Links to use within captions",
    description:"Any affiliate links, or a general link to the brand website, or a recipe page in case of a recipe"
  },
  {
    title:" Posting Timelines",
    description:" Timeline for the content deliverables"
  },
]