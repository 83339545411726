import React from 'react';
import { useDispatch, useSelector } from "react-redux";
import { styled } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import UserInputs from '../UserInputs/UserInputs';
import {
  audienceTypeTooltipData,
  platformTooltipData,
  campaignObjectiveTooltipData,
  mediaTypeTooltipData,
  outputLanguageTooltipData,
} from "../../Constant/CreateBriefTooltip";
import {
  platformInfo,
  campaignObjectiveInfo,
  mediaTypeInfo,
  additionalUserSelectionsHeading,
  audienceTypeDropdown,
  platformDropdown,
  campaignObjectiveDropdown,
} from "../../Constant/AddMoreFlavour";
import './AddMoreFlavour.css';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
      maxHeight: 'none', // Set maxHeight to none to allow the content to expand the dialog height
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(2),
      display: 'flex',
      justifyContent: 'flex-end', // Align items to the right
    },
}));

const AddMoreFlavour = ({ expanded, onChange }) => {
  const {
    audienceTypeSelectedVal,
    platformSelectedVal,
    campaignObjectiveSelectedVal,
    mediaTypeSelectedVal,
    outputLanguageList,
    selectedOutputLanguage
  } = useSelector((state) => state.addMoreFlavour);

  const audienceTypeStyle = {
    color: "#111111",
    leadingTrim: "both",
    textEdge: "cap",
    fontFamily: "Unilever Shilling",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  };

  const platformStyle = {
    color: "var(--Text-Normal, #111)",
    fontFamily: "Unilever Shilling",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  };

  const campaignObjectiveStyle = {
    color: "var(--Text-Normal, #111)",
    fontFamily: "Unilever Shilling",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  };

  const mediaTypeStyle = {
    color: "var(--Text-Normal, #111)",
    fontFamily: "Unilever Shilling",
    fontSize: "12px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "20px",
  };

  return (
    <Accordion
      expanded={expanded === "panel2"}
      onChange={onChange("panel2")}
      sx={{ borderBottom: "1px solid #dcdcdc", boxShadow: "none" }}
    >
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1-content"
        id="panel1-header"
      >
        <Box
          sx={{
            width: "8px",
            height: "20px",
            backgroundColor: "#47C8FF",
            marginRight: "8px",
          }}
        ></Box>
        <Box
          style={{
            fontWeight: 600,
            fontSize: "14px",
            fontFamily: "Unilever Shilling",
          }}
        >
          {additionalUserSelectionsHeading}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Grid container spacing={1} justifyContent="center">
          <Grid item xs={1}></Grid>
          <Grid item xs={10}>
            <Grid container spacing={1} justifyContent="center">
              <Grid item xs={12} style={{ textAlign: "left" }}>
                <UserInputs
                  label={audienceTypeDropdown.label}
                  labelStyle={audienceTypeStyle}
                  fieldType={audienceTypeDropdown.fieldType}
                  layoutType={audienceTypeDropdown.layoutType}
                  showAsterisk={audienceTypeDropdown.showAsterisk}
                  showInfoIcon={audienceTypeDropdown.showInfoIcon}
                  textFieldValue={audienceTypeSelectedVal}
                  disabledState={audienceTypeDropdown.disabledState}
                  tooltipData={audienceTypeTooltipData}
                  tooltipPlacement={audienceTypeDropdown.tooltipPlacement}
                  tooltipWidth={audienceTypeDropdown.tooltipWidth}
                  textBoxWidth={audienceTypeDropdown.textBoxWidth}
                />
              </Grid>

              <Grid item xs={12} sx={{ height: "24px" }}></Grid>

              <Grid item xs={12} style={{ textAlign: "left" }}>
                <UserInputs
                  label={platformDropdown.label}
                  labelStyle={platformStyle}
                  dropdownOptions={platformInfo}
                  fieldType={platformDropdown.fieldType}
                  className={platformDropdown.className}
                  layoutType={platformDropdown.layoutType}
                  showAsterisk={platformDropdown.showAsterisk}
                  showInfoIcon={platformDropdown.showInfoIcon}
                  dropdownWidth={platformDropdown.dropdownWidth}
                  dropdownSelectedValue={platformSelectedVal}
                  disableDropdown={platformDropdown.disableDropdown}
                  tooltipData={platformTooltipData}
                  tooltipPlacement={platformDropdown.tooltipPlacement}
                  tooltipWidth={platformDropdown.tooltipWidth}
                  multiSelect={platformDropdown.multiSelect}
                />
              </Grid>

              <Grid item xs={12} sx={{ height: "24px" }}></Grid>

              <Grid item xs={12} style={{ textAlign: "left" }}>
                <UserInputs
                  label={campaignObjectiveDropdown.label}
                  labelStyle={campaignObjectiveStyle}
                  dropdownOptions={campaignObjectiveInfo}
                  fieldType={campaignObjectiveDropdown.fieldType}
                  className={campaignObjectiveDropdown.className}
                  layoutType={campaignObjectiveDropdown.layoutType}
                  showAsterisk={campaignObjectiveDropdown.showAsterisk}
                  showInfoIcon={campaignObjectiveDropdown.showInfoIcon}
                  dropdownWidth={campaignObjectiveDropdown.dropdownWidth}
                  dropdownSelectedValue={campaignObjectiveSelectedVal}
                  disableDropdown={campaignObjectiveDropdown.disableDropdown}
                  tooltipData={campaignObjectiveTooltipData}
                  tooltipPlacement={campaignObjectiveDropdown.tooltipPlacement}
                  tooltipWidth={campaignObjectiveDropdown.tooltipWidth}
                />
              </Grid>

              <Grid item xs={12} sx={{ height: "24px" }}></Grid>

              <Grid item xs={12} style={{ textAlign: "left" }}>
                <UserInputs
                  label="Media Type"
                  labelStyle={mediaTypeStyle}
                  dropdownOptions={mediaTypeInfo}
                  fieldType="dropdown"
                  className="select"
                  layoutType="vertical"
                  showAsterisk={false}
                  showInfoIcon={true}
                  dropdownWidth={279}
                  dropdownSelectedValue={mediaTypeSelectedVal}
                  disableDropdown={false}
                  tooltipData={mediaTypeTooltipData}
                  tooltipPlacement={"right-start"}
                  tooltipWidth={220}
                  multiSelect={true}
                />
              </Grid>

              <Grid item xs={12} sx={{ height: "24px" }}></Grid>
              {/* <Grid item xs={12} style={{ textAlign: "left" }}>
                <UserInputs
                  label="Output Language"
                  labelStyle={mediaTypeStyle}
                  dropdownOptions={outputLanguageList?.map((e)=>e.language_name)}
                  fieldType="dropdown"
                  className="select"
                  layoutType="vertical"
                  showAsterisk={false}
                  showInfoIcon={true}
                  dropdownWidth={279}
                  dropdownSelectedValue={selectedOutputLanguage}
                  disableDropdown={false}
                  tooltipData={outputLanguageTooltipData}
                  tooltipPlacement={"right-start"}
                  tooltipWidth={220}
                 
                />
              </Grid> */}
            </Grid>
          </Grid>
          <Grid item xs={1}></Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};

export default AddMoreFlavour;
