import React from "react";
import { Box } from "@mui/material";
import Heading from "./Heading";

function BulletPoints ({ items, textSize, textColor, textWeight })  {
    console.log(items)
  return (
    <Box
      style={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {items?.map((item, index) => (
        <Box key={index} style={{ display: "flex" }}>
          <span
            style={{
              marginRight: "8px",
              color: `#${textColor}`,
            }}
          >
            •
          </span>
          <Heading
            text={item}
            textColor={textColor}
            textSize={textSize}
            textWeight={textWeight}
          />
        </Box>
      ))}
    </Box>
  );
};

export default BulletPoints;
