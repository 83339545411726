import ligtBulb from "../assets/images/Lightbulb.png";
import star from "../assets/images/Star.png";
import lines from "../assets/images/Lines.png";
import preview from "../assets/images/Icon pack Variants.png";
import blueStar from "../assets/images/starBlue.jpg";
import fileBlue from "../assets/images/fileBlue.jpg";
import ligtBlubBlack from "../assets/images/LightbulbBlack.jpg";
import { useTextLabels } from "./useTextLabels";

export const EDITBRIEFDATA = [
  {
    logo: ligtBlubBlack,
    editLogo:ligtBulb,
    title: "Get To Know Us",
    subTitle: "Help influencer know more about the brand and the product",
  },
  {
    logo: star,
    editLogo:blueStar,
    title: "Your Creative Challenge",
    subTitle: "Help influencer understand what is expected from content",
  },
  {
    logo: lines,
    editLogo:fileBlue,
    title: "Things To Keep In Mind",
    subTitle: "Important Do’s and Dont’s, legal guidelines, timelines etc.",
  },
  {
    logo: preview,
    editLogo:preview,
    title: "Preview All &  Download ",
    subTitle: "Preview the whole belief & download in PDF format",
  },
];

export const creativeChallengTabs = [
  { id: 1, label: "Campaign Background" },
  { id: 2, label: "The Ask" },
  { id: 3, label: "Thought Starters" },
  { id: 4, label: "Inspiration" },
];

export const readyMadePrompt = [
  { id: 1, label: "Joyful" },
  { id: 2, label: "Inviting" },
  { id: 3, label: "Honest" },
  { id: 4, label: "Enthusiastic" },
];

export const EditBriefContentIdeaHeadlines=[
  {id:1,label:"Transform your dinner table into a scene from a witch's brew with our hauntingly delicious pumpkin soup!"},
  {id:2,label:"Summon the flavors of the season with our spellbinding pumpkin soup – a taste of autumnal alchemy!"},
  {id:3,label:"Unleash the spirit of Halloween with our bewitching pumpkin soup – a potion of comfort and flavor!"},
  {id:4,label:"Brewed to perfection with a sprinkle of enchantment, our pumpkin soup will cast a delicious spell on your taste buds!"}
]

export const thoughtStarterText="Thought Starters";
export const campaignKeyMessageText="Campaign Key Messages";
export const suggestedCaptionText="Suggested Caption*";
export const suggestedHeadlineText="*These caption ideas are here to inspire you. We encourage you to reword in your tone of voice and align to your idea"
export const campainBackgroundText="Campaign Background";
export const theAskText="The Ask";
export const deliverablesText="Deliverables";
export const socialMediahandlesText="Social Media Hashtags & handles";
export const hashtagsText="Hashtags";
export const handleText="Handles";
export const linkToUseText="Links To Use Within Captions";
export const postingTimelineText="Posting Timeline";
export const inspirationText="Inspiration";
export const contentMandatoriesText="Content Mandatories";
export const previewBriefText="Preview & Download the brief";
export const makeTweaksText="Make Tweaks & Download";
export const campaignBackgroundText="Campaign Background";
export const campaignImageText="Campaign Images";
export const theAsktext="The Ask";
export const allAboutBrand="All About";
export const allAboutProduct="All About"
export const suggestedCaptionsText="Suggested Captions";
export const deliverableText="Deliverables";
export const socialMediaHandlesText="Social Media Handles & Hashtags";
export const aboutText="About"

