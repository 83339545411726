import {
  setIsLoading,
} from "../../slicers/createBriefSlicer";

import store from "../../store";

const apiService = async ({
  // dispatch,
  method,
  url,
  body,
  headers,
  queryParams,
}) => {
  store.dispatch(setIsLoading(true));
  let queryString = "";
  if (queryParams) {
    const params = new URLSearchParams(queryParams);
    queryString = `?${params.toString()}`;
  }

  const config = {
    method: method || "GET",
    headers: {
      "Content-Type": "application/json",
      ...headers,
    },
    body: method !== "GET" ? JSON.stringify(body) : undefined,
  };

  try {
    const response = await fetch(`${url}${queryString}`, config);
    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    store.dispatch(setIsLoading(false)); // Set loading status to false when API call completes (error)
    throw new Error(`Error fetching API: ${error.message}`);
  } finally {
    store.dispatch(setIsLoading(false)); // Set loading status to false when API call completes (success or error)
  }
};

export default apiService;
