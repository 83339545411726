import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  audienceTypeSelectedVal: "Age less than 35 years",
  platformSelectedVal: ["Instagram"],
  campaignObjectiveSelectedVal: "Drive Awareness",
  mediaTypeSelectedVal: ["Long form Video"],
  outputLanguageList : [],
  selectedOutputLanguage: "English",
  selectedOutputLangaugeId:1,
};

export const addMoreFlavourSlicer = createSlice({
  name: "addMoreFlavour",
  initialState,
  reducers: {
    setAudienceTypeSelectedVal: (state, action) => ({
      ...state,
      audienceTypeSelectedVal: action.payload,
    }),
    setPlatformSelectedVal: (state, action) => ({
      ...state,
      platformSelectedVal: action.payload,
    }),
    setCampaignObjectiveSelectedVal: (state, action) => ({
      ...state,
      campaignObjectiveSelectedVal: action.payload,
    }),
    setMediaTypeSelectedVal: (state, action) => ({
      ...state,
      mediaTypeSelectedVal: action.payload,
    }),
    setOutputLanguageList: (state, action) => ({
      ...state,
      outputLanguageList: action.payload,
    }),

    setSelectedOutputLanguage: (state, action) => ({
      ...state,
      selectedOutputLanguage: action.payload,
    }),
    setSelectedOutputLanguageId: (state, action) => ({
      ...state,
      selectedOutputLangaugeId: action.payload,
    }),
    seResetAddMoreFlavour: (state) => ({
      ...state,
      audienceTypeSelectedVal: initialState.audienceTypeSelectedVal,
      platformSelectedVal: initialState.platformSelectedVal,
      campaignObjectiveSelectedVal: initialState.campaignObjectiveSelectedVal,
      mediaTypeSelectedVal: initialState.mediaTypeSelectedVal,
      selectedOutputLanguage: initialState.selectedOutputLanguage,
      selectedOutputLangaugeId: initialState.selectedOutputLangaugeId,
    }),
  },
});

// Action creators are generated for each case reducer function
export const {
  setAudienceType,
  setAudienceTypeSelectedVal,
  setPlatformSelectedVal,
  setCampaignObjectiveSelectedVal,
  setMediaTypeSelectedVal,
  setOutputLanguageList,
  setSelectedOutputLanguage,
  setSelectedOutputLanguageId,
  seResetAddMoreFlavour
} = addMoreFlavourSlicer.actions;

export default addMoreFlavourSlicer.reducer;
